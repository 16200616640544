import styled from "@emotion/styled";
import React, { useMemo, useState } from "react";
import { StreamWithStats } from "~/screens/app/brandreport/data/useStreamStats";
import { useBrands } from "~/services/brands/fetchBrands";
import {
  BrandReport,
  BrandReportsProps,
} from "~/screens/app/brandreport/report/BrandReport";

interface BrandReportsViewProps {
  stats: StreamWithStats[];
}

export function BrandReportView({ stats }: BrandReportsViewProps) {
  const { brandByName } = useBrands();
  const brands = useMemo(() => {
    if (!stats) return undefined;
    const names = new Set<string>();
    stats.forEach((s) => {
      if (!s.data) return;
      Object.keys(s.data.All.all.objects).forEach((k) => {
        names.add(k);
      });
    });
    return Array.from(names).map<BrandReportsProps["brand"]>((n) => {
      const brand = brandByName?.[n];
      return {
        name: n,
        label: brand?.label || n,
        img: brand?.img,
      };
    });
  }, [stats, brandByName]);
  const [active, setActive] = useState<BrandReportsProps["brand"]>();
  return (
    <div>
      <Brands>
        {brands?.map((b) => {
          return (
            <Brand
              key={b.name}
              data-tooltip={b.label}
              onClick={() => setActive(b)}
              className={b === active ? "is-active" : ""}
            >
              <img
                src={b.img ? b.img : "https://via.placeholder.com/50"}
                alt={b.label}
              />
            </Brand>
          );
        })}
      </Brands>
      {active && <BrandReport stats={stats} brand={active} />}
    </div>
  );
}

const Brand = styled.div`
  & > img {
    width: 4em;
    height: 4em;
  }
  opacity: 0.5;
  &.is-active {
    opacity: 1;
  }
`;

const Brands = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
  margin-top: 1em;
  align-items: center;
`;
