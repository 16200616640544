import React from "react";
import styled from "@emotion/styled";
import { Brand } from "@core/types/domain/Brand";
import { VideoBrandStats } from "@core/types/detection/DetectionTypes";
import BrandSummary from "../BrandSummary";

interface CompareChartProps {
  stats: VideoBrandStats;
  brandsInStats: Brand[];
}

function CompareChart({ stats, brandsInStats }: CompareChartProps) {
  return (
    <div>
      <ChartWrapper>
        <BrandSummary brands={brandsInStats} stats={stats} />
      </ChartWrapper>
    </div>
  );
}

const ChartWrapper = styled.div`
  margin-left: 42px;
`;

export default CompareChart;
