import React from "react";
import { RouteComponentProps, Link } from "@reach/router";
import styled from "@emotion/styled";
import { toInt } from "~/utils/jsUtils";
import { useLivestream } from "~/screens/app/detection/livestreams/data/useLivestreams";
import { useLivestreamTask } from "~/screens/app/detection/livestreams/data/useLivestreamsStatus";
import AppPageSpinner from "~/layouts/app/AppPageSpinner";
import Notification from "~/components/notification/Notification";
import { Reload } from "~/components/links/Reload";
import { AppRoute } from "~/config/Routes";
import { LivestreamView } from "~/screens/app/detection/livestreams/livestream/LivestreamView";
import { BackButton } from "~/components/button/IconButton";

interface LivestreamScreenProps extends RouteComponentProps {
  streamId: string;
}

export function LivestreamScreen(props: LivestreamScreenProps) {
  const streamId = toInt(props.streamId);
  if (!streamId) return null;
  const { data: stream, isLoading: l1, isIdle: l2 } = useLivestream(streamId);
  const { data: task, isLoading: l3 } = useLivestreamTask(
    streamId,
    stream?.server
  );
  const isLoading = l1 || l2 || l3;

  if (isLoading) return <AppPageSpinner />;
  if (!task) {
    return (
      <Notification type={"danger"}>
        The detection job doesn&apos;t seem to be running. Try to <Reload />{" "}
        this page or go{" "}
        <Link to={AppRoute.LivestreamOverview}>back to the stream list</Link>.
      </Notification>
    );
  }
  if (!stream || !task) return null;
  return (
    <div>
      <BackButtonContainer>
        <Link to={`${AppRoute.LivestreamOverview}?project=${stream.projectId}`}>
          <BackButton variant={"is-link"}>Go back to overview</BackButton>
        </Link>
      </BackButtonContainer>
      <h1>{stream.title}</h1>
      {/*<div>{JSON.stringify(stream, null, 2)}</div>*/}
      {/*<div>{JSON.stringify(task, null, 2)}</div>*/}
      <LivestreamView server={stream.server} task={task} stream={stream} />
    </div>
  );
}

const BackButtonContainer = styled.div`
  margin-bottom: 16px;
`;
