import { VideoFilterType } from "../filter/types/VideoFilterType";
import {
  fromBase64,
  getQueryParams,
  isEmpty,
  omit,
  setQueryParams,
  toBase64,
} from "../../../utils/jsUtils";
import {
  PaginationState,
  VideoProviderContextState,
} from "./VideoProviderContext";

export function persistContextOptions(
  filter: VideoFilterType,
  pagination: PaginationState
) {
  const filterToPersist = omit(filter, "project");
  setQueryParams({
    page: pagination.pageIndex > 0 ? pagination.pageIndex : null,
    pageSize:
      pagination.pageSize !== defaultPageSize ? pagination.pageSize : null,
    filter: !isEmpty(filterToPersist)
      ? toBase64(JSON.stringify(filterToPersist))
      : null,
  });
}

export function getDefaultContext(): VideoProviderContextState {
  const params = getQueryParams();
  const pageSize = params.get("pageSize");
  const pageIndex = params.get("page");
  return {
    pagination: {
      pageSize: (pageSize && parseInt(pageSize)) || defaultPageSize,
      pageIndex: (pageIndex && parseInt(pageIndex)) || 0,
    },
    fetchState: {
      isLoading: false,
      data: undefined,
      error: undefined,
    },
  };
}

export function getDefaultFilter(
  params: URLSearchParams = getQueryParams()
): VideoFilterType {
  const filter = params.get("filter");
  if (!filter) return null;
  const project = parseInt(params.get("project"));
  const decodedFilter =
    filter && (JSON.parse(fromBase64(filter)) as VideoFilterType);
  if (project) {
    decodedFilter.project = {
      in: [project],
    };
  }
  return decodedFilter || null;
}

const defaultPageSize = 25;
