import styled from "@emotion/styled";
import React from "react";
import { Brand } from "@core/types/domain/Brand";
import { StreamWithStats } from "~/screens/app/brandreport/data/useStreamStats";

export interface BrandReportsProps {
  stats: StreamWithStats[];
  brand: Pick<Brand, "name"> & Partial<Brand>;
}

export function BrandReport({ stats, brand }: BrandReportsProps) {
  return (
    <Table>
      <thead>
        <tr>
          <th>#</th>
          <th>Project</th>
          <th>Stream</th>
          <th>Views</th>
        </tr>
      </thead>
      <tbody>
        {stats.map((stream) => {
          return (
            <tr key={stream.stream.id}>
              <td>{stream.stream.id}</td>
              <td>{stream.stream.projectId}</td>
              <td>{stream.stream.title}</td>
              <td>{stream.stream.views}</td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}

const Table = styled.table`
  th,
  td {
    padding: 0.5em;
  }
`;

const Brands = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
  margin-top: 1em;
  align-items: center;
  img {
    width: 4em;
    height: 4em;
  }
`;
