import React, { useCallback, useEffect, useMemo } from "react";
import { LivestreamType } from "@core/types/domain/Livestream";
import { ProjectId } from "@core/types/domain/ProjectType";
import SelectDropdown, {
  SelectDropdownProps,
} from "~/elements/selectdropdown/SelectDropdown";
import { plural } from "~/utils/formatUtils";
import { useSelectDropdownState } from "~/elements/selectdropdown/useSelectDropdownState";
import {
  getQueryParam,
  notNull,
  setQueryParam,
  toIntArray,
} from "~/utils/jsUtils";

export interface StreamSelectOption {
  title: string;
  id: number;
  projectId: ProjectId;
}

interface StreamSelectorProps
  extends Pick<
    SelectDropdownProps<StreamSelectOption>,
    "onChange" | "formatTitle" | "singleValue" | "isLoading"
  > {
  streams: StreamSelectOption[];
  /** set this to persist state to url */
  urlParam?: string;
  values: StreamSelectOption[] | StreamSelectOption;
}

export function StreamFilterSelector({
  streams,
  ...props
}: StreamSelectorProps) {
  const values = useMemo(() => {
    if (!props.values) return undefined;
    if (Array.isArray(props.values)) return props.values;
    return [props.values];
  }, [props.values]);
  return (
    <SelectDropdown
      keys={keys}
      options={streams}
      getOptionLabel={getLabel}
      getOptionValue={getValue}
      formatTitle={formatTitle}
      {...props}
      values={values}
    />
  );
}

const keys: Array<keyof StreamSelectOption> = ["title"];

const getLabel = (s: StreamSelectOption) => s.title;
const getValue = (s: StreamSelectOption) => s.id;
const formatTitle = (s: StreamSelectOption[]) => {
  return s.length
    ? `${plural(s.length, "stream", "streams")} selected`
    : "All streams";
};

export function useStreamFilterState(
  streams: LivestreamType[],
  urlParam?: string
) {
  const streamSelection = useSelectDropdownState<StreamSelectOption>();
  const setValues = useCallback(
    (values: StreamSelectOption[]) => {
      streamSelection.setValues(values);
      if (urlParam) {
        setQueryParam(
          urlParam,
          values?.length ? values.map((v) => v.id) : null
        );
      }
    },
    [streamSelection.setValues]
  );
  useEffect(() => {
    if (!urlParam || !streams) return;
    const ids = getQueryParam(urlParam, toIntArray);
    if (!ids) return;
    const values = streams.filter((s) => ids.indexOf(s.id) !== -1);
    setValues(values);
  }, [streams]);

  const selectedStreams = useMemo(() => {
    if (!streams) return undefined;
    return streamSelection.values
      ?.map((v) => {
        const stream = streams.find((s) => s.id === v.id);
        if (!stream) {
          // TODO REMOVE!
          console.debug("found invalid stream", v.id);
        }
        return stream;
      })
      .filter(notNull);
  }, [streamSelection.values, streams]);

  return useMemo(() => {
    return {
      values: selectedStreams,
      firstValue: selectedStreams?.[0],
      setValues,
      bindings: {
        values: streamSelection.values,
        onChange: setValues,
      },
    };
  }, [streams, streamSelection, setValues, selectedStreams]);
}
