import { DateFilterType, DateFilterTypeName } from "../../types/DateFilterType";

/**
 * this function should extract a single date from any filtertype
 * that can be used as the first input date for any other type
 */
export function getExistingDateByType(filter: DateFilterType) {
  switch (filter.type) {
    case "between":
      return filter.from;
    case "exact":
      return filter.date;
    case "from":
      return filter.from;
    case "to":
      return filter.to;
  }
}

export function getDefaultFilter(
  type: DateFilterTypeName,
  previousDate: Date | string = new Date()
): DateFilterType {
  switch (type) {
    case "exact":
      return {
        type: "exact",
        date: previousDate,
      };
    case "between":
      return {
        type: "between",
        from: previousDate,
        to: previousDate,
      };
    case "from":
      return {
        type: "from",
        from: previousDate,
      };
    case "to":
      return {
        type: "to",
        to: previousDate,
      };
  }
}
