import React from "react";
import { ProjectType } from "@core/types/domain/ProjectType";
import { AttributeChartTexts } from "~/screens/app/detection/summary_legacy/views/AttributePieChart";
import Explain from "~/components/bulma/Explain";
import { getDetectionSizeExplainText } from "~/screens/app/detection/stats/project/SizeChart";

export function getSizePieChartTexts(
  project: ProjectType
): AttributeChartTexts {
  return {
    title: (
      <span>
        Duration by size
        <Explain text={getDetectionSizeExplainText(project)} />
      </span>
    ),
    subTitle: <span>summed logo durations</span>,
    explain:
      "This value is calculated by summing up the different size durations.\nThe sum " +
      "might be higher than the Brand duration, becauses sizes can overlap.",
  };
}
