import React, { ReactElement, useCallback } from "react";
import styled from "@emotion/styled";
import {
  ButtonDropdownControlled,
  useDropdownState,
} from "../../components/inputs/dropdown/ButtonDropdown";
import OptionsList from "./OptionsList";

type Value = number | string;

export interface SelectDropdownProps<T> {
  values: T[];
  options: T[];
  getOptionLabel: (option: T) => string;
  getOptionValue: (option: T) => Value;
  onChange: (values: T[]) => void;
  formatTitle?: (values: T[]) => string | ReactElement;
  isLoading?: boolean;
  className?: string;
  /** keys to search for when using text search */
  keys: string[] | ((option: T) => string);
  /** do not allow selecting multiple entries */
  singleValue?: boolean;
  onClose?: () => void;
  hideClear?: boolean;
}

function SelectDropdown<T>(props: SelectDropdownProps<T>) {
  const popupState = useDropdownState();
  const onChange = useCallback(
    (values: T[]) => {
      props.onChange(values);
      if (props.singleValue) {
        popupState.close();
      }
    },
    [props.onChange, props.singleValue]
  );
  return (
    <Container className={props.className}>
      <ButtonDropdownControlled
        {...popupState}
        popup={
          <OptionsList
            {...props}
            onChange={onChange}
            keys={props.keys as string[]}
          />
        }
        loading={props.isLoading}
      >
        {getTitle(props)}
      </ButtonDropdownControlled>
    </Container>
  );
}

function getTitle<T>(props: SelectDropdownProps<T>) {
  if (props.formatTitle) return props.formatTitle(props.values);
  if (props.isLoading) return "Loading";
  return `${props.values?.length} entries selected`;
}

const Container = styled.div`
  text-align: right;
  display: inline-block;

  .dropdown-menu {
    left: unset;
    right: 0;
    width: 500px !important;
    max-width: 100vw;
  }
`;

export default SelectDropdown;
