import { useCallback, useMemo } from "react";
import {
  LivestreamDetectionType,
  LivestreamType,
} from "@core/types/domain/Livestream";
import { useStreamDetectionResults } from "~/screens/app/detection/data/fetchDetectionResults";

export interface StreamWithDetections extends LivestreamType {
  detections: LivestreamDetectionType[];
}

export function useStreamsWithResults(
  streams: LivestreamType[],
  enabled = true
): StreamWithDetections[] {
  const streamIds = useMemo(() => streams?.map((s) => s.id), [streams]);
  const { data: detections } = useStreamDetectionResults(enabled && streamIds);
  const findDetections = useCallback(
    (stream: LivestreamType) => {
      if (!detections) return undefined;
      const filtered = detections.filter((d) => d.streamId === stream.id);
      filtered.sort((a, b) => {
        return a.id < b.id ? 1 : -1;
      });
      return filtered;
    },
    [detections]
  );
  return useMemo(() => {
    return streams?.map((stream) => {
      return {
        ...stream,
        detections: findDetections(stream),
      };
    });
  }, [streams, findDetections]);
}
