import React from "react";
import { LivestreamType } from "@core/types/domain/Livestream";
import { formatLocalDateTime, formatLocalTime } from "~/utils/dateUtils";
import { AutomationTypeIndicator } from "~/components/domain/stream/AutomationTypeIndicator";

interface CompactTimeInfoProps {
  stream: LivestreamType;
}

export function CompactTimeInfo({ stream }: CompactTimeInfoProps) {
  if (!stream.plannedStart) return null;
  const start = formatLocalDateTime(stream.plannedStart);
  const end = formatLocalTime(stream.plannedEnd);
  const info = `${start}${end ? ` - ${end}` : ""} Uhr`;
  const title = `Start: ${start}\n End: ${
    formatLocalDateTime(stream.plannedEnd) || ""
  }`;
  return (
    <span>
      <span title={title}>{info}</span>
      <AutomationTypeIndicator stream={stream} />
    </span>
  );
}
