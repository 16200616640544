import React from "react";
import styled from "@emotion/styled";
import { useUpdateFilter } from "../../context/VideoFilterContext";
import { DateFilterType, DateFilterTypeName } from "../../types/DateFilterType";
import { FilterType } from "../../types/VideoFilterType";
import DateInput from "../../../../../components/inputs/date/DateInput";
import { FilterTagProps } from "../FilterTagProps";
import { getDefaultFilter, getExistingDateByType } from "./dateDefaultsByType";

type DatePopupProps = FilterTagProps<DateFilterType>;

const types: { label: string; value: DateFilterTypeName }[] = [
  { label: "Exact", value: "exact" },
  { label: "From", value: "from" },
  { label: "To", value: "to" },
  { label: "Between", value: "between" },
];

function DatePopup(props: DatePopupProps) {
  const { filter, field } = props;
  const updateFilter = useUpdateFilter(field.name);
  return (
    <div>
      <OptionContainer className="control">
        {types.map((type) => (
          <label key={type.value} className="radio">
            <input
              type="radio"
              name={type.value}
              checked={filter.type === type.value}
              onChange={(event) => {
                if (!event.target.checked) return;
                const existingDateByType = getExistingDateByType(filter);
                updateFilter((prev) =>
                  getDefaultFilter(type.value, existingDateByType)
                );
              }}
            />
            {type.label}
          </label>
        ))}
      </OptionContainer>
      {renderPopupInputs(filter, updateFilter)}
    </div>
  );
}

function renderPopupInputs(
  filter: DateFilterType,
  updateFilter: (updater: (prev: FilterType) => FilterType) => void
) {
  const bind = (key: string) => ({
    value: filter[key],
    onChange: (date: Date) => {
      if (!date) date = null;
      updateFilter((prev) => ({
        ...prev,
        [key]: date,
      }));
    },
  });
  switch (filter.type) {
    case "exact":
      return (
        <>
          <label className="label">From</label>
          <DateInput {...bind("date")} />
        </>
      );
    case "from":
      return (
        <>
          <label className="label">From</label>
          <DateInput {...bind("from")} />
        </>
      );
    case "to":
      return (
        <>
          <label className="label">To</label>
          <DateInput {...bind("to")} />
        </>
      );
    case "between":
      return (
        <>
          <label className="label">From</label>
          <DateInput {...bind("from")} />
          <label className="label">To</label>
          <DateInput {...bind("to")} />
        </>
      );
  }
}

const OptionContainer = styled.div`
  white-space: nowrap;
  margin-bottom: 14px;
  label {
    margin-right: 4px;
  }
  input {
    margin-right: 4px;
  }
`;

export default DatePopup;
