import React from "react";
import styled from "@emotion/styled";
import AvatarToggle from "./AvatarToggle";
import Header from "./Header";
import { useAction } from "~/utils/hooks/useAction";
import { setLoggedOut } from "~/store/login/loginActions";
import { SwapEnvButton } from "~/layouts/main/header/SwapEnvButton";

interface HeaderProps {}

function HeaderWithLogin(props: HeaderProps) {
  return (
    <Header
      contentRight={
        <ContentWrapper>
          <SwapEnvButton />
          <AvatarToggle />
          <div className="navbar-item">
            <div className="buttons">
              <LogoutButton />
            </div>
          </div>
        </ContentWrapper>
      }
    />
  );
}

const ContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
`;

function LogoutButton() {
  const logout = useAction(setLoggedOut);
  return (
    <a className="button is-light" onClick={() => logout()}>
      Logout
    </a>
  );
}

export default HeaderWithLogin;
