import React, { useCallback, useMemo } from "react";
import keyBy from "lodash/keyBy";
import { useUpdateFilter } from "../../context/VideoFilterContext";
import { IdsFilterType } from "../../types/IdsFilterType";
import ActiveFilter from "../ActiveFilter";
import { FilterTagProps } from "../FilterTagProps";
import SelectFilterPopup from "./SelectFilterPopup";

export interface FilterOption {
  value: string;
  label: string;
}

interface SelectFilterProps extends FilterTagProps<IdsFilterType> {
  options: FilterOption[];
}

function createOnChange<T>(updateFilter: Updater<T>) {
  return (event: React.ChangeEvent<HTMLSelectElement>) => {
    const options = Array.from(event.target.options);
    const selectedOptions = options.filter((value) => value.selected);
    const selectedIds = selectedOptions.map((value) => parseInt(value.value));
    updateFilter(
      (prev) =>
        ({
          in: selectedIds,
        } as T)
    );
  };
}

function SelectFilter(props: SelectFilterProps) {
  const updateFilter = useUpdateFilter(props.field.name);
  const { filter, field, options } = props;
  const onChange = useCallback(createOnChange(updateFilter), [updateFilter]);
  const selectedList = useMemo(() => {
    const optionsById = keyBy(options, "value");
    return props.filter.in
      .map((id) => {
        const option = optionsById[id];
        return option && option.label;
      })
      .join(", ");
  }, [filter.in, props.options]);
  return (
    <ActiveFilter
      allFilters={props.allFilters}
      filter={props.filter}
      field={props.field}
      popup={
        <SelectFilterPopup
          filter={filter}
          options={options}
          onChange={onChange}
          field={field}
        />
      }
      invalid={!selectedList}
    >
      {field.label}: <b>{selectedList || "?"}</b>
    </ActiveFilter>
  );
}

type Updater<T> = (updater: (prev: T) => T) => void;

export default SelectFilter;
