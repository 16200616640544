import React, { useMemo } from "react";
import { faSmile } from "@fortawesome/free-regular-svg-icons";
import { upperFirst, max, maxBy } from "lodash";
import { SentimentStats } from "../../../../@core/types/api/projectRouteTypes";
import GroupedPieChartWidget from "../../dashboard/sections/GroupedPieChartWidget";

interface SentimentChartWidgetProps {
  stats: SentimentStats;
}

function SentimentChartWidget({ stats }: SentimentChartWidgetProps) {
  const data = useMemo(() => {
    if (!stats) return null;
    return Object.entries(stats).map(([key, value]) => {
      return {
        label: upperFirst(key),
        value,
        color: colors[key],
      };
    });
  }, [stats]);

  const highestMood = useMemo(() => {
    if (!stats) return null;
    const entries = Object.entries(stats).filter(([key]) => key !== "total");
    const highest = maxBy(entries, ([_, value]) => value);
    return highest && upperFirst(highest[0]);
  }, [stats]);

  if (!stats) return null;
  return (
    <GroupedPieChartWidget
      icon={faSmile}
      title={"Sentiment"}
      total={highestMood}
      subTitle={"average mood"}
      explain={"Each post is grouped according to its dominant mood"}
      data={data}
    />
  );
}

const colors: Record<keyof SentimentStats, string> = {
  positive: "green",
  neutral: "gray",
  negative: "red",
};

export default SentimentChartWidget;
