import React, { useMemo } from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { LivestreamType } from "@core/types/domain/Livestream";
import {
  DetectionFilter,
  DetectionFilterTarget,
} from "~/screens/app/detection/stats/components/filter/DetectionFilter";
import { TopBrandsWidget } from "~/screens/app/detection/stats/project/summary/TopBrandsWidget";
import { BrandsLineChartWidget } from "~/screens/app/detection/stats/components/widgets/BrandsLineChartWidget";
import { BrandStatsSection } from "~/screens/app/detection/stats/components/widgets/BrandStatsSection";
import WidgetGrid from "~/components/widget/WidgetGrid";
import { isCollapsed } from "~/utils/style/styleUtils";
import { useDetectionStats } from "~/screens/app/detection/stats/components/widgets/useDetectionStats";
import { useProject } from "~/store/project/projectHooks";
import { useRangeStreamResults } from "~/screens/app/detection/stream/data/useRangeStreamResults";
import { TimeRangeInfo } from "~/screens/app/detection/stream/videorange/SectionSelectorView";
import { ErrorNotification } from "~/components/notification/ErrorNotification";

export interface StreamSectionStatsProps {
  stream: LivestreamType;
  ranges: TimeRangeInfo[];
}

export function StreamSectionStats({
  stream,
  ranges,
}: StreamSectionStatsProps) {
  const { results, isFetching: l2 } = useRangeStreamResults(stream, ranges);
  const legacyFilter: DetectionFilter = useMemo(() => {
    return {
      target: DetectionFilterTarget.all,
    };
  }, []);
  const { summary, brands } = useDetectionStats(results, legacyFilter);
  const project = useProject(stream.projectId);
  if (results === null && !l2) {
    return (
      <ErrorNotification>
        There seem to be no data for the selected stream
      </ErrorNotification>
    );
  }
  if (!results || !brands) return null;
  return (
    <div>
      <Grid>
        <TopBrandsWidget
          css={css`
            grid-area: A;
          `}
          count={12}
          stats={summary.summed}
          brands={brands}
        />
        <BrandsLineChartWidget
          stats={summary.summed}
          brands={brands}
          css={css`
            grid-area: Chart;
          `}
          project={project}
        />
      </Grid>
      <BrandStatsSection
        stats={summary.summed}
        results={results}
        totalLength={summary.totalLength}
        projectId={stream.projectId}
        type={"stream"}
        attributeChartConfig={null}
        stream={stream}
      />
    </div>
  );
}

const Grid = styled(WidgetGrid)`
  grid-template-areas: "A A Chart Chart" "A A Chart Chart";
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  ${isCollapsed} {
    grid-template-areas: "Chart Chart Chart Chart" "A A B B" "C C C C";
  }
`;
