import debounce from "lodash/debounce";
import React, { memo, useEffect, useRef } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.min.css";

interface VideoJsPlayerProps extends videojs.PlayerOptions {
  src: string;
  onSeeking?: (pos: number) => void;
  onReady?: (player: videojs.Player) => void;
  className?: string;
}

export const VideoJsPlayer = memo(function VideoJsPlayerInner({
  src,
  onSeeking,
  onReady,
  className,
  ...playerProps
}: VideoJsPlayerProps) {
  const $wrapper = useRef<HTMLDivElement>();

  useEffect(() => {
    const video = document.createElement("video");
    video.classList.add("video-js");
    $wrapper.current.appendChild(video);

    const type = getTypeFromUrl(src);
    const player = videojs(video, {
      ...playerProps,
      controls: true,
      liveui: false,
      sources: [{ src: src, type }],
    });
    // player.on("loadedmetadata", (args) => {
    //   console.log("duration", video.duration)
    // })
    if (onReady) {
      player.on("ready", () => {
        onReady(player);
      });
    }
    if (onSeeking) {
      player.on(
        "seeking",
        debounce(() => {
          onSeeking(video.currentTime);
        }, 500)
      );
    }
    return () => {
      player.dispose();
    };
  }, [src]);

  return <div ref={$wrapper} className={className} />;
});

function getTypeFromUrl(url: string) {
  const lastDot = url.lastIndexOf(".");
  const ending = url.substr(lastDot + 1);
  switch (ending) {
    case "mp4":
      return "video/mp4";
    case "webm":
      return "video/webm";
    default:
      return "application/x-mpegURL";
  }
}
