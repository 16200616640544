import { TaskInfo } from "@core/types/detection/DetectionTypes";
import { useQuery } from "react-query";
import { requireOk, toJson } from "~/services/fetchApi";

export function usePlacementLabels(task: TaskInfo) {
  const location = task.request?.location;
  return useQuery(["usePlacementLabels", location], () => {
    return fetch(getS3LabelConfigUrl(location))
      .then(requireOk)
      .then<string[][]>(toJson)
      .then((json) => {
        const values = json[0];
        const labels = json[1];
        return values.reduce((result, name, i) => {
          result[name] = labels[i];
          return result;
        }, {} as Record<string, string>);
      });
  });
}

function getS3LabelConfigUrl(location: string) {
  // TODO MAGIC VALUE
  return `https://yoloout.s3.eu-central-1.amazonaws.com/locations/${location}/labels.json`;
}
