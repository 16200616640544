import React, { useMemo } from "react";
import { GetDetectionJobStatus } from "@core/types/status/statusRouteTypes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import { SimpleTable } from "~/components/table/SimpleTable";
import { limitList } from "~/utils/jsUtils";
import PlatformIcon from "~/views/videos/PlatformIcon";

interface LastResultsProps {
  results: GetDetectionJobStatus["lastResults"];
}

export function LastResults({ results }: LastResultsProps) {
  if (!results.length) return null;
  const limited = useMemo(() => {
    return limitList(results, 25);
  }, [results]);
  return (
    <div>
      <h2>Last videos</h2>
      <SimpleTable>
        <thead>
          <tr>
            <th>Time</th>
            <th>Job</th>
            <th>Video</th>
            <th>Platform</th>
            <th>URL</th>
            <th>Success</th>
            <th>Error</th>
          </tr>
        </thead>
        <tbody>
          {limited.map((r) => (
            <tr key={r.time.getTime()}>
              <td>{r.time.toLocaleString()}</td>
              <td>{r.jobId}</td>
              <td>{r.videoId}</td>
              <td>
                <a href={r.permalink} target={"_blank"} rel="noreferrer">
                  <PlatformIcon id={r.platform} />
                </a>
              </td>
              <td>
                <a
                  href={r.url}
                  target={"_blank"}
                  title={r.url}
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={faLink} />
                </a>
              </td>
              <td>{r.success ? <span>✓</span> : <span>❌</span>}</td>
              <td>{r.errorMessage}</td>
            </tr>
          ))}
        </tbody>
      </SimpleTable>
    </div>
  );
}
