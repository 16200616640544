import React, { useCallback, useMemo, useState } from "react";
import styled from "@emotion/styled";
import { CheckerProject } from "@core/types/domain/ProjectType";
import { GetUncheckedPostsQuery } from "@core/types/api/checkerApiTypes";
import { PostType } from "@core/types/domain/post/PostType";
import { PostCheckStatus } from "@core/types/domain/post/PostCheckStatus";
import PostCheck from "./post/PostCheck";
import { useCheckerPosts } from "~/services/checker/checkerHooks";
import { isNull } from "~/utils/jsUtils";
import Notification from "~/components/notification/Notification";
import AppPageSpinner from "~/layouts/app/AppPageSpinner";

interface PostCheckerViewProps {
  project: CheckerProject;
}

function CheckerView({ project }: PostCheckerViewProps) {
  const projectIds = useMemo(() => project && [project.id], [project]);

  const filter: GetUncheckedPostsQuery = useMemo(() => {
    return {
      projectIds,
      includeRecheck: false, // if this can change l
    };
  }, [projectIds]);

  const {
    data: posts,
    refetch,
    isFetching,
  } = useCheckerPosts(filter, {
    onSuccess: (posts: PostType[]) => {
      if (posts.length > 0) {
        getNext();
      } else {
        console.debug("NO MORE POSTS!");
      }
    },
  });
  const [post, setPost] = useState<PostType>();

  const getNext = useCallback(() => {
    const next = posts?.find((p) => isUncheckedPost(p, filter));
    setPost(next);
    if (!next) {
      refetch();
    }
  }, [posts, filter]);

  if (!project || isFetching) return <AppPageSpinner />;

  if (posts?.length === 0) {
    return (
      <Notification type={"info"}>
        There seem to be no more posts. Try reloading the page.
      </Notification>
    );
  }

  return (
    <div>
      {post && (
        <PostCheck
          project={project}
          post={post}
          getNext={getNext}
          filter={filter}
        />
      )}
    </div>
  );
}

function isUncheckedPost(post: PostType, filter: GetUncheckedPostsQuery) {
  const isUnchecked = !post.checkStatus; // check for null or 0
  if (filter.includeRecheck) {
    return isUnchecked || post.checkStatus === PostCheckStatus.ReCheck;
  } else {
    return isUnchecked;
  }
}

const DebugItem = styled.span<{ isCausal: boolean }>`
  font-weight: ${(props) => !isNull(props.isCausal) && "bold"};
  :after {
    content: ", ";
  }
`;

export default CheckerView;
