import React from "react";
import styled from "@emotion/styled";
import MainLayout, { MainLayoutProps } from "../main/MainLayout";
import { Stylable } from "../../types/reactTypes";

interface AppPageProps extends MainLayoutProps, Stylable {}

function AppPageLayout(props: AppPageProps) {
  return (
    <AppPageContainer className={props.className}>
      {props.children}
    </AppPageContainer>
  );
}

const AppPageContainer = styled.div`
  position: relative;
`;

export default AppPageLayout;
