import React, { useCallback } from "react";
import styled from "@emotion/styled";
import {
  VideoStatus,
  videoStatusById,
  VideoStatusType,
} from "../../../../../@core/types/domain/video/VideoStatus";
import { unstyledButtonCss } from "../../../../../components/button/Button";
import { EMPTY_ARRAY } from "../../../../../utils/jsUtils";
import { useProjectFilter, useProjectFilterDispatch } from "./ProjectFilter";

interface StateSelectorProps {}

function StateSelector(props: StateSelectorProps) {
  const filter = useProjectFilter();
  const setFilter = useProjectFilterDispatch();

  const value = filter.statusIn;
  const onChange = useCallback(
    (value: VideoStatus[]) => {
      setFilter({
        ...filter,
        statusIn: value,
      });
    },
    [filter, setFilter]
  );

  return (
    <Container>
      <StatusButton
        key={null}
        type={"button"}
        title={"Remove filter"}
        onClick={() => {
          onChange(EMPTY_ARRAY);
        }}
        className={value.length === 0 && "active"}
      >
        all
      </StatusButton>
      {filterableStatus.map((status) => {
        const active = value.indexOf(status.id) !== -1;
        return (
          <StatusButton
            key={status.id}
            type={"button"}
            title={"Filter by status: " + status.name}
            onClick={() => {
              if (active) {
                onChange(EMPTY_ARRAY);
              } else {
                onChange([status.id]);
              }
            }}
            className={active && "active"}
          >
            {status.name}
          </StatusButton>
        );
      })}
    </Container>
  );
}

const StatusButton = styled.button`
  ${unstyledButtonCss};
  &.active {
    text-shadow: 0.25px 0px 0.1px, -0.25px 0px 0.1px;
  }
`;

const filterableStatus: VideoStatusType[] = [
  VideoStatus.LegalUse,
  VideoStatus.IllegalUse,
  VideoStatus.Greylist,
].map((id) => videoStatusById[id]);

const Container = styled.div`
  text-align: right;
  margin-bottom: 12px;
`;

export default StateSelector;
