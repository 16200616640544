import React, { useMemo } from "react";
import styled from "@emotion/styled";
import { faAws } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getRecordingsBucket } from "@core/utils/domain/awsUtils";

export function AwsRecordingsLink({ projectId }: { projectId: string }) {
  const bucket = useMemo(
    () => getRecordingsBucket(`${projectId}`),
    [projectId]
  );

  return (
    <a href={bucket.manageUrl} target={"_blank"} rel="noreferrer">
      <AwsIcon icon={faAws} />
    </a>
  );
}

const AwsIcon = styled(FontAwesomeIcon)`
  vertical-align: middle;
  color: unset;
  margin-left: 1rem;
  margin-bottom: 0.75rem;
`;
