import React from "react";
import AppPageLayout from "../../../layouts/app/AppPageLayout";
import { useUserRole } from "../../../store/login/loginHooks";
import { UserRole } from "../../../@core/types/domain/user/UserRole";
import PasswordGenerator from "./PasswordGenerator";
import { ImportTest } from "~/screens/app/admin/ImportTest";

interface AdminScreenProps {}

function AdminScreen(props: AdminScreenProps) {
  const role = useUserRole();
  if (role !== UserRole.SuperAdmin) {
    return (
      <div>
        <h2>No sir!</h2>
        <p>This route requires SuperAdmin permissions.</p>
      </div>
    );
  }

  return (
    <AppPageLayout>
      <PasswordGenerator />
      <ImportTest />
    </AppPageLayout>
  );
}

export default AdminScreen;
