import React from "react";
import styled from "@emotion/styled";
import img from "./logo.png";

interface HeaderLogoProps {}

function HeaderLogo(props: HeaderLogoProps) {
  return <Logo src={img} title={img} />;
}

export const Logo = styled.img`
  max-height: 52px;
  margin-left: -2px;
`;

export default HeaderLogo;
