import React, { memo } from "react";
import { DateFilterType } from "../../types/DateFilterType";
import ActiveFilter from "../ActiveFilter";
import { formatDate } from "../../../../../utils/dateUtils";
import { FilterTagProps } from "../FilterTagProps";
import DatePopup from "./DatePopup";

type DateFilterProps = FilterTagProps<DateFilterType>;

function renderText(props: DateFilterProps) {
  const { filter, field } = props;
  switch (filter.type) {
    case "exact":
      return (
        <span>
          {field.label}: <b>{formatDateOrFallback(filter.date)}</b>
        </span>
      );
    case "from":
      return (
        <span>
          {field.label} ≥ <b>{formatDateOrFallback(filter.from)}</b>
        </span>
      );
    case "to":
      return (
        <span>
          {field.label} ≤ <b>{formatDateOrFallback(filter.to)}</b>
        </span>
      );
    case "between":
      return (
        <span>
          {field.label} between <b>{formatDateOrFallback(filter.from)}</b> and{" "}
          <b>{formatDateOrFallback(filter.to)}</b>
        </span>
      );
    default:
      return null;
  }
}

function formatDateOrFallback(date) {
  return date ? formatDate(date) : "?";
}

function DateFilter(props: DateFilterProps) {
  return (
    <ActiveFilter popup={<DatePopup {...props} />} {...props}>
      {renderText(props)}
    </ActiveFilter>
  );
}

export default memo(DateFilter);
