import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { QueryVideosResult } from "../../../@core/types/api/videoRouteTypes";
import { VideoFilterType } from "../filter/types/VideoFilterType";
import { WithChildren } from "../../../types/reactTypes";
import { FetchState } from "../../../utils/hooks/useFetchState";
import { fetchVideos } from "../../../services/video/videoService";
import { alertError } from "../../../utils/alert/alertUtils";
import { VideoType } from "../../../@core/types/domain/video/VideoType";
import { VideoCustomStatus } from "../../../@core/types/domain/video/VideoCustomStatus";
import {
  getDefaultContext,
  persistContextOptions,
} from "./persistContextOptions";

export interface PaginationState {
  pageSize: number;
  pageIndex: number;
}

interface ContextState {
  filter?: VideoFilterType;
  pagination: PaginationState;
  fetchState: FetchState<QueryVideosResult>;
}
export type VideoProviderContextState = ContextState;

interface ContextType extends ContextState {
  setFilter: (filter: VideoFilterType) => void;
  requestVideos: () => void;
  setState: Dispatch<SetStateAction<ContextState>>;
  setPagination: (next: Partial<PaginationState>) => void;
  updateVideo: (video: VideoType) => void;
}

const Context = createContext<ContextType>(null);

export function VideoProviderContext(props: WithChildren) {
  const [state, setState] = useState<ContextState>(getDefaultContext);

  const cachedFetchVideos = useCallback(
    // (filter: VideoFilterType, pagination: PaginationState) => {
    () => {
      const filter = state.filter;
      const pagination = state.pagination;

      persistContextOptions(filter, pagination);

      setState((state) => ({
        ...state,
        fetchState: {
          data: state.fetchState.data,
          isLoading: true,
        },
      }));

      const fetchFilter = getFetchFilter(filter);

      fetchVideos({
        filter: fetchFilter,
        pageSize: pagination.pageSize,
        page: pagination.pageIndex,
      })
        .then((result) => {
          setState((state) => ({
            ...state,
            fetchState: {
              isLoading: false,
              data: result,
            },
          }));
        })
        .catch((error) => {
          alertError("Something went wrong.");
          setState((state) => ({
            ...state,
            fetchState: {
              isLoading: false,
              error,
            },
          }));
        });
    },
    [state.pagination, state.filter]
  );

  const updateVideo = useCallback(createUpdateVideo(setState), [setState]);

  const value: ContextType = useMemo(() => {
    return {
      ...state,
      setFilter: (filter: VideoFilterType) => {
        setState((state) => ({
          ...state,
          filter,
        }));
      },
      updateVideo,
      setState,
      requestVideos: cachedFetchVideos,
      setPagination: (next: Partial<PaginationState>) => {
        setState((state) => ({
          ...state,
          pagination: {
            ...state.pagination,
            ...next,
          },
        }));
      },
    };
  }, [state, cachedFetchVideos]);

  return <Context.Provider value={value}>{props.children}</Context.Provider>;
}

function getFetchFilter(filter: VideoFilterType): VideoFilterType {
  if (filter.customStatus) return filter;
  // hide nonRelevant by default
  return {
    ...filter,
    customStatus: {
      notIn: [VideoCustomStatus.NonRelevant],
    },
  };
}

function createUpdateVideo(setState) {
  const updateVideo = (update: VideoType) => {
    setState((state) => ({
      ...state,
      fetchState: {
        isLoading: false,
        data: {
          ...state.fetchState.data,
          results: state.fetchState.data.results.map((video) => {
            if (video.id === update.id) return update;
            return video;
          }),
        },
      },
    }));
  };
  globalUpdateVideo = updateVideo;
  return updateVideo;
}

export let globalUpdateVideo = (video: VideoType) => {
  console.warn("missing implementation");
};

declare global {
  // noinspection JSUnusedGlobalSymbols
  interface Window {
    updateVideo: (video: VideoType) => void;
  }
}

export function useVideoProvider(): ContextType {
  return useContext(Context);
}
