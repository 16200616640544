import React, { useEffect, useState } from "react";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import { css } from "@emotion/react";
import Icon from "~/components/icons/Icon";

interface RefreshButtonProps {
  isLoading: boolean;
  onClick: () => void;
}

export function RefreshButton(props: RefreshButtonProps) {
  const [isLoading, setLoading] = useState(props.isLoading);
  useEffect(() => {
    if (props.isLoading) {
      setLoading(true);
    } else {
      const hideTask = window.setInterval(() => {
        setLoading(false);
      }, duration);
      return () => window.clearInterval(hideTask);
    }
  }, [props.isLoading]);
  return (
    <Icon
      icon={faSync}
      spin={isLoading || props.isLoading}
      onClick={props.onClick}
      css={style}
    />
  );
}

const style = css`
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
`;

const duration = 1000;
