import React, { useState } from "react";
import {
  MultiEventSelector,
  StreamInfo,
} from "~/screens/app/brandreport/selector/MultiEventSelector";
import { useResolveStreams } from "~/screens/app/brandreport/data/useResolveStreams";
import { BrandReportContainer } from "~/screens/app/brandreport/BrandReportContainer";

interface BrandReportScreenProps {}

export function BrandReportScreen(props: BrandReportScreenProps) {
  const [selected, setSelected] = useState<number[]>([]);
  const { data: streams } = useResolveStreams(selected);
  return (
    <div>
      <MultiEventSelector onChange={setSelected} />
      {streams && <BrandReportContainer streams={streams} />}
    </div>
  );
}
