import { useQueries } from "react-query";
import { LivestreamType } from "@core/types/domain/Livestream";
import { useMemo } from "react";
import { liveStreamTaskId } from "@core/utils/domain/detectionUtils";
import { fetchLiquidApi } from "~/screens/app/detection/stream/data/useRangeStreamStats";
import { LiquidStreamStats } from "~/screens/app/brandreport/data/LiquidStreamStats";

export function useStreamStats(
  streams: Pick<LivestreamType, "id" | "projectId" | "title" | "views">[]
) {
  const data = useQueries(
    streams.map((stream) => ({
      queryKey: ["streamStats", stream],
      queryFn: async () => {
        return fetchSummedLiquidStats(stream);
      },
    }))
  );
  return useMemo(() => {
    return {
      data: data.map((d) => {
        return {
          stream: streams[data.indexOf(d)],
          data: d.data as LiquidStreamStats,
        };
      }),
      isLoading: data.some((d) => d.isLoading),
    };
  }, [data]);
}

function fetchSummedLiquidStats({
  id,
  projectId,
}: Pick<LivestreamType, "id" | "projectId">) {
  const streamId = liveStreamTaskId.fromStreamId(id);
  const url = `https://yoloout.s3.eu-central-1.amazonaws.com/${projectId}/${streamId}.sum.json`;
  return fetch(url)
    .then<LiquidStreamStats>((res) => res.json())
    .then((results) => results);
}

function fetchLiquidStats(stream: Pick<LivestreamType, "id" | "projectId">) {
  const folder = stream.projectId;
  const task = liveStreamTaskId.fromStreamId(stream.id);
  const url = `/task_download/${folder}/${task}`;
  // if (ranges?.length) {
  //   url += getRanges(ranges);
  // }
  return fetchLiquidApi(url)
    .then<LiquidStreamStats[]>((res) => res)
    .then((results) => results[0]);
}

export type StreamWithStats = ReturnType<typeof useStreamStats>["data"][0];
