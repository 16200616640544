import { useQuery } from "react-query";
import { BroadcastSignalType } from "@core/types/detection/BroadcastSignalType";
import { useMemo } from "react";
import { fetchApi, requireOk, toJson } from "~/services/fetchApi";

export function useSignals() {
  return useQuery(["useSignals"], () => fetchSignals());
}

export function useSignal(id: number) {
  const query = useSignals();
  return useMemo(() => {
    return {
      ...query,
      data: query.data?.find((s) => s.id === id),
    };
  }, [query, id]);
}

function fetchSignals(): Promise<BroadcastSignalType[]> {
  return fetchApi("/signal").then(requireOk).then(toJson);
}
