import React, { createElement } from "react";
import styled from "@emotion/styled";
import { VideoFilterType } from "./types/VideoFilterType";
import { FilterFieldConfig, videoFields } from "./utils/videoFields";
import { filterComponentsByType } from "./utils/filterComponentsByType";
import AddFilter from "./add/AddFilter";
import PredefinedButton from "./predefined/PredefinedButton";
import ClearFilter from "./ClearFilter";

interface ActiveFiltersProps {
  filter: VideoFilterType;
  submitButton: React.ReactElement;
}

function ActiveFilters(props: ActiveFiltersProps) {
  const { filter } = props;
  return (
    <Container>
      {filter.project && <PredefinedButton />}
      {Object.entries(filter).map(([fieldName, fieldFilter]) => {
        const field: FilterFieldConfig = videoFields[fieldName];
        if (!field) throw Error("could not find field " + fieldName);
        const component = filterComponentsByType[fieldName];
        if (!component)
          throw Error("could not find component for field " + fieldName);
        return createElement(component, {
          key: field.name,
          filter: fieldFilter,
          field,
          allFilters: filter,
        });
      })}
      <AddFilter />
      <ClearFilter />
      {props.submitButton}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  background-color: white;
  //border: 1px solid #dadcde;
  border-radius: 8px;
  padding: 16px;
  > div {
    margin-right: 12px;
  }
`;

export default ActiveFilters;
