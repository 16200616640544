import React, { useMemo } from "react";
import { faHashtag } from "@fortawesome/free-solid-svg-icons";
import { css } from "@emotion/react";
import { ProjectType } from "@core/types/domain/ProjectType";
import { PostsSummary } from "@core/types/api/postRouteTypes";
import { useProjectPosts } from "~/services/post/fetchProjectPosts";
import { Spinner } from "~/components/spinner/Spinner";
import { usePaginationState } from "~/components/table/usePaginationState";
import { AsyncTable } from "~/components/table/AsyncTable";
import { useUserPermissions } from "~/store/login/loginHooks";
import { getPostColumns } from "~/screens/app/posts/getPostColumns";

interface PostsTableContainerProps {
  project: ProjectType;
  summary: PostsSummary;
}

export function PostsTableContainer({
  project,
  summary,
}: PostsTableContainerProps) {
  const { isAdmin } = useUserPermissions();
  const { pagination, setPagination } = usePaginationState();
  const { data: posts, isFetching: f2 } = useProjectPosts(
    project.id,
    pagination
  );
  const columns = useMemo(
    () => getPostColumns(isAdmin, project),
    [isAdmin, project]
  );
  if (f2) return <Spinner />;
  if (!posts) return null;
  return (
    <AsyncTable
      items={posts}
      maxItemCount={summary.count}
      title={"Posts"}
      icon={faHashtag}
      isLoading={false}
      columns={columns}
      pagination={pagination}
      setPagination={setPagination}
      css={tableCss}
    />
  );
}

const tableCss = css`
  .cell-title {
    word-break: break-word;
  }

  .cell-thumbnail {
    width: 120px;
  }

  .cell-isCausal {
    text-align: center;
  }
`;
