import { uniq } from "lodash";
import { useQuery } from "react-query";
import { StreamInfo } from "~/screens/app/brandreport/selector/MultiEventSelector";
import { fetchLivestreams } from "~/screens/app/detection/livestreams/data/useLivestreams";

export function useResolveStreams(streamIds: number[]) {
  return useQuery(
    ["useResolveStreams", streamIds],
    () => {
      return fetchLivestreams({
        streamIds,
      });
    },
    {
      enabled: !!streamIds.length,
    }
  );
}
