import { RecordingStopArgs } from "@core/types/recordings/RecordingApi";
import { fetchApi, requireOk, toJson } from "~/services/fetchApi";

export function fetchStopRecording(args: RecordingStopArgs) {
  return fetchApi(`/recordings/stop`, {
    method: "POST",
    body: JSON.stringify(args),
  })
    .then(requireOk)
    .then(toJson);
}
