import React, { memo, useCallback, useState } from "react";
import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDizzy } from "@fortawesome/free-solid-svg-icons";
import { css } from "@emotion/react";

type SafeImageProps = React.DetailedHTMLProps<
  React.ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>;

function SafeImage(props: SafeImageProps) {
  const [error, setError] = useState();
  const onError = useCallback((err) => {
    setError(err);
  }, []);
  if (error) {
    return (
      <ErrorPlaceholder title={"Missing picture"} {...props}>
        <FontAwesomeIcon icon={faDizzy} size={"2x"} />
      </ErrorPlaceholder>
    );
  }
  // noinspection HtmlRequiredAltAttribute
  return <img {...props} onError={onError} />;
}

const ErrorPlaceholder = styled.div`
  //min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0f0f0;

  svg {
    opacity: 0.5;
  }
`;

export default memo(SafeImage);
