import React from "react";
import {
  faEuroSign,
  faHashtag,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { PostsSummary } from "@core/types/api/postRouteTypes";
import { ProjectType } from "@core/types/domain/ProjectType";
import NumberWidget from "~/screens/app/dashboard/widgets/NumberWidget";
import WidgetGrid from "~/components/widget/WidgetGrid";
import { roundN } from "~/utils/js/mathUtils";

interface PostsDashboardProps {
  project: ProjectType;
  summary: PostsSummary;
}

export function PostsDashboard({ summary }: PostsDashboardProps) {
  return (
    <WidgetGrid>
      <NumberWidget
        title={"Total posts"}
        icon={faHashtag}
        value={summary.count}
        subTitle={"Total number of posts"}
      />
      <NumberWidget
        title={"Total reach"}
        icon={faUsers}
        value={summary.reach}
        subTitle={"Total amount of reached people"}
      />
      <NumberWidget
        title={"Total value"}
        // header={
        //   <CardHeaderRight>
        //     <EditEuroValueButton />
        //   </CardHeaderRight>
        // }
        icon={faEuroSign}
        value={summary.euroValue || 0}
        subTitle={"Summed Euro values of all posts"}
      />
      <NumberWidget
        title={"Average value"}
        icon={faEuroSign}
        value={roundN(summary.euroValue / summary.count) || 0}
        subTitle={"Average Euro value per post"}
      />
      {/*<EditEuroValueWidget project={project} />*/}
    </WidgetGrid>
  );
}
