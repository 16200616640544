import React, { memo, useMemo } from "react";
import styled from "@emotion/styled";
import { DropdownItem } from "../../../../../components/inputs/dropdown/Dropdown";
import { FilterFieldConfig } from "../../utils/videoFields";
import { IdsFilterType } from "../../types/IdsFilterType";
import { stringify } from "../../../../../utils/jsUtils";
import { FilterOption } from "./SelectFilter";

interface SelectFilterPopupProps {
  options: FilterOption[];
  onChange: React.ChangeEventHandler<HTMLSelectElement>;
  filter: IdsFilterType;
  field: FilterFieldConfig;
}

function SelectFilterPopup(props: SelectFilterPopupProps) {
  const value = useMemo(() => {
    return props.filter ? props.filter.in.map(stringify) : [];
  }, [props.filter]);

  return (
    <DropdownItem>
      <PopupContainer className="select is-multiple">
        <label className={"label"}>{props.field.label} is</label>
        <select multiple size={8} onChange={props.onChange} value={value}>
          {props.options.map((status) => (
            <option key={status.label} value={status.value}>
              {status.label}
            </option>
          ))}
        </select>
        <small>
          Select multiple elements by holding <i>Ctrl</i> when clicking.
        </small>
      </PopupContainer>
    </DropdownItem>
  );
}

const PopupContainer = styled.div`
  width: 180px;
  select {
    width: 100%;
    margin-bottom: 8px;
  }
  small {
  }
`;

export default memo(SelectFilterPopup);
