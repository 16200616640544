import React, { useEffect, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import Dropdown, {
  DropdownItem,
  DropdownLink,
} from "../../../../components/inputs/dropdown/Dropdown";
import { useProjectSelection } from "../../../../elements/context/project/ProjectSelectionContext";
import { useFetchState } from "../../../../utils/hooks/useFetchState";
import {
  fetchPredefinedFilters,
  PredefinedFilter,
} from "../../../../services/video/filterService";
import { useFilterContext } from "../context/VideoFilterContext";
import { VideoFilterType } from "../types/VideoFilterType";
import { tryClickOnElement } from "../../../../utils/jsUtils";
import { btnApplyFilterId } from "../SubmitFilter";
import { useSelectedProject } from "../../../../store/project/projectHooks";

interface PredefinedButtonProps {}

function Popup(props: { filters: PredefinedFilter[] }) {
  const { setFilter } = useFilterContext();
  if (props.filters.length === 0)
    return (
      <DropdownItem>
        <i>No predefined filters.</i>
      </DropdownItem>
    );
  return (
    <>
      {props.filters.map((filter) => (
        <DropdownLink
          key={filter.id}
          onClick={() => {
            setFilter(
              () =>
                ({
                  ...filter.filter,
                } as VideoFilterType)
            );
            // TODO
            setTimeout(() => tryClickOnElement(btnApplyFilterId), 500);
          }}
        >
          {filter.label}
        </DropdownLink>
      ))}
    </>
  );
}

function PredefinedButton(props: PredefinedButtonProps) {
  const project = useSelectedProject();
  const [state, dispatch] = useFetchState<PredefinedFilter[]>();
  const { setLoading, onSuccess, onFail } = dispatch;
  useEffect(() => {
    setLoading();
    fetchPredefinedFilters(project.id).then(onSuccess).catch(onFail);
  }, [project]);

  const filters = state.data;

  // const filtersOrNull = state.data && state.data.length > 0 ? state.data : null
  const disabledProps = useMemo(
    () => ({
      disabled: !filters || filters.length === 0,
    }),
    [filters]
  );

  if (!project) return null;

  return (
    <Dropdown popup={filters && <Popup filters={filters} />} isHoverable>
      <a
        className={classNames("button", "has-badge-rounded", {
          "is-loading": state.isLoading,
        })}
        {...disabledProps}
        data-badge={filters && filters.length > 0 ? filters.length : null}
      >
        <span className="icon is-small">
          <FontAwesomeIcon icon={faFilter} />
        </span>
      </a>
    </Dropdown>
  );
}

export default PredefinedButton;
