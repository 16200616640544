import React, { useMemo } from "react";
import styled from "@emotion/styled";
import { ProjectType } from "@core/types/domain/ProjectType";
import AppPageSpinner from "~/layouts/app/AppPageSpinner";
import { formatLocalDateTime } from "~/utils/dateUtils";
import { ExcelDownloadButton } from "~/screens/app/excel/service/ExcelDownloadButton";
import { useFinishedLivestreams } from "~/screens/app/detection/livestreams/data/useFinishedLivestreams";
import { ExcelTimelineButton } from "~/screens/app/excel/service/ExcelTimelineButton";
import { ProjectSelectorWithLabel } from "~/elements/context/project/FloatingProjectSelector";

interface ExcelReportsViewProps {
  project: ProjectType;
}

export function ExcelReportsView({ project }: ExcelReportsViewProps) {
  const projectId = useMemo(() => project && [project.id], [project]);
  const { data: streams, isLoading } = useFinishedLivestreams(projectId);

  const sorted = useMemo(() => {
    if (!streams) return streams;
    return [...streams].sort((a, b) => {
      return a.plannedStart?.getTime() - b.plannedStart?.getTime();
    });
  }, [streams]);

  if (isLoading) return <AppPageSpinner />;
  if (!project) return null;

  return (
    <>
      <SelectorRow>
        <ProjectSelectorWithLabel autoSelect />
        {sorted ? (
          <ExcelDownloadButton
            project={project}
            streams={sorted}
            disabled={!sorted.length}
          />
        ) : null}
      </SelectorRow>
      {sorted?.length ? (
        <Table>
          <thead>
            <tr>
              <th>#</th>
              <th>Start</th>
              <th>Title</th>
              <th>Download</th>
            </tr>
          </thead>
          <tbody>
            {sorted.map((stream) => (
              <tr key={stream.id}>
                <td>#{stream.id}</td>
                <td>{formatLocalDateTime(stream.plannedStart)}</td>
                <td>{stream.title}</td>
                <td align={"center"}>
                  <ExcelTimelineButton
                    project={project}
                    stream={stream}
                    disabled={!streams.length}
                  />
                  {/*<FontAwesomeIcon icon={faFileDownload} color={primaryColor} />*/}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : null}
    </>
  );
}

const Table = styled.table`
  td,
  th {
    padding-right: 1em;
    padding-top: 0.2em;
    padding-bottom: 0.2em;
    vertical-align: middle;
    &[align="center"] {
      text-align: center;
    }
  }
`;

const SelectorRow = styled.div`
  display: flex;
  & > * {
    margin-right: 1rem;
  }
`;
