import { KeyBy } from "@core/types/utils/utilTypes";
import { useQuery } from "react-query";
import { DemoLiveStream } from "@core/types/domain/Livestream";
import {
  BrandStats,
  LiveStreamStats,
  LiveStreamStatsData,
} from "@core/types/domain/livestream/BrandStats";
import { StreamStats } from "./useStreamStats";
import { toJson } from "~/services/fetchApi";

export function useJsonStreamStats(data: DemoLiveStream) {
  if (!data.jsonUrl) throw new Error("missing jsonUrl");
  return useQuery<StreamStats>(
    "useJsonStreamStats",
    () => fetchJsonStats(data),
    {
      retry: false,
    }
  );
}

function fetchJsonStats(stream: DemoLiveStream): Promise<StreamStats> {
  console.log("fetchJsonStats");
  return fetch(stream.jsonUrl)
    .then(toJson)
    .then((json: ExternalJson[]) => {
      const newStats = convert(json);
      console.log("newStats", newStats);
      const legacy = convertToLegacy(newStats);
      console.log("legacy", legacy);
      return {
        stats: legacy,
      };
    });
}

const sizeToFactor = {
  small: 1,
  medium: 2,
  big: 3,
};

function convertToLegacy(data: LiveStreamStats): LiveStreamStatsData {
  return Object.entries(data).reduce((result, [second, secondStats]) => {
    result[second] = Object.entries(secondStats.brands).reduce(
      (result, [brandName, brandStats]) => {
        result[brandName] = Object.entries(brandStats.sizes).reduce(
          (result, [sizeName, sizeStats]) => {
            result.duration = Math.max(result.duration, sizeStats.duration);
            result.score += sizeStats.score;
            result.scale += sizeStats.scale;
            return result;
          },
          {
            name: brandName,
            duration: 0,
            score: 0,
            scale: 0,
          }
        );

        return result;
      },
      {} as KeyBy<BrandStats>
    );
    return result;
  }, {} as LiveStreamStatsData);
}

function convert(api: ExternalJson[]): LiveStreamStats {
  const bySecond: LiveStreamStats = {};

  api.forEach((data) => {
    let secondStats = bySecond[data.second];
    if (!secondStats) {
      secondStats = {
        second: data.second,
        brands: {},
      };
      bySecond[data.second] = secondStats;
    }

    // iterate over all objects (brands) in this capture
    Object.entries(data.objects).forEach(([brandName, newBrandStats]) => {
      let brandStats = secondStats.brands[brandName];
      if (!brandStats) {
        brandStats = {
          name: brandName,
          sizes: {},
        };
        secondStats.brands[brandName] = brandStats;
      }

      if (brandStats.sizes[newBrandStats.cat]) {
        throw new Error(
          `found duplicated size for ${brandName} at ${data.second}`
        );
      }

      const sizeFactor = sizeToFactor[newBrandStats.cat];
      if (!sizeFactor) {
        throw new Error(`found unknown size: ${newBrandStats.cat}`);
      }

      brandStats.sizes[newBrandStats.cat] = {
        duration: newBrandStats.lduration,
        scale: newBrandStats.size,
        score: sizeFactor * newBrandStats.lduration,
        name: brandName,
      };
    });
  });

  return bySecond;
}

interface ExternalJson {
  fduration: number;
  frame: number;
  second: number;
  objects: KeyBy<{
    count: number;
    size: number;
    lduration: number;
    cat: SizeCat;
  }>;
}

type SizeCat = "medium" | "big" | "small";
