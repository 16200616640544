import React from "react";
import styled from "@emotion/styled";
import MainNav from "../../views/nav/MainNav";
import BaseLayout from "../BaseLayout";
import HeaderWithLogin from "./header/HeaderWithLogin";
import { WithChildren } from "~/types/reactTypes";
import { isMobile } from "~/utils/style/styleUtils";

export type MainLayoutProps = WithChildren;

function MainLayout(props: MainLayoutProps) {
  return (
    <BaseLayout>
      <HeaderWithLogin />
      <MainWrapper className="columns is-fullheight">
        <MainNav />
        <MainSection className={"column section"}>{props.children}</MainSection>
      </MainWrapper>
    </BaseLayout>
  );
}

const MainWrapper = styled.div`
  margin-left: 0;
  margin-right: 0;
`;

const MainSection = styled.div`
  ${isMobile} {
    padding-top: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;

export default MainLayout;
