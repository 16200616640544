import { useQuery } from "react-query";
import { ProjectId } from "@core/types/domain/ProjectType";
import { DemoLiveStream, LiveStream } from "@core/types/domain/Livestream";
import { demoStreams } from "./demo/data/demoStreams";
import { fetchApi, requireOk, toJson } from "~/services/fetchApi";

export function useProjectLiveStreams(filter: FetchLiveStreamOptions) {
  return useQuery(
    filter && ["useProjectLiveStreams", filter],
    // fetchLiveStreams,
    fetchDemoStreams,
    { retry: false }
  );
}

export function useLiveStream(id: number) {
  if (!id) throw new Error("invalid id: " + id);
  return {
    data: demoStreams.find((s) => s.id === id),
  };
}

interface FetchLiveStreamOptions {
  projectId: ProjectId;
}

function fetchLiveStreams(
  key: string,
  filter: FetchLiveStreamOptions
): Promise<LiveStream[]> {
  return fetchApi(`/project/${filter.projectId}/livestreams`)
    .then(requireOk)
    .then(toJson);
}

function fetchDemoStreams() {
  return new Promise<DemoLiveStream[]>((resolve) => {
    resolve(demoStreams);
  });
}
