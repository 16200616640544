import { useMemo, useState } from "react";
import { PaginationState } from "@core/types/misc/PaginationState";
import { queryParams } from "~/utils/jsUtils";

export function getCursorQueryParams(state: PaginationState) {
  return queryParams(state);
}

export function usePaginationState(pageSize = 25) {
  const [state, setState] = useState<PaginationState>({
    pageSize: 25,
    page: 0,
  });
  return useMemo(() => {
    return {
      pagination: state,
      setPagination: setState,
    };
  }, [state]);
}
