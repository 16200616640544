import React, { useEffect } from "react";
import styled from "@emotion/styled";
import AppPageLayout from "../../../layouts/app/AppPageLayout";
import { useFetchState } from "../../../utils/hooks/useFetchState";
import { VideoType } from "../../../@core/types/domain/video/VideoType";
import { fetchVideo } from "../../../services/video/videoService";
import AppPageSpinner from "../../../layouts/app/AppPageSpinner";
import TextareaInput from "../../../components/inputs/text/TextareaInput";
import VideoComments from "./comments/VideoComments";

interface VideoScreenProps {
  videoId?: number;
}

function VideoScreen(props: VideoScreenProps) {
  const { videoId } = props;
  if (!videoId) return null;
  const [state, dispatch] = useFetchState<VideoType>();
  useEffect(() => {
    fetchVideo(videoId).then(dispatch.onSuccess).catch(dispatch.onFail);
  }, [videoId]);
  if (state.isLoading) return <AppPageSpinner />;
  if (!state.data) return null;
  const video = state.data;
  return (
    <ScreenContainer>
      <h1>Video #{video.id}</h1>
      {video.note && (
        <VideoNote>
          <h2>Internal note</h2>
          <TextareaInput readOnly value={video.note} />
        </VideoNote>
      )}
      <VideoComments videoId={video.id} />
    </ScreenContainer>
  );
}

const ScreenContainer = styled(AppPageLayout)`
  h2 {
    margin-bottom: 1rem;
  }
  > div {
    margin-bottom: 1.2rem;
  }
`;

const VideoNote = styled.div`
  max-width: 600px;
`;

export default VideoScreen;
