import { useCallback, useMemo, useState } from "react";

type Fetcher<T extends any[]> = (...args: T[]) => Promise<Response>;

export interface UseFileDownloadArgs {
  onError?: (err: Error) => void;
  filename: string;
}

export function useFileDownload<T extends any[]>(
  fetcher: Fetcher<T>,
  config: UseFileDownloadArgs
) {
  const [isFetching, setFetching] = useState(false);
  const startFetch = useCallback(
    (args: T) => {
      setFetching(true);
      return fetcher(args)
        .then((value) => value.blob())
        .then((blob) => {
          const url = URL.createObjectURL(blob);
          const a = document.createElement("a");
          document.body.appendChild(a);
          a.style.display = "none";
          a.href = url;
          a.download = config.filename;
          a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch(config.onError)
        .then(() => {
          setFetching(false);
        });
    },
    [fetcher]
  );
  return useMemo(() => {
    return {
      isFetching,
      startFetch,
    };
  }, [startFetch, isFetching]);
}
