import { useQuery } from "react-query";
import { DetectionServer } from "@core/types/detection/DetectionServer";
import { requireOk, toJson } from "~/services/fetchApi";
import { PieChartFilterState } from "~/screens/app/detection/charts/filter/PieChartFilterState";

export interface FetchPlotlyChartArgs extends PieChartFilterState {
  projectId: string | number;
  streamId: string;
}

export function usePlotlyPieChart(args: FetchPlotlyChartArgs) {
  return useQuery(
    ["usePlotlyPieChart", args],
    () => fetchPlotlyPieChart(args),
    {
      enabled: !!args,
    }
  );
}

function fetchPlotlyPieChart(options: FetchPlotlyChartArgs) {
  const url = `${DetectionServer.L1.url}/chart/${options.projectId}/${options.streamId}/${options.category}/${options.criteria}/0/sunburst/data`;
  return fetch(url).then(requireOk).then(toJson);
}
