import React, { useMemo } from "react";
import { useSelectedProjectIds } from "~/store/project/projectHooks";
import { useLivestreams } from "~/screens/app/detection/livestreams/data/useLivestreams";
import AppPageSpinner from "~/layouts/app/AppPageSpinner";
import { LivestreamsTable } from "~/screens/app/detection/livestreams/LivestreamsTable";

interface LivestreamsViewProps {}

export function LivestreamsView(props: LivestreamsViewProps) {
  const projectIds = useSelectedProjectIds();
  const { data: streams, isLoading, isIdle } = useLivestreams(projectIds);

  const upcomingStreams = useMemo(() => {
    return streams?.filter((stream) => {
      return (
        stream.plannedStart &&
        (!stream.end || stream.end.getTime() > Date.now())
      );
    });
  }, [streams]);

  if (isLoading || isIdle) return <AppPageSpinner />;
  return <LivestreamsTable streams={upcomingStreams} />;
}
