import React, { HTMLProps } from "react";
import { css } from "@emotion/react";

type TextLinkButtonProps = Pick<
  HTMLProps<HTMLSpanElement>,
  "onClick" | "children"
>;

function TextLinkButton(props: TextLinkButtonProps) {
  return <span css={buttonCss} {...props} />;
}

const buttonCss = css`
  cursor: pointer;
  font-weight: bold;
  &:hover {
    opacity: 0.7;
  }
  //text-decoration: underline;
`;

export default TextLinkButton;
