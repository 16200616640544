import React, { useCallback } from "react";
import { css } from "@emotion/react";
import { UserRole } from "@core/types/domain/user/UserRole";
import {
  useProjectFilter,
  useProjectFilterDispatch,
} from "../sections/filter/ProjectFilter";
import EbuFilterToggle from "./EbuFilterToggle";
import { useUserRole } from "~/store/login/loginHooks";

interface VideoFilterWidgetProps {}

function VideoFilterWidget(props: VideoFilterWidgetProps) {
  const filter = useProjectFilter();
  const dispatch = useProjectFilterDispatch();

  const setOnlyEbu = useCallback(
    (onlyEbu: boolean) => {
      if (onlyEbu === filter.ebuMember) return;
      dispatch({
        ...filter,
        ebuMember: onlyEbu,
      });
    },
    [filter]
  );

  const isAdmin = useUserRole() === UserRole.SuperAdmin;
  if (!isAdmin) return null;

  return (
    <EbuFilterToggle
      css={containerCss}
      value={filter.ebuMember}
      onValueChange={setOnlyEbu}
    />
  );
}

const containerCss = css`
  margin-top: 8px;
`;

export default VideoFilterWidget;
