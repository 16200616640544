import { PostUpdateAsChecker } from "@core/types/domain/post/PostType";
import { fetchApi, requireOk, toJson } from "../fetchApi";

export function fetchUpdatePostAsChecker(
  id: number,
  update: Partial<PostUpdateAsChecker>
) {
  return fetchApi(`/checker/post/${id}`, {
    method: "PATCH",
    body: JSON.stringify(update),
  })
    .then(requireOk)
    .then(toJson);
}
