import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { RecordingFile } from "@core/types/recordings/RecordingFile";
import { AwsRecordingsLink } from "./AwsRecordingsLink";
import { fetchSavedRecordings } from "./service/fetchSavedRecordings";
import { RecordingTable } from "./list/ActiveRecordings";
import { useBindState } from "~/utils/hooks/useBindState";
import { useFetchState } from "~/utils/hooks/useFetchState";
import { formatLocalDateTime } from "~/utils/dateUtils";
import { formatFileSize } from "~/utils/formatUtils";
import { Excerpt } from "~/components/misc/Excerpt";
import { CopyText } from "~/elements/anchor/CopyText";
import { getQueryParam } from "~/utils/jsUtils";

export function ListRecordings() {
  const {
    value: projectId,
    setValue: setProjectId,
    binders,
  } = useBindState(process.env.NODE_ENV === "development" ? "113" : undefined);

  useEffect(() => {
    const urlId = getQueryParam("project");
    if (urlId) {
      setProjectId(urlId);
      onFetchFiles(projectId);
    }
  }, []);

  const [{ data: files }, filesState] = useFetchState<RecordingFile[]>();
  const onFetchFiles = (projectId: string) => {
    filesState.setLoading();
    fetchSavedRecordings(projectId)
      .then(filesState.onSuccess)
      .catch(filesState.onFail);
  };

  return (
    <Container>
      <h2>List recordings</h2>
      <Row>
        <div className="field has-addons">
          <div className="control">
            <input
              className="input"
              type="text"
              placeholder="Project ID"
              {...binders}
            />
          </div>
          <div className="control">
            <button
              className="button is-info"
              disabled={!projectId}
              onClick={() => {
                onFetchFiles(projectId);
              }}
            >
              List recordings
            </button>
          </div>
        </div>
        {projectId && <AwsRecordingsLink projectId={projectId} />}
      </Row>
      {files && (
        <RecordingTable>
          <thead>
            <tr>
              <th>Created</th>
              <th>Name</th>
              <th>Size</th>
              <th>Local path</th>
              <th>Remote path</th>
            </tr>
          </thead>
          <tbody>
            {files.map((file) => (
              <tr key={file.name}>
                <td className={"nowrap"}>
                  {formatLocalDateTime(file.created)}
                </td>
                <td>{file.name}</td>
                <td className={"nowrap"}>{formatFileSize(file.size)}</td>
                <td>
                  <CopyText text={file.path_name}>
                    <Excerpt length={36}>{file.path_name}</Excerpt>
                  </CopyText>
                </td>
                <td>
                  <a href={file.link_url}>
                    <Excerpt length={36}>{file.link_url}</Excerpt>
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </RecordingTable>
      )}
    </Container>
  );
}

const Row = styled.div`
  & > .field {
    display: inline-flex;
  }
  display: flex;
  align-items: center;
`;

const Container = styled.div`
  margin-top: 2rem;
`;
