import React from "react";
import { useSelector } from "react-redux";
import { IdsFilterType } from "../types/IdsFilterType";
import { projectsById } from "../../../../store/project/projectReducer";
import { FilterTagProps } from "./FilterTagProps";
import ActiveFilter from "./ActiveFilter";

type ProjectFilterProps = FilterTagProps<IdsFilterType>;

function ProjectFilter(props: ProjectFilterProps) {
  const projects = useSelector(projectsById);
  return (
    <ActiveFilter
      allFilters={props.allFilters}
      field={props.field}
      filter={props.filter}
      unremovable
    >
      Project:{" "}
      <span>
        {props.filter.in.map((id) => {
          const project = projects[id];
          return (
            <b key={id} title={project.name}>
              {project.id}
            </b>
          );
        })}
      </span>
    </ActiveFilter>
  );
}

export default ProjectFilter;
