import React, { MouseEvent } from "react";

interface ReloadProps {
  children?: any;
}

function onReload(event: MouseEvent<HTMLAnchorElement>) {
  window.location.reload();
  event.preventDefault();
}

function onMoveBack(event: MouseEvent<HTMLAnchorElement>) {
  window.history.back();
  event.preventDefault();
}

export function Reload(props: ReloadProps) {
  return <a onClick={onReload}>{props.children || "reload"}</a>;
}

export function NavigateBack(props: ReloadProps) {
  return <a onClick={onMoveBack}>{props.children || "back"}</a>;
}
