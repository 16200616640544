import React, { useEffect, useState } from "react";
import { Brand } from "@core/types/domain/Brand";
import styled from "@emotion/styled";
import {
  DetectionWithStream,
  VideoBrandStats,
} from "@core/types/detection/DetectionTypes";
import { LivestreamType } from "@core/types/domain/Livestream";
import BrandSelection from "~/screens/app/detection/summary_legacy/views/BrandSelection";
import {
  ProjectDetectionBrandStats,
  ProjectSummaryProps,
} from "~/screens/app/detection/stats/project/ProjectDetectionBrandStats";
import { useBrandsInStats } from "~/screens/app/detection/utils/useBrandsInStats";

interface BrandStatsSectionProps
  extends Pick<ProjectSummaryProps, "attributeChartConfig"> {
  stats: VideoBrandStats;
  results: DetectionWithStream[];
  totalLength: number;
  projectId: number;
  type: ProjectSummaryProps["type"];
  stream?: LivestreamType; // TODO this should be removed
}

export function BrandStatsSection({
  results,
  totalLength,
  stats,
  projectId,
  type,
  attributeChartConfig,
  stream,
}: BrandStatsSectionProps) {
  const { brands: brandsInStats } = useBrandsInStats(stats);
  const [selectedBrand, setSelectedBrand] = useState<Brand>();
  useEffect(() => {
    if (!brandsInStats) return;
    setSelectedBrand(brandsInStats[0]);
  }, [brandsInStats]);
  if (!results || !brandsInStats) return null;
  return (
    <>
      <BrandRow>
        <BrandSelection
          brands={brandsInStats}
          setSelectedBrand={setSelectedBrand}
          selectedBrand={selectedBrand}
        />
      </BrandRow>
      {selectedBrand && (
        <ProjectDetectionBrandStats
          brand={selectedBrand}
          brandsInStats={brandsInStats}
          results={results}
          stats={stats}
          totalLength={totalLength}
          projectId={projectId}
          type={type}
          attributeChartConfig={attributeChartConfig}
          stream={stream}
        />
      )}
    </>
  );
}

const BrandRow = styled.div`
  margin-bottom: 13px;
`;
