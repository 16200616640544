import { detectionStorageUrl } from "@core/config";
import { liveStreamTaskId } from "@core/utils/domain/detectionUtils";
import { platformById } from "../types/domain/Platform";
import {
  VideoCategory,
  videoCategoryById,
} from "../types/domain/video/VideoCategory";
import { BrandStats } from "../types/domain/livestream/BrandStats";

export function getPlatformName(key: string) {
  return platformById[key]?.name;
}

export function getCategoryName(key: string | VideoCategory) {
  return videoCategoryById[key]?.name;
}

export function getBrandStatsScore(stats: Omit<BrandStats, "score">) {
  return stats.duration * stats.scale * 100;
}

export function getStreamVideoUrl(projectId = "ROOT", streamId: number) {
  const taskId = liveStreamTaskId.fromStreamId(streamId);
  return `${detectionStorageUrl}/${projectId}/${taskId}.mp4`;
}
