import { useQuery } from "react-query";
import { DemoLiveStream } from "@core/types/domain/Livestream";
import { getBrandStatsScore } from "@core/utils/domainUtils";
import { StreamStats } from "./useStreamStats";
import { notNull } from "~/utils/jsUtils";

export function useDemoStreamStats(data: DemoLiveStream) {
  return useQuery<StreamStats>(
    "useProjectLiveStreams",
    () => fetchConfig("-1", data),
    {
      retry: false,
    }
  );
}

export interface DemoConfig extends StreamStats {
  version: string;
}

function fetchConfig(id: string, stream: DemoLiveStream): Promise<DemoConfig> {
  return fetch(stream.dataSheet)
    .then((res) => res.text())
    .then((csvText) => {
      // console.debug(csvText)
      const allRows = csvText.split("\n");
      const [firstRow, secondRow, ...rows] = allRows;

      const firstCols = firstRow.split("\t");
      const secondCols = secondRow.split("\t");

      const version = secondCols[0]; // version for developing purpose

      const brands = firstCols
        .map((value, i) => {
          if (i === 0) return null;
          if (!value?.trim()) return null;
          return value;
        })
        .filter(notNull);

      const stats = rows.reduce((result, row) => {
        const allCols = row.split("\t");

        const [time, ...cols] = allCols;
        const timeStats = result[time] || {};
        if (!result[time]) {
          result[time] = timeStats;
        }

        for (let i = 0; i < brands.length; i++) {
          const col = i * 2;
          const brand = brands[i];
          const brandStats = { name: brand, duration: 0, scale: 0, score: 0 };
          brandStats.duration = getFloat(cols[col]);
          brandStats.scale = getFloat(cols[col + 1]);
          brandStats.score = getBrandStatsScore(brandStats);
          if (brandStats.duration && brandStats.scale) {
            timeStats[brand] = brandStats;
          }
        }

        return result;
      }, {});

      return {
        version,
        stats,
      };
    });
}

function getFloat(str: string) {
  return parseFloat(str?.replace(/,/g, "."));
}
