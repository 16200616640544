import { VideoRange } from "~/screens/app/detection/stream/videorange/range/useVideoRanges";
import { getQueryParam, setQueryParam, toInt } from "~/utils/jsUtils";

export function queryPersistRange(ranges: VideoRange[]) {
  setQueryParam(QUERY_PARAM, serializeRanges(ranges));
}

export function resolvePeristedRanges(): VideoRange[] {
  const s = getQueryParam(QUERY_PARAM);
  if (!s) return [];
  return deserializeRanges(s);
}

export function serializeRanges(ranges: VideoRange[]) {
  return ranges
    .map((r) => {
      return Math.floor(r.from) + RANGE_DELIM + Math.floor(r.to);
    })
    .join(DELIM);
}

export function deserializeRanges(ranges: string) {
  return ranges.split(DELIM).map((range) => {
    const [from, to] = range.split(RANGE_DELIM).map(toInt);
    return {
      from,
      to,
    };
  });
}

const RANGE_DELIM = "-";
const DELIM = "_";
const QUERY_PARAM = "ranges";
