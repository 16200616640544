import React, { useEffect, useMemo, useState } from "react";
import styled from "@emotion/styled";
import { liveStreamTaskId } from "@core/utils/domain/detectionUtils";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import Plot from "react-plotly.js";
import {
  FetchPlotlyChartArgs,
  usePlotlyPieChart,
} from "~/screens/app/detection/charts/usePlotlyPieChart";
import { PieChartFilters } from "~/screens/app/detection/charts/filter/PieChartFilters";
import { usePieChartFilterState } from "~/screens/app/detection/charts/filter/PieChartFilterState";
import {
  StreamFilterSelector,
  StreamSelectOption,
} from "~/screens/app/detection/stats/stream/StreamFilterSelector";
import { useSelectedProjectId } from "~/store/project/projectHooks";
import { useFinishedLivestreams } from "~/screens/app/detection/livestreams/data/useFinishedLivestreams";
import Notification from "~/components/notification/Notification";
import MultiProjectSelector from "~/elements/context/project/MultiProjectSelector";
import { SelectorLabelWrapper } from "~/elements/misc/SelectorLabelWrapper";
import AppPageSpinner from "~/layouts/app/AppPageSpinner";
import { ErrorNotification } from "~/components/notification/ErrorNotification";
import { ChartExplain } from "~/screens/app/detection/charts/ChartExplain";
import { ChartHelpIntro } from "~/screens/app/detection/charts/ChartHelpIntro";
import { ClickableIcon } from "~/components/button/ClickableIcon";
import { useStoredTaskInfo } from "~/services/detection/storage/fetchStoredTaskInfo";

export function StreamPieCharts() {
  const projectId = useSelectedProjectId();
  const { data: streams, isLoading } = useFinishedLivestreams(projectId);
  const [filter, setFilter] = usePieChartFilterState();

  const hasStreams = !!streams?.length;

  const [selectedStream, setSelectedStream] = useState<StreamSelectOption>();
  const selectedStreamId = useMemo(() => {
    return selectedStream
      ? liveStreamTaskId.fromStreamId(selectedStream.id)
      : undefined;
  }, [selectedStream]);

  useEffect(() => {
    if (!projectId || !streams) return;
    if (!selectedStream || selectedStream.projectId !== projectId) {
      // project has changed and there is no selection
      setSelectedStream(streams?.[0]);
    }
  }, [projectId, streams, selectedStream]);

  const [showHelp, setShowHelp] = useState(false);
  const { data: taskInfo } = useStoredTaskInfo(
    selectedStream?.projectId,
    selectedStream ? liveStreamTaskId.fromStreamId(selectedStream.id) : null
  );
  return (
    <div>
      <SelectorRow>
        <FieldWrapper>
          <SelectorLabelWrapper label={"Project"}>
            <MultiProjectSelector autoSelect multi={false} />
          </SelectorLabelWrapper>
          {hasStreams && (
            <SelectorLabelWrapper label={"Event"}>
              <StreamFilterSelector
                isLoading={!streams}
                streams={streams}
                values={selectedStream}
                onChange={(values) => {
                  setSelectedStream(values ? values[0] : undefined);
                }}
                formatTitle={(values) => {
                  if (!values?.[0]) return "-";
                  return values[0].title;
                }}
                singleValue
              />
            </SelectorLabelWrapper>
          )}
        </FieldWrapper>
        {hasStreams && (
          <FieldWrapper>
            <PieChartFilters
              filter={filter}
              setFilter={setFilter}
              info={taskInfo}
            />
            <ClickableIcon
              icon={faQuestionCircle}
              onClick={() => {
                setShowHelp((prev) => !prev);
              }}
            />
          </FieldWrapper>
        )}
      </SelectorRow>
      {showHelp && <ChartHelpIntro />}
      {streams?.length === 0 && (
        <Notification type={"info"} marginTop={"1rem"}>
          No completed streams could be found for this project. Please select
          another project.
        </Notification>
      )}
      {isLoading && <AppPageSpinner />}
      {hasStreams && selectedStreamId && (
        <>
          <Charts
            projectId={projectId}
            streamId={selectedStreamId}
            {...filter}
          />
        </>
      )}
    </div>
  );
}

function Charts(props: FetchPlotlyChartArgs) {
  const { data, isLoading, error } = usePlotlyPieChart(props);
  if (isLoading) return <AppPageSpinner />;
  if (error)
    return (
      <ErrorNotification marginTop={"1rem"}>
        Data could not be retrieved. This may be due to an error or missing data
        (e.g. because the event is too old).
      </ErrorNotification>
    );
  return (
    <ChartRow>
      <Chart data={data?.["data1"]?.[0]} />
      <Chart data={data?.["data0"]?.[0]} />
      <div>
        <ChartExplain filter={props} />
      </div>
    </ChartRow>
  );
}

function Chart({ data }: { data: any }) {
  const chartData = useMemo(() => {
    if (!data) return undefined;
    return [data];
  }, [data]);
  return (
    <Plot
      data={chartData}
      layout={{
        autosize: true,
        margin: {
          t: 0,
          b: 0,
          l: 0,
          r: 0,
        },
        paper_bgcolor: "transparent",
      }}
      useResizeHandler
    />
  );
}

const SelectorRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FieldWrapper = styled.div`
  & > * {
    margin-right: 1rem;
    margin-bottom: 1rem;
    &:last-child {
      margin-right: 0;
    }
  }
`;

const ChartRow = styled.div`
  display: flex;
  min-height: 50vh;
  margin-top: 3rem;
  & > div {
    max-width: 480px;
    display: flex;
    margin-right: 2em;
  }
`;
