import React, { useMemo } from "react";
import DailyBarChart, {
  DailyBarChartProps,
} from "../../../../components/charts/DailyBarChart";
import { ensureDate } from "../../../../utils/dateUtils";
import {
  GetPostHistoryEntry,
  GetPostHistoryResult,
} from "../../../../@core/types/api/stats/getPostHistory";

interface PostHistoryProps extends Omit<DailyBarChartProps, "data"> {
  data: GetPostHistoryResult;
  field: keyof GetPostHistoryEntry;
}

function PostHistory({ data, field, ...props }: PostHistoryProps) {
  const chartData = useMemo(() => {
    return data?.map((entry) => ({
      date: ensureDate(entry.date),
      value: entry[field] as number,
    }));
  }, [data]);
  return <DailyBarChart {...props} data={chartData} />;
}

export default PostHistory;
