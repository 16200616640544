import React from "react";
import styled from "@emotion/styled";
import {
  activeDetectionServers,
  DetectionServer,
} from "@core/types/detection/DetectionServer";
import { useDetectionServerStatus } from "~/services/detection/detectionService";
import { LoadingIcon } from "~/components/icons/LoadingIndicator";

interface ServerStatusProps {
  server: DetectionServer;
}

export function ServerStatus() {
  return (
    <Table>
      <thead>
        <tr>
          <th>Server</th>
          <th>Free memory</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {activeDetectionServers.map((server) => (
          <ServerRow key={server.id} server={server} />
        ))}
      </tbody>
    </Table>
  );
}

function ServerRow({ server }: ServerStatusProps) {
  const { data: status, isFetching } = useDetectionServerStatus(
    server,
    serverStatusQueryConfig
  );
  return (
    <tr>
      <td>{server.label}</td>
      <td>
        {status?.memoryFree ? (
          <>
            {formatMemory(status?.memoryFree)} /{" "}
            {formatMemory(status?.memoryTotal)}
          </>
        ) : (
          <span>-</span>
        )}
      </td>
      <td>
        <LoadingIcon isLoading={isFetching} />
      </td>
    </tr>
  );
}

const Table = styled.table`
  td,
  th {
    padding-right: 1em;
    padding-bottom: 0.2em;
  }

  td:first-of-type {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

function formatMemory(mb: number) {
  return mb?.toLocaleString() + " MB";
}

export const serverStatusQueryConfig = {
  refetchOnWindowFocus: true,
  refetchInterval: 1000 * 30,
  staleTime: 1000,
};
