import { useQuery } from "react-query";
import { LivestreamType } from "@core/types/domain/Livestream";
import { liveStreamTaskId } from "@core/utils/domain/detectionUtils";
import { ApiAccumulatedResult } from "@core/types/detection/ApiDetectionTypes";
import { DetectionServer } from "@core/types/detection/DetectionServer";
import { toJson } from "~/services/fetchApi";
import { debugDelay } from "~/utils/debugUtils";
import { TimeRangeInfo } from "~/screens/app/detection/stream/videorange/SectionSelectorView";

interface Result {
  all: ApiAccumulatedResult;
}

export function useRangeStreamStats(
  stream: Pick<LivestreamType, "id" | "projectId">,
  ranges?: TimeRangeInfo[]
) {
  return useQuery<Result>(
    stream && ["useSelectiveStreamStats", stream.id, ranges],
    () => {
      return fetchSelectiveStreamStats(stream, ranges);
    },
    {
      enabled: !!stream,
    }
  );
}

export function fetchSelectiveStreamStats(
  stream: Pick<LivestreamType, "id" | "projectId">,
  ranges?: TimeRangeInfo[]
) {
  // const folder = stream. <= 162 ? "ROOT" : stream.projectId
  const folder = stream.projectId;
  const task = liveStreamTaskId.fromStreamId(stream.id);
  let url = `/task_download/${folder}/${task}`;
  if (ranges?.length) {
    url += getRanges(ranges);
  }
  return fetchLiquidApi(url)
    .then(debugDelay)
    .then((r) => r[0]);
}

function getRanges(ranges: TimeRangeInfo[]) {
  if (ranges.length === 1 && ranges[0].from === 0 && ranges[0].to === 0)
    return "";
  else
    return `/${ranges.map((r) => Math.floor(r.from)).join(",")}/${ranges
      .map((r) => Math.floor(r.to))
      .join(",")}`;
}

export function fetchLiquidApi(endpoint: string, server = DetectionServer.S6) {
  return fetch(`${server.url}${endpoint}`).then(toJson);
}
