import React, { useMemo } from "react";
import { ChartData, ChartOptions } from "chart.js";
import { Brand } from "@core/types/domain/Brand";
import { VideoBrandStats } from "@core/types/detection/DetectionTypes";
import { BrandStatsBySize } from "@core/types/domain/livestream/BrandStats";
import { fallbackColors } from "../../../../components/charts/PieChart";
import ChartjsChart from "../../../../components/charts/ChartjsChart";
import { roundN } from "../../../../utils/js/mathUtils";
import { formatDuration } from "../../../../utils/formatUtils";

interface BrandSummaryProps {
  brands: Brand[];
  stats: VideoBrandStats;
}

function BrandSummary({ brands, stats }: BrandSummaryProps) {
  const datasets = useMemo(() => {
    const keys = new Set<keyof BrandStatsBySize>();
    Object.values(stats).forEach((obj) =>
      Object.keys(obj).forEach((key) => {
        if (key === "all") return;
        keys.add(key);
      })
    );
    return Array.from(keys);
  }, [stats]);
  const data: ChartData = useMemo(() => {
    return {
      labels: brands.map((b) => b.label),
      datasets: datasets.map((field, i) => {
        return {
          label: `${field}`,
          backgroundColor: fallbackColors[i],
          data: brands.map((b) => {
            const brandStats = stats[b.name];
            const duration = brandStats[field]?.duration || 0;
            return roundN(duration, 2);
          }),
        };
      }),
    };
  }, [stats]);
  const options: ChartOptions = useMemo(() => {
    return {
      scales: {
        yAxes: [
          {
            ticks: {
              // display: false,
            },
            stacked: true,
          },
        ],
        xAxes: [
          {
            ticks: {
              callback(value: number) {
                return formatDuration(value);
              },
            },
            stacked: true,
          },
        ],
      },
    };
  }, []);

  return <ChartjsChart type={"horizontalBar"} options={options} data={data} />;
}

export default BrandSummary;

export const logoSizes = [
  { label: "small" },
  { label: "medium" },
  { label: "big" },
];
