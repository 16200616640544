import {
  RecordingStartArgs,
  RecordingStartResponse,
} from "@core/types/recordings/RecordingApi";
import { fetchApi, requireOk, toJson } from "~/services/fetchApi";

export function fetchStartRecording(args: RecordingStartArgs) {
  return fetchApi(`/recordings/start`, {
    method: "POST",
    body: JSON.stringify(args),
  })
    .then(requireOk)
    .then<RecordingStartResponse>(toJson);
}
