import React, { useMemo } from "react";
import { faEye, faFlag, faVideo } from "@fortawesome/free-solid-svg-icons";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import ReactCountUp from "react-countup";
import {
  DetectionResult,
  DetectionWithStream,
} from "@core/types/detection/DetectionTypes";
import { getProjectViewsValue } from "@core/utils/domain/projectUtils";
import NumberWidget from "~/screens/app/dashboard/widgets/NumberWidget";
import { formatDurationFull } from "~/utils/formatUtils";
import { TopBrandsWidget } from "~/screens/app/detection/stats/project/summary/TopBrandsWidget";
import WidgetGrid from "~/components/widget/WidgetGrid";
import { isCollapsed } from "~/utils/style/styleUtils";
import Explain from "~/components/bulma/Explain";
import { useDetectionFilter } from "~/screens/app/detection/stats/components/filter/DetectionFilter";
import { DetectionFilterView } from "~/screens/app/detection/stats/components/filter/DetectionFilterView";
import { BrandStatsSection } from "~/screens/app/detection/stats/components/widgets/BrandStatsSection";
import { BrandsLineChartWidget } from "~/screens/app/detection/stats/components/widgets/BrandsLineChartWidget";
import { useDetectionStats } from "~/screens/app/detection/stats/components/widgets/useDetectionStats";
import { useProject } from "~/store/project/projectHooks";
import { ProjectSummaryProps } from "~/screens/app/detection/stats/project/ProjectDetectionBrandStats";
import { StreamWithEuroValue } from "~/screens/app/detection/stats/stream/useStatsWithEuroValue";

interface VideoDetectionViewProps {
  results: DetectionWithStream[];
  projectId: number;
  type: ProjectSummaryProps["type"];
}

export function VideoDetectionView({
  results,
  projectId,
  type,
}: VideoDetectionViewProps) {
  const { filter, setFilter } = useDetectionFilter();
  const { summary, brands } = useDetectionStats(results, filter);
  const project = useProject(projectId);
  const viewValue = useMemo(() => {
    return getProjectViewsValue(project, summary.totalViews);
  }, [project, summary]);
  if (!results || !brands) return null;
  return (
    <div>
      <DetectionFilterView
        filter={filter}
        setFilter={setFilter}
        rawStats={results}
      />
      <Grid>
        <NumberWidget
          title={"Total videos"}
          subTitle={"total number of videos"}
          icon={faVideo}
          value={results.length}
          subValue={
            <span>
              {formatDurationFull(summary.totalLength)}
              <Explain>Total duration of footage</Explain>
            </span>
          }
          css={css`
            grid-area: A;
          `}
        />
        {type === "stream" ? (
          <NumberWidget
            title={"Total Brands"}
            subTitle={"total number of brands"}
            icon={faFlag}
            value={brands.length}
            css={css`
              grid-area: B;
            `}
          />
        ) : (
          <NumberWidget
            title={"Total views"}
            subTitle={"total views of footage"}
            subValue={
              viewValue ? (
                <ReactCountUp end={viewValue} suffix={" €"} separator={"."} />
              ) : undefined
            }
            icon={faEye}
            value={summary.totalViews}
            css={css`
              grid-area: B;
            `}
          />
        )}
        <TopBrandsWidget
          css={css`
            grid-area: C;
          `}
          stats={summary.summed}
          brands={brands}
        />
        <BrandsLineChartWidget
          stats={summary.summed}
          brands={brands}
          css={css`
            grid-area: Chart;
          `}
          project={project}
        />
      </Grid>
      <BrandStatsSection
        stats={summary.summed}
        results={results}
        totalLength={summary.totalLength}
        projectId={projectId}
        type={type}
        attributeChartConfig={null}
      />
    </div>
  );
}

const Grid = styled(WidgetGrid)`
  grid-template-areas: "A B Chart Chart" "C C Chart Chart";
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  ${isCollapsed} {
    grid-template-areas: "Chart Chart Chart Chart" "A A B B" "C C C C";
  }
`;
