import React, { useMemo } from "react";
import styled from "@emotion/styled";
import { UserRole } from "@core/types/domain/user/UserRole";
import {
  useProjectSocialStats,
  useSelectedProjectIds,
} from "../../../store/project/projectHooks";
import { PostFilter } from "../../../@core/types/api/stats/PostFilter";
import { useLocalBind } from "../../../utils/hooks/useLocalBind";
import { ProjectSelectorWithLabel } from "../../../elements/context/project/FloatingProjectSelector";
import PostFilterWidget from "./PostFilterWidget";
import SocialDashboardWidgets from "./SocialDashboardWidgets";
import { useUserRole } from "~/store/login/loginHooks";

interface SocialDashboardScreen {}

function SocialDashboard(props: SocialDashboardScreen) {
  const isAdmin = useUserRole() === UserRole.SuperAdmin;
  const projectIds = useSelectedProjectIds();
  const { value: ebu, onValueChange } = useLocalBind(false);
  const filter: PostFilter = useMemo(() => {
    return { projectIds, ebuMember: !!ebu };
  }, [projectIds, ebu]);
  const socialStats = useProjectSocialStats(filter);

  return (
    <>
      <FilterOptions>
        <ProjectSelectorWithLabel autoSelect />
        {isAdmin && (
          <PostFilterWidget value={ebu} onValueChange={onValueChange} />
        )}
      </FilterOptions>
      <SocialDashboardWidgets filter={filter} socialStats={socialStats} />
    </>
  );
}

const FilterOptions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 14px;
  margin-left: 2px;
  margin-right: 2px;
`;

export default SocialDashboard;
