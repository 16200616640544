import React, { createRef, useCallback, useEffect } from "react";
import styled from "@emotion/styled";
import {
  fetchAddVideoComment,
  fetchDeleteVideoComment,
  fetchVideoComments,
} from "../../../../services/video/videoCommentService";
import { alertError } from "../../../../utils/alert/alertUtils";
import { useFetchState } from "../../../../utils/hooks/useFetchState";
import { VideoCommentType } from "../../../../@core/types/domain/video/VideoCommentType";
import TextareaInput from "../../../../components/inputs/text/TextareaInput";
import { useHasPermission } from "../../../../store/login/permissionSelectors";
import { UserPermission } from "../../../../@core/types/domain/user/UserPermission";
import Comment from "./Comment";

interface VideoCommentsProps {
  videoId: number;
}

function VideoComments(props: VideoCommentsProps) {
  const { videoId } = props;
  const [commentFetch, dispatch] = useFetchState<VideoCommentType[]>();
  const canUpdateComments = useHasPermission(UserPermission.canWriteComment);
  const comments = commentFetch.data;
  const input = createRef<HTMLTextAreaElement>();
  useEffect(() => {
    fetchVideoComments(videoId).then(dispatch.onSuccess).catch(dispatch.onFail);
  }, [videoId]);
  const onDelete = useCallback(
    (commentId: number) => {
      fetchDeleteVideoComment(videoId, commentId)
        .then(() => {
          dispatch.onSuccess(
            comments.filter((value) => value.id !== commentId)
          );
        })
        .catch(alertError);
    },
    [comments]
  );
  const onSubmit = useCallback(() => {
    const message = input.current.value.trim();
    if (!message) {
      return alertError("Message must not be empty");
    }
    fetchAddVideoComment(videoId, { message })
      .then((comment) => {
        input.current.value = "";
        dispatch.onSuccess([...comments, comment]);
      })
      .catch((err) => {
        alertError(err);
      });
  }, [comments]);
  const hasComments = comments && comments.length > 0;
  if (!hasComments && !canUpdateComments) return null;
  return (
    <div>
      <h2>Comments</h2>
      <CommentsContainer>
        {hasComments && (
          <CommentsList>
            <ul>
              {comments.map((comment) => (
                <Comment
                  key={comment.id}
                  comment={comment}
                  onDelete={canUpdateComments ? onDelete : null}
                />
              ))}
            </ul>
          </CommentsList>
        )}
        {canUpdateComments && (
          <>
            <TextareaInput
              ref={input}
              placeholder={"New comment"}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  (event.target as HTMLTextAreaElement).blur();
                  onSubmit();
                }
              }}
            />
            <ButtonRow>
              <button className={"button is-primary"} onClick={onSubmit}>
                Add comment
              </button>
              <small>&nbsp;&nbsp;or press enter</small>
            </ButtonRow>
          </>
        )}
      </CommentsContainer>
    </div>
  );
}

const ButtonRow = styled.div`
  margin-top: 14px;
  display: flex;
  align-items: center;
`;

const CommentsContainer = styled.div`
  max-width: 600px;
`;

const CommentsList = styled.div`
  max-height: 500px;
  overflow-y: auto;
  margin-bottom: 14px;
`;

export default VideoComments;
