import React from "react";
import styled from "@emotion/styled";
import ProjectSelector, {
  ProjectSelectionProps,
} from "~/elements/context/project/ProjectSelector";

type ProjectSelectorRowProps = ProjectSelectionProps;

export function ProjectSelectorRow(props: ProjectSelectorRowProps) {
  return (
    <Row>
      <label className={"label"}>{"Project"}</label>
      <ProjectSelector {...props} />
    </Row>
  );
}

const Row = styled.div`
  margin-bottom: 1rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  & > label {
    margin-right: 1rem;
    margin-bottom: 0 !important;
  }
`;
