import React, { useCallback, useRef, useState } from "react";
import styled from "@emotion/styled";
import { fetchApi, requireOk, toJson } from "../../../services/fetchApi";
import { alertError } from "../../../utils/alert/alertUtils";

interface PasswordGeneratorProps {}

function PasswordGenerator(props: PasswordGeneratorProps) {
  const input = useRef<HTMLInputElement>();
  const [result, setResult] = useState("");
  const [fetching, setFetching] = useState(false);

  const onRequestPassword = useCallback(() => {
    const pw = input.current.value;
    setFetching(true);
    fetchApi("/admin/generate-pw?pw=" + pw)
      .then(requireOk)
      .then(toJson)
      .then((json) => {
        setResult(json && json.hash);
      })
      .catch((error) => {
        alertError(error);
      })
      .then(() => {
        setFetching(false);
      });
  }, []);

  return (
    <Container>
      <h2>Create password</h2>
      <div className="field has-addons">
        <div className="control">
          <input
            ref={input}
            className="input"
            type="text"
            placeholder="New password"
          />
        </div>
        <div className="control">
          <button
            type={"button"}
            className="button is-info"
            onClick={onRequestPassword}
            disabled={fetching}
          >
            Generate
          </button>
        </div>
      </div>
      <div className="field">
        <div className="control">
          <input
            readOnly
            value={result}
            className="input"
            type="text"
            placeholder="Hash"
          />
        </div>
      </div>
    </Container>
  );
}

const Container = styled.div`
  max-width: 520px;
`;

export default PasswordGenerator;
