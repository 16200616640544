import React from "react";
import styled from "@emotion/styled";

interface SamplesProps {
  onSelect: (url: string) => void;
}

const samples = [
  {
    id: 1,
    name: "Locally served video for testing",
    url: "/root/shared/demo_ath_src.mp4",
  },
  {
    id: 2,
    name: "Same as #1, but will be downloaded from an external server",
    url: "http://s2.onlinecontrolserver.net/stream/demo_ath.mp4",
  },
  {
    id: 3,
    name: "YouTube example",
    url: "https://www.youtube.com/watch?v=iOR_rHfaJjc",
  },
  {
    id: 4,
    name: "Real RTSP stream (which unfortunately has nothing to do with sport)",
    url: "rtsp://wowzaec2demo.streamlock.net/vod/mp4:BigBuckBunny_115k.mov",
  },
  {
    id: 5,
    name: "Soelden Livestream 2",
    url: "/var/www/html/dl/livestream_2.mp4",
  },
];

export function Samples(props: SamplesProps) {
  return (
    <Container>
      <select
        value={""}
        onChange={(event) => {
          props.onSelect(event.currentTarget.value);
        }}
      >
        <option value="">Example</option>
        {samples.map((s) => (
          <option key={s.id} value={s.url}>
            #{s.id} {s.name}
          </option>
        ))}
      </select>
    </Container>
  );
}

const Container = styled.div`
  max-width: 112px;
`;
