import React, { FormEvent, HTMLProps, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import styled from "@emotion/styled";

interface SearchInputProps
  extends Pick<HTMLProps<HTMLInputElement>, "value" | "id" | "placeholder"> {
  onValueChange: (value: string) => void;
}

function SearchInput({ onValueChange, ...props }: SearchInputProps) {
  const onChange = useCallback(
    (event: FormEvent<HTMLInputElement>) => {
      onValueChange(event.currentTarget.value);
    },
    [onValueChange]
  );

  const onClear = useCallback(() => {
    onValueChange("");
  }, [onValueChange]);
  return (
    <Container>
      <input
        {...props}
        onChange={onChange}
        className={"input"}
        autoComplete="off"
      />
      <ButtonWrapper>
        <IconButton onClick={onClear}>
          <FontAwesomeIcon
            icon={faTimesCircle}
            title={"Clear filter"}
            fixedWidth
          />
        </IconButton>
      </ButtonWrapper>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
`;

const ButtonWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 6px;
  display: flex;
  align-items: center;
`;

const IconButton = styled.div`
  cursor: pointer;
  opacity: 0.5;
  padding: 2px;
  :hover {
    opacity: 0.4;
  }
`;

export default SearchInput;
