import { CheckerProject } from "@core/types/domain/ProjectType";
import { GetUncheckedPostsQuery } from "@core/types/api/checkerApiTypes";
import { PostType } from "@core/types/domain/post/PostType";
import { fetchApi, requireOk, toJson } from "../fetchApi";
import { queryParams } from "~/utils/jsUtils";

export function fetchCheckerProjects(): Promise<CheckerProject[]> {
  return fetchApi(`/checker/projects`).then(requireOk).then(toJson);
}
export function fetchUncheckedPosts(
  options: GetUncheckedPostsQuery
): Promise<PostType[]> {
  const query = queryParams(options);
  return fetchApi(`/checker/posts?${query}`).then(requireOk).then(toJson);
}

export interface CheckStatus {
  remaining: number;
}

export function fetchCheckStatus(filter: GetUncheckedPostsQuery) {
  const query = queryParams(filter);
  return fetchApi(`/checker/stats?${query}`)
    .then(requireOk)
    .then<CheckStatus>(toJson);
}
