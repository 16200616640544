import React, { useMemo } from "react";
import { Link } from "gatsby";
import { useQuery } from "react-query";
import styled from "@emotion/styled";
import { fetchDetectionTasks } from "@core/service/detection/fetchDetectionTasks";
import { TaskInfo } from "@core/types/detection/DetectionTypes";
import { liveStreamTaskId } from "@core/utils/domain/detectionUtils";
import { DebugDetectionPage } from "../DebugDetectionPage";
import Button from "~/components/button/Button";
import { isTaskRunning } from "~/services/detection/detectionUtils";
import { useDetectionController } from "~/screens/app/detection/debug/status/useDetectionController";
import { LoadingIcon } from "~/components/icons/LoadingIndicator";
import { useCheckboxState } from "~/components/inputs/checkbox/Checkbox";
import Switch from "~/components/inputs/checkbox/Switch";
import { AppRoute } from "~/config/Routes";
import { formatDuration } from "~/utils/formatUtils";
import {
  ServerStatus,
  serverStatusQueryConfig,
} from "~/screens/app/detection/debug/status/ServerStatus";
import { formatLocalDateTime } from "~/utils/dateUtils";

export function DetectionStatusScreen() {
  const { data, isFetching } = useQuery(
    "/status",
    fetchDetectionTasks,
    serverStatusQueryConfig
  );
  const { stop } = useDetectionController();
  const onlyRunnig = useCheckboxState(true);
  const filtered = useMemo(() => {
    return data?.filter((job) => {
      if (onlyRunnig.checked) {
        return job.status === "init" || job.status === "working";
      } else return true;
    });
  }, [data, onlyRunnig]);
  return (
    <DebugDetectionPage>
      <Container>
        <h2>Server status</h2>
        <ServerStatus />
        <h2>
          Detection Jobs <LoadingIcon isLoading={isFetching} />
        </h2>
        <Switch title={"Show only running"} {...onlyRunnig.bindings} />
        <Table>
          <thead>
            <tr>
              <th>#</th>
              <th>Server</th>
              <th>Status</th>
              <th>Status text</th>
              <th>Created</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filtered?.map((task) => {
              const key = `${task.server.name}_${task.id}`;
              const progressInfo = formatProgress(task);
              const streamId = liveStreamTaskId.toStreamId(task.id);
              return (
                <tr key={key}>
                  <td>
                    <Link to={`${task.server.url}/task/${task.id}`}>
                      {task.id}
                    </Link>
                  </td>
                  <td>{task.server?.label}</td>
                  <td>
                    {task.status}
                    {progressInfo ? ` (${progressInfo})` : null}
                  </td>
                  <td className={"is-status-text"}>{task.statusText}</td>
                  <td>{formatLocalDateTime(task.createdAt)}</td>
                  <td>
                    {isTaskRunning(task) && (
                      <Buttons>
                        <Button
                          variant={"is-primary"}
                          small
                          onClick={() => stop(task.id, task.server)}
                        >
                          Stop
                        </Button>
                        {/*<Button*/}
                        {/*  variant={"is-danger"}*/}
                        {/*  small*/}
                        {/*  onClick={() => stop(task.id, task.server, true)}*/}
                        {/*>*/}
                        {/*  Kill*/}
                        {/*</Button>*/}
                        <Link to={AppRoute.Livestream.create(streamId)}>
                          <Button variant={"is-link"} small>
                            LIVE
                          </Button>
                        </Link>
                      </Buttons>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Container>
      {/*<pre>{JSON.stringify(filtered, null, 2)}</pre>*/}
    </DebugDetectionPage>
  );
}

const Container = styled.div`
  h2 {
    margin-top: 1em;
    &:first-of-type {
      margin-top: 0;
    }
  }
`;

function formatProgress(task: TaskInfo) {
  if (task?.status !== "working") return null;
  if (task.progress) {
    return `${task.progress.toFixed(1)}%`;
  } else if (task.progressPos) {
    return `${formatDuration(task.progressPos)}s`;
  } else return null;
}

const Buttons = styled.div`
  button {
    margin-right: 8px;
  }
`;

const Table = styled.table`
  th,
  td {
    padding: 4px 8px;
  }

  td.is-status-text {
    max-width: 620px;
  }
`;
