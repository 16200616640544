import React, { useCallback, useMemo } from "react";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { LivestreamTypeExternal } from "@core/types/domain/Livestream";
import { ProjectType } from "@core/types/domain/ProjectType";
import IconButton from "~/components/button/IconButton";
import {
  excelApiUrl,
  slugifyFileName,
} from "~/screens/app/excel/service/excelService";
import { requireOk } from "~/services/fetchApi";
import { alertError } from "~/utils/alert/alertUtils";
import {
  useFileDownload,
  UseFileDownloadArgs,
} from "~/utils/hooks/useFileDownload";

interface ExcelDownloadButtonProps {
  project: ProjectType;
  streams: LivestreamTypeExternal[];
  disabled?: boolean;
}

export function ExcelDownloadButton({
  project,
  streams,
  disabled,
}: ExcelDownloadButtonProps) {
  const data = useMemo(() => {
    const request = {
      title: project.name,
      filename: slugifyFileName(project.name),
      events: streams.map((s) => ({
        project_id: `${s.projectId}`,
        project_title: `${s.projectId}`,
        id: `${s.id}`,
        title: s.title,
        planned_start: s.plannedStart,
        planned_end: s.plannedEnd,
      })),
    };
    return JSON.stringify(request);
  }, [project, streams]);

  const fetcher = useCallback(
    (data: any) => {
      const form = new FormData();
      form.append("request", data);
      return fetch(excelApiUrl, {
        method: "POST",
        body: form,
      }).then(requireOk);
    },
    [data]
  );

  const config: UseFileDownloadArgs = useMemo(() => {
    return {
      filename: slugifyFileName(project.name),
      onError: (err) => {
        alertError(`Failed fetching file: ${err.message}`);
      },
    };
  }, [project]);

  const { startFetch, isFetching } = useFileDownload(fetcher, config);

  const onFetchSubmit = useCallback(() => {
    startFetch(data);
  }, [data]);

  return (
    <IconButton
      icon={faDownload}
      loading={isFetching}
      variant={"is-primary"}
      disabled={disabled || isFetching}
      onClick={onFetchSubmit}
    >
      Project summary
    </IconButton>
  );
}
