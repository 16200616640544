import React from "react";
import { faChartPie } from "@fortawesome/free-solid-svg-icons";
import { useProjectStats } from "../../../store/project/projectHooks";
import WidgetGrid from "../../../components/widget/WidgetGrid";
import Widget from "../../../components/widget/Widget";
import PlatformWidget from "./views/PlatformWidget";
import StatusWidget from "./views/StatusWidget";
import NumberStatsWidget from "./views/NumberStatsWidget";
import { useSelectedProjectFilter } from "./sections/filter/ProjectFilter";
import CategoryChart from "./widgets/CategoryChart";

interface DashboardWidgetsProps {}

function DashboardWidgets(props: DashboardWidgetsProps) {
  const filter = useSelectedProjectFilter();
  const { isLoading, data: stats } = useProjectStats(filter);

  return (
    <>
      <WidgetGrid>
        <div>
          <StatusWidget loading={isLoading} byStatus={stats?.byStatus} />
        </div>
        <div>
          <PlatformWidget loading={isLoading} byPlatform={stats?.byPlatform} />
        </div>
        <Widget title={"Categories"} icon={faChartPie} loading={isLoading}>
          <CategoryChart byCategory={stats?.byCategory} />
        </Widget>
      </WidgetGrid>
      <NumberStatsWidget stats={stats} />
    </>
  );
}

export default DashboardWidgets;
