import { keyBy } from "../../../utils/jsUtils";

export type SizeName = "medium" | "big" | "small";

export interface LogoSizeType {
  value: number;
  name: SizeName;
}

const sizes: LogoSizeType[] = [
  { name: "small", value: 5 },
  { name: "medium", value: 10 },
  { name: "big", value: 15 },
];

export const sizeByName = keyBy(sizes, (s) => s.name);
export const sizeByValue = keyBy(sizes, (s) => s.value.toString());
