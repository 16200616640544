import React, { useEffect } from "react";
import { navigate } from "gatsby";
import { Router } from "@reach/router";
import MainLayout from "../../layouts/main/MainLayout";
import SEO from "../../components/seo";
import PrivateRoute from "../../components/links/PrivateRoute";
import NotFound from "./shared/NotFound";
import { useLoginState } from "~/store/login/loginHooks";
import { useAppRoutes } from "~/screens/app/appRoutes";
import { ReactQueryProvider } from "~/utils/fetch/ReactQueryProvider";

export function App() {
  if (process.env.GATSBY_IS_BLANK_APP && process.env.NODE_ENV !== "development")
    return null;
  const { loggedIn, user } = useLoginState();
  useEffect(() => {
    if (!loggedIn) {
      navigate("/login");
    }
  }, [loggedIn]);

  const routes = useAppRoutes(user);

  if (!loggedIn) return null;
  // TODO permission checks might be missing here!
  return (
    <MainLayout>
      <SEO title="App" />
      <ReactQueryProvider>
        <Router defaultValue={routes?.[0].path}>
          {routes?.map((route) => (
            <PrivateRoute
              key={route.path}
              path={route.path}
              component={route.component}
              canAccess={route.canAccess}
            />
          ))}
          <NotFound default />
        </Router>
      </ReactQueryProvider>
    </MainLayout>
  );
}
