import React from "react";
import { faListOl } from "@fortawesome/free-solid-svg-icons";
import { Brand } from "@core/types/domain/Brand";
import { ProjectType } from "@core/types/domain/ProjectType";
import { VideoBrandStats } from "@core/types/detection/DetectionTypes";
import Widget from "~/components/widget/Widget";
import Explain from "~/components/bulma/Explain";
import CompareChart from "~/screens/app/detection/summary_legacy/views/CompareChart";
import { getDetectionSizeExplainText } from "~/screens/app/detection/stats/project/SizeChart";

interface BrandsLineChartWidgetProps {
  stats: VideoBrandStats;
  brands: Brand[];
  className?: string;
  project: ProjectType;
}

export function BrandsLineChartWidget({
  stats,
  brands,
  className,
  project,
}: BrandsLineChartWidgetProps) {
  return (
    <Widget
      icon={faListOl}
      title={
        <span>
          Duration compared
          <Explain text={getDetectionSizeExplainText(project)} />
        </span>
      }
      className={className}
    >
      <CompareChart stats={stats} brandsInStats={brands} />
    </Widget>
  );
}
