import React, { useMemo } from "react";
import { RouteComponentProps } from "@reach/router";
import styled from "@emotion/styled";
import AppPageLayout from "~/layouts/app/AppPageLayout";
import { ProjectSelectionContext } from "~/elements/context/project/ProjectSelectionContext";
import { StreamStatsView } from "~/screens/app/detection/stream/StreamStatsView";
import { Spinner } from "~/components/spinner/Spinner";
import {
  StreamFilterSelector,
  useStreamFilterState,
} from "~/screens/app/detection/stats/stream/StreamFilterSelector";
import { useLivestreams } from "~/screens/app/detection/livestreams/data/useLivestreams";
import { useSelectedProjectIds } from "~/store/project/projectHooks";
import ProjectSelector from "~/elements/context/project/ProjectSelector";

interface StreamStatsPageProps extends RouteComponentProps {
  streamId: string;
}

export function StreamStatsPage(props: StreamStatsPageProps) {
  return (
    <AppPageLayout>
      <ProjectSelectionContext resolveFromUrl>
        <WithSelector />
      </ProjectSelectionContext>
    </AppPageLayout>
  );
}

function WithSelector() {
  const projectIds = useSelectedProjectIds();
  const { data: streams, isFetching: l1 } = useLivestreams(projectIds);
  const selectableStreams = useMemo(() => {
    return streams?.filter((s) => s.end || s.isTest);
  }, [streams]);
  const streamSelection = useStreamFilterState(selectableStreams, "stream");
  if (l1) return <Spinner />;
  const stream = streamSelection.firstValue;
  return (
    <>
      <div>
        <SelectorRow>
          <ProjectSelector autoSelect />
          <StreamFilterSelector
            streams={selectableStreams}
            formatTitle={(v) => {
              return (
                <StreamSelectorTitle>
                  {v?.[0] ? v[0].title : "Select a stream"}
                </StreamSelectorTitle>
              );
            }}
            {...streamSelection.bindings}
            singleValue
          />
        </SelectorRow>
      </div>
      {stream && (
        <>
          <h1>Stream stats: {stream.title}</h1>
          <StreamStatsView stream={stream} />
        </>
      )}
    </>
  );
}

const StreamSelectorTitle = styled.span`
  text-align: left;
  width: 320px;
  overflow: hidden;
`;

const SelectorRow = styled.div`
  display: flex;
  justify-content: flex-end;
  & > div {
    margin-left: 12px;
  }
`;
