import React from "react";
import AppPageLayout from "../../../layouts/app/AppPageLayout";
import VideoList from "../../../views/videos/VideoList";
import { VideoFilterType } from "../../../views/videos/filter/types/VideoFilterType";

interface LawyerVideosScreenProps {}

function LawyerVideosScreen(props: LawyerVideosScreenProps) {
  return (
    <AppPageLayout>
      <h1>Videos with Notes</h1>
      <VideoList forcedFilter={forcedFilter} />
    </AppPageLayout>
  );
}

const forcedFilter: VideoFilterType = {
  hasNote: {
    value: true,
  },
};

export default LawyerVideosScreen;
