import React from "react";
import { useQuery } from "react-query";
import styled from "@emotion/styled";
import { FootageFile } from "@core/types/api/footageRouteTypes";
import { ProjectId } from "@core/types/domain/ProjectType";
import { fetchApi, requireOk, toJson } from "~/services/fetchApi";
import { formatDateTime } from "~/utils/dateUtils";
import { formatFileSize } from "~/utils/formatUtils";
import { useSelectedProjectId } from "~/store/project/projectHooks";
import { useUserPermissions } from "~/store/login/loginHooks";
import { LoadingIndicator } from "~/components/icons/LoadingIndicator";
import Notification from "~/components/notification/Notification";

export function FootageList() {
  const { isAdmin } = useUserPermissions();
  const projectId = useSelectedProjectId();
  const { data: files, isFetching } = useFootage(projectId);
  return (
    <div>
      <h2>
        Uploaded files <Loading isLoading={isFetching} />
      </h2>
      {files?.length === 0 && (
        <Notification type={"info"}>No uploads found</Notification>
      )}
      {files?.length && (
        <FileTable>
          <thead>
            <tr>
              <th>Name</th>
              <th>Size</th>
              <th>Last modified</th>
            </tr>
          </thead>
          <tbody>
            {files?.map((file) => (
              <tr key={file.name}>
                <td>
                  {isAdmin ? (
                    <a href={file.url}>{file.name}</a>
                  ) : (
                    <span>{file.name}</span>
                  )}
                </td>
                <td>{formatFileSize(file.size)}</td>
                <td>{formatDateTime(file.createdAt)}</td>
              </tr>
            ))}
          </tbody>
        </FileTable>
      )}
    </div>
  );
}

const FileTable = styled.table`
  td,
  tr {
    padding-right: 24px;
  }
`;

const Loading = styled(LoadingIndicator)`
  margin-top: 2px;
  margin-left: 0.5rem;
  font-size: 16px;
  opacity: 0.6;
`;

export function useFootage(projectId: ProjectId) {
  return useQuery(["useFootage", projectId], () => fetchFootageUrl(projectId), {
    refetchOnWindowFocus: true,
    staleTime: 10000,
  });
}

export const getFootageQueryKey = "useFootage";

function fetchFootageUrl(projectId: ProjectId): Promise<FootageFile[]> {
  if (!projectId) return undefined;
  return fetchApi(`/footage/${projectId}`, {})
    .then(requireOk)
    .then<FootageFile<string>[]>(toJson)
    .then((results) =>
      results.map((r) => ({
        ...r,
        createdAt: new Date(r.createdAt),
      }))
    );
}
