import { DemoLiveStream } from "@core/types/domain/Livestream";
import { LiveStreamStatsData } from "@core/types/domain/livestream/BrandStats";
import { useDemoStreamStats } from "./useDemoStreamStats";
import { useJsonStreamStats } from "./useJsonStreamStats";

export interface StreamStats {
  stats: LiveStreamStatsData;
}

export function useStreamStats(stream: DemoLiveStream) {
  if (stream.dataSheet) {
    return useDemoStreamStats(stream);
  } else if (stream.jsonUrl) {
    return useJsonStreamStats(stream);
  } else {
    throw new Error("stream is missing any data source");
  }
}
