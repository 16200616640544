export enum PostMediaType {
  Unknown = 0,
  Text = 1,
  Image = 2,
  Video = 3,
  Link = 4,
}

export interface PostMediaOption {
  id: number;
  title: string;
}

const postMediaTypes: Record<PostMediaType, PostMediaOption> = {
  [PostMediaType.Unknown]: { id: PostMediaType.Unknown, title: "Unset" },
  [PostMediaType.Text]: { id: PostMediaType.Text, title: "Text" },
  [PostMediaType.Image]: { id: PostMediaType.Image, title: "Image" },
  [PostMediaType.Video]: { id: PostMediaType.Video, title: "Video" },
  [PostMediaType.Link]: { id: PostMediaType.Link, title: "Link" },
};

export const allPostMediaTypes = Object.values(postMediaTypes);

export function getMediaType(id: number): PostMediaOption {
  return postMediaTypes[id] || postMediaTypes[PostMediaType.Unknown];
}
