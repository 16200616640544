import React from "react";
import { ProjectDetectionView } from "./ProjectDetectionView";
import { useSelectedProject } from "~/store/project/projectHooks";

interface ProjectDetectionPageProps {}

export function ProjectDetectionPage(props: ProjectDetectionPageProps) {
  const project = useSelectedProject();
  if (!project) return null;
  return (
    <>
      <h1>{project.name}</h1>
      <ProjectDetectionView project={project} />
    </>
  );
}
