import React from "react";
import styled from "@emotion/styled";
import AppPageLayout from "~/layouts/app/AppPageLayout";
import { ProjectSelectionContext } from "~/elements/context/project/ProjectSelectionContext";
import CheckerView from "~/screens/app/checker/CheckerView";
import { useSelectedProject } from "~/store/project/projectHooks";
import { ProjectSelectorWithLabel } from "~/elements/context/project/FloatingProjectSelector";
import AppPageSpinner from "~/layouts/app/AppPageSpinner";
import Notification from "~/components/notification/Notification";

function CheckPostsScreen() {
  return (
    <AppPageLayout>
      <ProjectSelectionContext resolveFromUrl>
        <SelectionRow>
          <ProjectSelectorWithLabel autoSelect />
        </SelectionRow>
        <CheckPostsView />
      </ProjectSelectionContext>
    </AppPageLayout>
  );
}

function CheckPostsView() {
  const project = useSelectedProject();

  if (project === undefined) return <AppPageSpinner />;

  if (!project)
    return (
      <Notification type={"danger"}>
        You are not assigned to any active project that needs post checking. Get
        in touch with our support.
      </Notification>
    );

  return <CheckerView project={project} />;
}

const SelectionRow = styled.div`
  margin-bottom: 24px;
`;

export default CheckPostsScreen;
