import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { TextInput } from "~/components/inputs/text/TextInput";
import Button from "~/components/button/Button";

interface MultiEventSelectorProps {
  onChange: (streamIds: number[]) => void;
}

export function MultiEventSelector({ onChange }: MultiEventSelectorProps) {
  const [streamInput, setStreamInput] = useState("1704,1705");
  useEffect(() => {
    if (!streamInput || !onChange) return;
    onChange(streamInput.split(",").map((s) => parseInt(s)));
  }, []);
  return (
    <div>
      <Row>
        <TextInput
          value={streamInput}
          onChange={(e) => setStreamInput(e.target.value)}
        />
        <Button
          onClick={() => {
            return onChange(streamInput.split(",").map((s) => parseInt(s)));
          }}
          type={"button"}
          variant={"is-primary"}
        >
          Load
        </Button>
      </Row>
      {/*<SelectDropdown*/}
      {/*  options={projects}*/}
      {/*  getOptionLabel={(project) => project.name}*/}
      {/*  getOptionValue={(project) => project.id}*/}
      {/*  values={project}*/}
      {/*  onChange={setProject}*/}
      {/*  keys={["name"]}*/}
      {/*/>*/}
    </div>
  );
}
const Row = styled.div`
  display: flex;
  max-width: 32em;
`;

export interface StreamInfo {
  projectId: number;
  streamId: number;
}
