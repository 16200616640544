import { ProjectType } from "@core/types/domain/ProjectType";

export function getDetectionSizeExplainText(project: ProjectType | undefined) {
  if (isDtm(project)) {
    return (
      "Based on the relative size of the logo \ncompared to screen size:\n" +
      "Small:  <5%, Medium: <10%, Large ≥10%"
    );
  }
  return (
    "Based on the relative size of the logo \ncompared to screen size:\n" +
    "Small:  <10%, Medium: <17%, Large ≥17%"
  );
}

function isDtm(project: ProjectType) {
  if (!project) return;
  return [160, 161].indexOf(project.id) !== -1;
}
