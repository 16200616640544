import { useMemo } from "react";
import {
  BrandStats,
  ByBrand,
  BySecond,
  DetailedBrandStats,
  LiveStreamStatsData,
} from "@core/types/domain/livestream/BrandStats";
import { KeyBy } from "@core/types/utils/utilTypes";
import cloneDeep from "lodash/cloneDeep";
import { avg } from "../../../../utils/js/mathUtils";

export function useSummedStreamStats(
  data: LiveStreamStatsData,
  seconds?: number
): BrandStats[] {
  return useMemo(() => {
    const stats = sumStreamStats(data, seconds);
    return stats && Object.values(stats);
  }, [data, seconds]);
}

type LegacyBrandStats = DetailedBrandStats;

type LegacyStats = BySecond<ByBrand<LegacyBrandStats>>;

export function sumStreamStats(
  propsData: LiveStreamStatsData,
  seconds: number = Object.keys(propsData).length,
  startSeconds = 0
) {
  const data = propsData as LegacyStats;
  if (!data) return undefined;
  const from = startSeconds;
  const to = startSeconds + seconds;
  const summed = Object.entries(data).reduce(
    (summed, [secondStr, secondStats]) => {
      const second = parseInt(secondStr);
      if (second > to || second < from) {
        // ignore data because it is higher than requested
        return summed;
      }

      Object.entries(secondStats).forEach(([brand, brandStats]) => {
        const summedStats = summed[brand];
        if (!summedStats) {
          summed[brand] = {
            duration: brandStats.duration,
            scale: brandStats.scale,
            scales: [brandStats.scale],
            name: brandStats.name,
            score: brandStats.score,
            byType: cloneDeep(brandStats.byType),
            bySize: cloneDeep(brandStats.bySize),
          };
        } else {
          summedStats.scale += brandStats.scale;
          summedStats.duration += brandStats.duration;
          summedStats.score += brandStats.score;

          for (const type in brandStats.byType) {
            summedStats.byType[type].duration =
              (summedStats.byType[type].duration || 0) +
              brandStats.byType[type].duration;
          }

          for (const type in brandStats.bySize) {
            summedStats.bySize[type].duration =
              (summedStats.bySize[type].duration || 0) +
              brandStats.bySize[type].duration;
          }
        }
      });

      return summed;
    },
    {} as KeyBy<BrandStatsTmp>
  );

  Object.values(summed).map((brandStats) => {
    brandStats.scale = avg(brandStats.scales);
    delete brandStats.scales;
    return brandStats as BrandStats;
  });

  return summed;
}

export interface BrandStatsTmp extends DetailedBrandStats {
  scales: number[];
}
