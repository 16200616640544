import { useQuery } from "react-query";
import { RecordingTask } from "@core/types/recordings/RecordingTask";
import { fetchApi, requireOk, toJson } from "~/services/fetchApi";
import { queryClient } from "~/utils/fetch/ReactQueryProvider";

function fetchActiveRecordings() {
  return fetchApi("/recordings/status")
    .then(requireOk)
    .then<RecordingTask<string>[]>(toJson)
    .then<RecordingTask[]>((results) =>
      results.map((json) => ({
        ...json,
        created: new Date(json.created),
      }))
    );
}

export function useActiveRecordings() {
  return useQuery(key, fetchActiveRecordings, {
    refetchInterval: 1000 * 15,
    refetchOnWindowFocus: true,
  });
}

export function refetchActiveRecordings() {
  queryClient.invalidateQueries(key);
}

const key = "useActiveRecordings";
