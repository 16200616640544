import React, { useMemo } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { formatDuration } from "~/utils/formatUtils";

interface TimePickerProps {
  onClick: () => void;
  onTimeClick?: (seconds: number) => void;
  value: number;
  isSmall?: boolean;
}

export function TimePicker({
  onClick,
  onTimeClick,
  value,
  isSmall,
}: TimePickerProps) {
  const formattedValue = useMemo(() => formatDuration(value, true), [value]);
  return (
    <Container className="field has-addons" isSmall={isSmall}>
      <div className="control">
        <input
          className={`input${isSmall ? " is-small" : ""}`}
          type="text"
          placeholder="Name"
          value={formattedValue}
          autoComplete="off"
          onClick={() => onTimeClick?.(value)}
        />
      </div>
      <div className="control">
        <button
          className={`button is-primary ${isSmall ? "is-small" : ""}`}
          onClick={onClick}
        >
          Set
        </button>
      </div>
    </Container>
  );
}

export const timePickerInputFormatCss = css`
  input,
  button {
    height: 2.2em;
  }
`;

const Container = styled.div<{ isSmall: boolean }>`
  input {
    max-width: ${(props) => (props.isSmall ? 66 : 88)}px;
    font-weight: bold;
    text-align: center;
  }

  ${timePickerInputFormatCss};
`;
