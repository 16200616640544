import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { LivestreamType } from "@core/types/domain/Livestream";
import { RecordingStartArgs } from "@core/types/recordings/RecordingApi";
import { liveStreamTaskId } from "@core/utils/domain/detectionUtils";
import { useBindState } from "~/utils/hooks/useBindState";
import { fetchLivestream } from "~/screens/app/detection/livestreams/data/useLivestreams";
import { getQueryParam, toInt } from "~/utils/jsUtils";

interface FromStreamSelectorProps {
  onSet: (next: RecordingStartArgs) => void;
}

export function FromStreamSelector({ onSet }: FromStreamSelectorProps) {
  const {
    value: id,
    setValue: setId,
    binders,
  } = useBindState(process.env.NODE_ENV === "development" ? "408" : "");
  const [stream, setStream] = useState<LivestreamType>();
  useEffect(() => {
    const id = getQueryParam("stream", toInt);
    if (id) {
      setId(`${id}`);
      getFromStream(id);
    }
  }, []);
  const getFromStream = (id: number) => {
    fetchLivestream(id).then((stream) => {
      onSet({
        id: liveStreamTaskId.fromStreamId(stream.id),
        groupId: `${stream.projectId}`,
        url: stream.url,
      });
      setStream(stream);
    });
  };
  return (
    <Container>
      <div className="field has-addons is-small">
        <div className="control">
          <input
            className="input is-small"
            type="text"
            placeholder="Livestream ID"
            {...binders}
          />
        </div>
        <div className="control is-small">
          <button
            className="button is-info is-small"
            disabled={!id}
            onClick={() => getFromStream(toInt(id))}
          >
            Read from Stream
          </button>
        </div>
      </div>
      <div>{stream?.title}</div>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  & > div {
    margin-bottom: 0.5rem;
    margin-right: 1rem;
  }
`;
