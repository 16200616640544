import React, { useMemo } from "react";
import { css } from "@emotion/react";
import { LivestreamType } from "@core/types/domain/Livestream";
import styled from "@emotion/styled";
import {
  DetectionResult,
  TaskInfo,
} from "@core/types/detection/DetectionTypes";
import {
  DetectionFilter,
  DetectionFilterTarget,
} from "~/screens/app/detection/stats/components/filter/DetectionFilter";
import { useProject } from "~/store/project/projectHooks";
import { TopBrandsWidget } from "~/screens/app/detection/stats/project/summary/TopBrandsWidget";
import { BrandsLineChartWidget } from "~/screens/app/detection/stats/components/widgets/BrandsLineChartWidget";
import WidgetGrid from "~/components/widget/WidgetGrid";
import { isCollapsed } from "~/utils/style/styleUtils";
import { usePlacementStats } from "~/screens/app/detection/stream/placement/usePlacementStats";
import { TimeRangeInfo } from "~/screens/app/detection/stream/videorange/SectionSelectorView";
import AppPageSpinner from "~/layouts/app/AppPageSpinner";
import { createDummyDetectionResult } from "~/screens/app/detection/stream/data/useRangeStreamResults";
import { useDynamicDetectionStats } from "~/screens/app/detection/data/dynamic/useDynamicDetectionStats";
import { BrandStatsSection } from "~/screens/app/detection/stats/components/widgets/BrandStatsSection";
import { AttributeChartTexts } from "~/screens/app/detection/summary_legacy/views/AttributePieChart";
import { usePlacementLabels } from "~/screens/app/detection/stream/placement/usePlacementLabels";

interface PlacementSectionStatsProps {
  stream: LivestreamType;
  ranges: TimeRangeInfo[];
  task: TaskInfo;
}

function usePlacementStatsResults(
  stream: LivestreamType,
  ranges: TimeRangeInfo[]
) {
  const { data, isFetching } = usePlacementStats(stream, ranges);
  return useMemo(() => {
    const result: DetectionResult = data
      ? createDummyDetectionResult(data)
      : undefined;
    return {
      isFetching,
      results: result ? [result] : undefined,
    };
  }, [stream, ranges, data, isFetching]);
}

export function PlacementSectionStats({
  stream,
  ranges,
  task,
}: PlacementSectionStatsProps) {
  const attributeChartConfig = useAttributeChartConfig(task);
  const { results, isFetching } = usePlacementStatsResults(stream, ranges);
  const legacyFilter: DetectionFilter = useMemo(() => {
    return {
      target: DetectionFilterTarget.all,
    };
  }, []);
  const { summary, brands } = useDynamicDetectionStats(results, legacyFilter);
  const project = useProject(stream.projectId);
  if (isFetching) return <AppPageSpinner />;
  if (!results || !brands) return null;
  return (
    <div>
      <Grid>
        <TopBrandsWidget
          css={css`
            grid-area: A;
          `}
          count={12}
          stats={summary.summed}
          brands={brands}
        />
        <BrandsLineChartWidget
          stats={summary.summed}
          brands={brands}
          css={css`
            grid-area: Chart;
          `}
          project={project}
        />
      </Grid>
      <BrandStatsSection
        stats={summary.summed}
        results={results}
        totalLength={summary.totalLength}
        projectId={stream.projectId}
        type={"stream"}
        attributeChartConfig={attributeChartConfig}
      />
    </div>
  );
}

function useAttributeChartConfig(task: TaskInfo): AttributeChartTexts {
  const { data: labels } = usePlacementLabels(task);
  return useMemo(() => {
    return {
      title: "Duration by Placement",
      subTitle: "summed logo durations",
      getAttrLabel(attr) {
        if (!labels) return attr;
        return labels[attr] || attr;
      },
    };
  }, [labels]);
}

const Grid = styled(WidgetGrid)`
  grid-template-areas: "A A Chart Chart" "A A Chart Chart";
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  ${isCollapsed} {
    grid-template-areas: "Chart Chart Chart Chart" "A A B B" "C C C C";
  }
`;
