import { useQuery } from "react-query";
import { fetchApi, requireOk, toJson } from "~/services/fetchApi";
import { sortBy } from "~/utils/jsUtils";

export interface DetectionModel<D = Date> {
  name: string;
  logos: string[];
  date: D;
}

function fetchDetectionModels() {
  return fetchApi("/logos/models")
    .then(requireOk)
    .then<DetectionModel<string>[]>(toJson)
    .then<DetectionModel[]>((results) => {
      const mapped = results.map((r) => {
        return {
          ...r,
          date: new Date(r.date),
          logos: sortBy(r.logos, (name) => name),
        };
      });
      return sortBy(mapped, (m) => m.date.getTime(), true);
    });
}

export function useDetectionModels() {
  return useQuery("/logos/models", fetchDetectionModels);
}
