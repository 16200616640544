export function getProjectBucketManageUrl(projectId: number) {
  return getBucketManageUrl("yoloout", `${projectId}/`);
}

export function getRecordingsBucket(projectId: string) {
  return {
    manageUrl: getBucketManageUrl("yoloout", `recordings/${projectId}/`),
  };
}

function getBucketManageUrl(bucket: string, prefix: string) {
  return `https://s3.console.aws.amazon.com/s3/buckets/${bucket}?region=eu-central-1&prefix=${prefix}&showversions=false`;
}
