import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faMailBulk,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import styled from "@emotion/styled";
import AppPageLayout from "../../layouts/app/AppPageLayout";

interface SupportProps {}

function Support(props: SupportProps) {
  return (
    <AppPageLayout>
      <h1>Support</h1>
      <p>
        Currently, our customer interface is still in work. Please use one of
        our regular contact methods:
      </p>
      <ContactContainer>
        {/*<div>*/}
        {/*  <Icon icon={faPhone}></Icon>*/}
        {/*  <a href="tel://0123456789">0123456789</a>*/}
        {/*</div>*/}
        <div>
          <Icon icon={faEnvelope} />
          <a href="mailto://support@onlinecontrol.eu">
            support@onlinecontrol.eu
          </a>
        </div>
      </ContactContainer>
    </AppPageLayout>
  );
}

const ContactContainer = styled.div`
  margin-top: 14px;
`;

const Icon = styled(FontAwesomeIcon)`
  margin-right: 12px;
`;

export default Support;
