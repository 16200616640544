import React from "react";
import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGavel,
  faTools,
  faUserCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useLoginState } from "../../../store/login/loginHooks";
import { UserRole } from "../../../@core/types/domain/user/UserRole";

interface AvatarToggleProps {}

function AvatarToggle(props: AvatarToggleProps) {
  const { user } = useLoginState();
  return (
    <Container className="navbar-item">
      <AvatarPlaceholder>
        <FontAwesomeIcon icon={faUserCircle} size={"2x"} />
      </AvatarPlaceholder>
      <span>{user.name}</span>
      {user.role === UserRole.Lawyer && (
        <RoleIndicator>
          <FontAwesomeIcon icon={faGavel} title={"Has lawyer role"} />
        </RoleIndicator>
      )}
      {user.role === UserRole.SuperAdmin && (
        <RoleIndicator>
          <FontAwesomeIcon icon={faTools} title={"Is super admin"} />
        </RoleIndicator>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
`;

const RoleIndicator = styled.div`
  margin-left: 6px;
`;

const AvatarPlaceholder = styled.div`
  margin-right: 8px;
  max-height: 36px;
`;

export default AvatarToggle;
