import React from "react";
import { EMPTY_STRING } from "~/utils/jsUtils";

type Value = number | string;

export interface SimpleSelectProps<T = any, V = string | number> {
  value?: Value;
  defaultValue?: Value;
  options: T[];
  extraOptions?: Array<{ label: string; value: V }>;
  getOptionLabel: (option: T) => string;
  getOptionValue: (option: T) => Value;
  onValueChange: (value: V) => void;
  formatTitle?: (values: T[]) => string;
  formatValue?: (value: string) => V;
  isLoading?: boolean;
}

function SimpleSelect<T, V = string | number>({
  getOptionLabel,
  getOptionValue,
  options,
  extraOptions,
  value,
  formatValue,
  defaultValue,
  onValueChange,
}: SimpleSelectProps<T, V>) {
  return (
    <div className={"select is-sma2ll"}>
      <select
        value={value || EMPTY_STRING}
        defaultValue={defaultValue}
        onChange={(event) => {
          const { value } = event.target;
          if (formatValue) {
            onValueChange(formatValue(value));
          } else {
            onValueChange(value as unknown as V);
          }
        }}
      >
        {extraOptions?.map(({ label, value }) => {
          const stringValue = `${value}`;
          return (
            <option key={stringValue} value={stringValue}>
              {label}
            </option>
          );
        })}
        {options.map((option) => {
          const value = getOptionValue(option);
          const label = getOptionLabel(option);
          return (
            <option key={value} value={value}>
              {label}
            </option>
          );
        })}
      </select>
    </div>
  );
}

export default SimpleSelect;
