import React, { useMemo } from "react";
import { LivestreamSectionType } from "@core/types/domain/stream/LivestreamSectionType";
import SelectDropdown from "~/elements/selectdropdown/SelectDropdown";
import { useStreamSections } from "~/services/stream/fetchStreamSection";
import { UseVideoRanges } from "~/screens/app/detection/stream/videorange/range/useVideoRanges";
import { UseStreamSectionState } from "~/screens/app/detection/stream/videorange/select/useStreamSectionState";

interface StreamSectionSelectorProps
  extends Pick<UseVideoRanges, "ranges">,
    Pick<UseStreamSectionState, "section" | "setSection"> {
  streamId: number;
}

export function SavedStreamSelector({
  streamId,
  section,
  setSection,
}: StreamSectionSelectorProps) {
  const { data: sections } = useStreamSections(streamId);
  const values = useMemo(() => (section ? [section] : undefined), [section]);
  return (
    <SelectDropdown
      values={values}
      getOptionLabel={formatOption}
      getOptionValue={(o) => o.id}
      keys={["name"]}
      options={sections}
      formatTitle={(values) => {
        const section = values?.[0];
        return section?.id
          ? `${section.name.substr(0, 32)}`
          : "Load saved section";
      }}
      singleValue
      onChange={(sections) => {
        const section = sections[0];
        if (section) {
          setSection(section || undefined);
        } else {
          setSection(undefined);
        }
      }}
    />
  );
}

function formatOption(section: LivestreamSectionType) {
  return section.name;
  // const start = formatDurationFull(section.start)
  // const end = formatDurationFull(section.end)
  // return `[${start} - ${end}] ${section.name}`
}
