import React from "react";
import styled from "@emotion/styled";
import { LivestreamType } from "@core/types/domain/Livestream";
import AppPageLayout from "~/layouts/app/AppPageLayout";
import { AdminStreamList } from "~/screens/app/admin/streams/AdminStreamList";
import { useLivestreams } from "~/screens/app/detection/livestreams/data/useLivestreams";
import AppPageSpinner from "~/layouts/app/AppPageSpinner";
import { ProjectSelectionContext } from "~/elements/context/project/ProjectSelectionContext";
import { ProjectSelectorWithLabel } from "~/elements/context/project/FloatingProjectSelector";

export function AdminStreamsScreen() {
  const { data: streams, isLoading } = useLivestreams(null);
  return (
    <AppPageLayout>
      {isLoading && <AppPageSpinner />}
      <ProjectSelectionContext resolveFromUrl>
        <ProjectSelectorRow>
          <ProjectSelectorWithLabel multi label={"Filter projects"} />
        </ProjectSelectorRow>
        {streams && <AdminStreams streams={streams} />}
      </ProjectSelectionContext>
    </AppPageLayout>
  );
}

function AdminStreams(props: { streams: LivestreamType[] }) {
  return <AdminStreamList streams={props.streams} />;
}

const ProjectSelectorRow = styled.div`
  margin-bottom: 12px;
  text-align: right;
`;
