import React, { useEffect, useMemo, useRef, useState } from "react";
import styled from "@emotion/styled";
import { Uppy } from "@uppy/core";
import AwsS3Multipart from "@uppy/aws-s3-multipart";
import { DashboardModal } from "@uppy/react";
import latinize from "latinize";
import { faBoxOpen } from "@fortawesome/free-solid-svg-icons";
import { UserRole } from "@core/types/domain/user/UserRole";
import { apiUrl } from "~/config";
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import Button from "~/components/button/Button";
import { getFootageQueryKey } from "~/screens/app/footage/FootageList";
import { useSelectedProjectId } from "~/store/project/projectHooks";
import { queryClient } from "~/utils/fetch/ReactQueryProvider";
import LinkButton from "~/components/button/LinkButton";
import { useUserRole } from "~/store/login/loginHooks";

interface FootageUploadUppyProps {}

export function FootageUploadUppy(props: FootageUploadUppyProps) {
  const isAdmin = useUserRole() === UserRole.SuperAdmin;
  const { open, setClosed, setOpened } = useModalState();
  const projectId = useSelectedProjectId();
  const $uppy = useRef<Uppy>(undefined);
  useEffect(() => {
    if (!projectId) return;
    const uppy = new Uppy({
      meta: { type: "avatar" },
      restrictions: { maxNumberOfFiles: 10 },
      autoProceed: true,
      onBeforeFileAdded: (file) => {
        if (file.name.indexOf("/") !== -1) {
          throw new Error("invalid characters");
        }
        const filename = `project/${projectId}/${fixFileName(file.name)}`;
        return {
          ...file,
          name: filename,
        };
      },
    });
    $uppy.current = uppy;

    uppy.use(AwsS3Multipart, {
      companionUrl: apiUrl + "/uppy",
      limit: 5,
    });

    uppy.on("complete", (result) => {
      queryClient.invalidateQueries(getFootageQueryKey);
    });
    return () => uppy.close();
  }, [projectId]);
  return (
    <Container>
      <Button onClick={setOpened} variant={"is-info"}>
        Upload files
      </Button>
      {isAdmin && (
        <LinkButton
          url={`https://s3.console.aws.amazon.com/s3/buckets/detection-footage?region=eu-central-1&prefix=project/${projectId}/`}
          variant={"is-danger"}
          target={"_blank"}
          icon={faBoxOpen}
        >
          AWS Bucket
        </LinkButton>
      )}
      {$uppy.current && (
        <DashboardModal
          open={open}
          uppy={$uppy.current}
          closeModalOnClickOutside
          showProgressDetails
          onRequestClose={setClosed}
        />
      )}
    </Container>
  );
}

const Container = styled.div`
  margin-bottom: 24px;

  & > .button {
    margin-right: 12px;
  }
`;

export function useModalState() {
  const [open, setOpen] = useState(false);
  return useMemo(() => {
    return {
      open,
      setOpened: () => setOpen(true),
      setClosed: () => setOpen(false),
    };
  }, [open]);
}

function fixFileName(name: string) {
  return latinize(name);
}
