import React, { useMemo } from "react";
import styled from "@emotion/styled";
import {
  faChartLine,
  faCheckDouble,
  faCopy,
  faFilm,
  faHashtag,
  faIcons,
  faList,
  faPhotoVideo,
  faSatellite,
  faSearchDollar,
  faTable,
  faTools,
  faTv,
  faVideo,
  faWifi,
} from "@fortawesome/free-solid-svg-icons";
import { UserRole } from "@core/types/domain/user/UserRole";
import NavMenu, { Group, Item } from "./NavMenu";
import { DEV } from "~/config";
import Footer from "~/layouts/main/Footer";
import Switch from "~/components/inputs/checkbox/Switch";
import { useDebugDispatcher, useDebugOptions } from "~/store/debug/debugHooks";
import { getQueryParam } from "~/utils/jsUtils";
import { useUserPermissions, useUserRole } from "~/store/login/loginHooks";
import { AppRoute } from "~/config/Routes";

function MainNav() {
  const role = useUserRole();
  const permissions = useUserPermissions();
  const hasDebugParam = useMemo(() => {
    return DEV || getQueryParam("debug") === "";
  }, []);
  const isLawyer = role === UserRole.Lawyer;
  const isSuperAdmin = role === UserRole.SuperAdmin;
  const isChecker = role === UserRole.Checker;
  if (role === UserRole.LogoDetection) return null;
  return (
    <NavMenu className={"column section is-narrow"}>
      {isChecker && (
        <Group title={"Checker"}>
          <Item
            href={AppRoute.Checker}
            title={"Check posts"}
            icon={faCheckDouble}
          />
        </Group>
      )}
      {!isChecker && (
        <>
          <Group title={"Rights"}>
            <Item
              href={`/app`}
              title={"Videodashboard"}
              icon={faVideo}
              comingSoon={isLawyer}
            />
            <Item href={`/app/videos`} title={"Videolist"} icon={faList} />
          </Group>
          <Group title={"Social"}>
            <Item href={AppRoute.Posts} title={"Posts"} icon={faPhotoVideo} />
            <Item
              href={AppRoute.SocialDashboard}
              title={"Webactivites"}
              icon={faHashtag}
            />
          </Group>
          <Group title={"Brand"}>
            {permissions.canAccessLivestreams && (
              <Item
                href={AppRoute.LivestreamOverview}
                title={"Live Analytics"}
                icon={faWifi}
              />
            )}
            <Item
              href={AppRoute.DetectionLivestreams}
              title={"Broadcast Analytics"}
              icon={faTv}
            />
            <Item
              href={AppRoute.StreamCharts}
              title={"Broadcast Charts"}
              icon={faChartLine}
            />
            <Item
              href={AppRoute.DetectionLivestream.path}
              title={"Section Analytics"}
              icon={faTv}
            />
            <Item
              href={AppRoute.DetectionProject}
              title={"Social/Webanalytics"}
              icon={faHashtag}
            />
            <Item
              href={AppRoute.ExcelReports}
              title={"Stream Reports"}
              icon={faTable}
            />
            {(hasDebugParam || isSuperAdmin) && (
              <Item
                href={AppRoute.BrandReports}
                title={"Brand Report"}
                icon={faChartLine}
                internal
              />
            )}
          </Group>
          <Group title={"Tools"}>
            <Item href={AppRoute.Reports} title={"Reports"} icon={faCopy} />
            <Item
              href={AppRoute.FootageUpload}
              title={"Footage"}
              icon={faFilm}
            />
          </Group>
          {(hasDebugParam || isSuperAdmin) && (
            <Group title={"Internal"}>
              <Item
                href={AppRoute.Checker}
                title={"Check posts"}
                icon={faCheckDouble}
              />
              {permissions.canDebugDetection && (
                <Item
                  href={AppRoute.DebugDetectionStatus}
                  title={"Detection"}
                  icon={faSearchDollar}
                  internal
                />
              )}
              {isSuperAdmin && (
                <Item href={`/app/admin`} title={"Admin"} icon={faTools} />
              )}
              {isSuperAdmin && permissions.canAccessLivestreams && (
                <Item
                  href={`/app/admin/streams`}
                  title={"Streamadmin"}
                  icon={faWifi}
                />
              )}
              {isSuperAdmin && (
                <Item
                  href={AppRoute.VideoImport}
                  title={"Video Import"}
                  icon={faPhotoVideo}
                />
              )}
              {isSuperAdmin && (
                <Item
                  href={AppRoute.AdminSignals}
                  title={"Signals"}
                  icon={faSatellite}
                />
              )}
              {process.env.NODE_ENV === "development" && (
                <Item href={`/app/icons`} title={"Icons"} icon={faIcons} />
              )}
              {hasDebugParam && <DebugToggle />}
            </Group>
          )}
        </>
      )}
      <Footer />
    </NavMenu>
  );
}

function DebugToggle() {
  const debugOptions = useDebugOptions();
  const updateOptions = useDebugDispatcher();
  return (
    <Debug>
      <Switch
        title={"Debug"}
        value={debugOptions.general}
        onChange={(checked) => {
          updateOptions({ general: checked });
        }}
      />
    </Debug>
  );
}

const Debug = styled.div`
  margin-top: 10px;
  margin-left: 12px;
  color: white;
  input {
    margin-right: 8px;
  }
`;

export default MainNav;
