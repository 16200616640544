import React from "react";
import { DebugDetectionPage } from "~/screens/app/detection/debug/DebugDetectionPage";
import { ListRecordings } from "~/screens/app/detection/debug/recording/ListRecordings";
import { CreateRecording } from "~/screens/app/detection/debug/recording/create/CreateRecording";
import { ActiveRecordings } from "~/screens/app/detection/debug/recording/list/ActiveRecordings";

interface DebugRecordingScreenProps {}

export function DebugRecordingScreen(props: DebugRecordingScreenProps) {
  return (
    <DebugDetectionPage>
      <ActiveRecordings />
      <CreateRecording />
      <ListRecordings />
    </DebugDetectionPage>
  );
}
