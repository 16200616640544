import { delay } from "@core/utils/fetchUtils";
import { getQueryParam } from "./jsUtils";

export function isDebug() {
  return getQueryParam("debug") === "";
}

export async function debugDelay<T>(obj: T) {
  if (
    process.env.NODE_ENV === "development" &&
    process.env.GATSBY_DEV_FETCH_DELAY
  ) {
    await delay(parseInt(process.env.GATSBY_DEV_FETCH_DELAY));
  }
  return obj;
}
