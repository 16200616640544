import { DemoLiveStream } from "@core/types/domain/Livestream";

export const demoStreams: DemoLiveStream[] = [
  {
    id: 1,
    label: "Testski",
    url: "https://s2.onlinecontrolserver.net/stream/testski3.mp4",
    dataSheet:
      "https://docs.google.com/spreadsheets/d/e/2PACX-1vSma6gGxrotXGCVaI7CEeVO4MsS7-SSSoBZONuXDGgsxUqf9SagjfHsULGHhKeDhVgAdYPP1imzlukC/pub?output=tsv",
  },
  {
    id: 2,
    label: "Alpin",
    url: "http://s2.onlinecontrolserver.net/stream/demo_alpin.mp4",
    dataSheet:
      "https://docs.google.com/spreadsheets/d/e/2PACX-1vSilOmMguN2XzEjSEy7i61CVg4z_P-rdwdNDm2VMTM-y-73Do-FqTRg8JKSsDmmJJzvBEBX7r8PaZCj/pub?output=tsv",
  },
  {
    id: 3,
    label: "Alpin (Ende)",
    url: "http://s2.onlinecontrolserver.net/stream/demo_alpin.mp4",
    dataSheet:
      "https://docs.google.com/spreadsheets/d/e/2PACX-1vSilOmMguN2XzEjSEy7i61CVg4z_P-rdwdNDm2VMTM-y-73Do-FqTRg8JKSsDmmJJzvBEBX7r8PaZCj/pub?output=tsv",
    hasSummary: true,
    length: 77,
  },
  {
    id: 4,
    label: "Athletics (Demo)",
    // url: "http://66.248.204.22/demo_athH264.mp4",
    url: "http://66.248.204.22/demo_athH264.mkv",
    jsonUrl: "http://66.248.204.22/demo_ath.json",
  },
];
