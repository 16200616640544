import { atom } from "recoil";

export interface NavState {
  toggled: boolean;
}

export const navState = atom<NavState>({
  key: "nav",
  default: {
    toggled: false,
  },
});
