import { useQuery } from "react-query";
import { RecordingFile } from "@core/types/recordings/RecordingFile";
import { fetchApi, requireOk, toJson } from "~/services/fetchApi";

export function fetchSavedRecordings(projectId: string) {
  return fetchApi(`/recordings/files/${projectId}`)
    .then(requireOk)
    .then<RecordingFile<string>[]>(toJson)
    .then<RecordingFile[]>((results) =>
      results.map((json) => ({
        ...json,
        created: new Date(json.created),
      }))
    );
}

export function useSavedRecordings(projectId: string) {
  return useQuery(
    ["useSavedRecordings", projectId],
    () => {
      return fetchSavedRecordings(projectId);
    },
    {
      refetchInterval: 1000 * 15,
      refetchOnWindowFocus: true,
      enabled: false,
    }
  );
}
