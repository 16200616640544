import React from "react";
import { Link } from "gatsby";
import styled from "@emotion/styled";
import { AppRoute } from "~/config/Routes";
import AppPageLayout from "~/layouts/app/AppPageLayout";
import Button from "~/components/button/Button";

interface DebugDetectionPageProps {
  children: any;
}

function DebugDetectionHeader() {
  return (
    <Nav>
      {/*<NavItem to={AppRoute.DebugDetection} name={"Detection Home"} />*/}
      {/*<NavItem to={AppRoute.DebugLiveDetections} name={"Live Detection"} />*/}
      <NavItem to={AppRoute.DebugDetectionStatus} name={"Server Status"} />
      <NavItem to={AppRoute.DebugDetectionModels} name={"Models"} />
      <NavItem
        to={AppRoute.DebugRecordingStatus.create()}
        name={"Recordings"}
      />
      <NavItem to={AppRoute.DebugVideoDetection} name={"Video Detection"} />
    </Nav>
  );
}

export function DebugDetectionPage(props: DebugDetectionPageProps) {
  return (
    <AppPageLayout>
      <DebugDetectionHeader />
      {props.children}
    </AppPageLayout>
  );
}

const Nav = styled.nav`
  margin-bottom: 24px;
  button {
    margin-right: 12px;
  }
`;

function NavItem(props: { to: string; name: string }) {
  return (
    <Link to={props.to}>
      <Button variant={"is-link"}>{props.name}</Button>
    </Link>
  );
}
