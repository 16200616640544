import React from "react";
import {
  LivestreamView,
  LivestreamViewProps,
} from "../../livestreams/livestream/LivestreamView";
import { useTaskInfo } from "~/services/detection/detectionService";
import AppPageSpinner from "~/layouts/app/AppPageSpinner";

interface DebugDetectionJobScreenProps
  extends Pick<LivestreamViewProps, "stream"> {
  jobId: string;
}

export function DebugDetectionJobScreen(props: DebugDetectionJobScreenProps) {
  const { data: task } = useTaskInfo(props.jobId);
  if (!task) return <AppPageSpinner />;
  return (
    <LivestreamView task={task} server={task.server} stream={props.stream} />
  );
}
