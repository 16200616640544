import React, { memo } from "react";
import PieChart, {
  PieChartOptions,
} from "../../../../components/charts/PieChart";
import { DetailedProjectStats } from "../../../../@core/types/domain/ProjectType";
import { videoCategoryById } from "../../../../@core/types/domain/video/VideoCategory";

export interface CategoryChartProps
  extends Pick<DetailedProjectStats, "byCategory"> {
  options?: PieChartOptions;
}

function CategoryChart({ byCategory, options }: CategoryChartProps) {
  if (!byCategory) return null;
  const data = Object.values(byCategory).map((value) => value.count);
  const compact = options?.compact || false;
  const labels = Object.entries(byCategory).map(([id, { count }]) => {
    const status = videoCategoryById[id];
    if (compact) {
      return status && `${status.name}`;
    } else {
      return status && `${status.name} (${count || 0})`;
    }
  });
  return (
    <PieChart
      data={data}
      labels={labels}
      // colors={statusColors}
      options={options}
    />
  );
}

export default memo(CategoryChart);
