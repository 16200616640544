import React, { useEffect, useMemo } from "react";
import { getBaseUrl, queryParams } from "../../utils/jsUtils";
import PdfButton, { PdfButtonProps } from "./PdfButton";

interface PdfDownloadButtonProps
  extends Pick<PdfButtonProps, "title" | "disabled" | "variant"> {
  route: string;
  params?: Record<string, any>;
}

function PdfDownloadButton({
  route,
  params,
  ...props
}: PdfDownloadButtonProps) {
  const url = useMemo(() => {
    const query = params ? queryParams(params) : "";
    return getBaseUrl(`${route}?${query}`);
  }, [route, params]);
  return <PdfButton url={url} {...props} />;
}

export default PdfDownloadButton;
