import React from "react";
import { GetDetectionJobStatus } from "@core/types/status/statusRouteTypes";
import { VideoDetectionStatus } from "@core/types/domain/video/VideoType";
import { SimpleTable } from "~/components/table/SimpleTable";
import Notification from "~/components/notification/Notification";

interface JobsInfoProps {
  jobs: GetDetectionJobStatus["jobs"];
}

export function JobsInfo({ jobs }: JobsInfoProps) {
  return (
    <div>
      <h2>Active jobs</h2>
      <Notification type={"info"}>
        Die folgenden Zahlen sind derzeit nicht zuverlässig
      </Notification>
      <SimpleTable>
        <thead>
          <tr>
            <th>#</th>
            <th>Project</th>
            <th>Total videos</th>
            <th>Resolved videos</th>
            <th>Errors</th>
          </tr>
        </thead>
        <tbody>
          {jobs.map(
            ({
              job,
              resolvedVideos,
              resolvableVideos,
              totalVideos,
              byStatus,
            }) => {
              const errors = Object.entries(byStatus).reduce(
                (result, [status, count]) => {
                  if (parseInt(status) < 0) result += count;
                  return result;
                },
                0
              );
              const errorTitle = Object.entries(errorTypes)
                .filter(([_, value]) => {
                  return !!value;
                })
                .map(([status, title]) => {
                  return `${title}: ${byStatus[status]}`;
                })
                .join("\n");
              return (
                <tr key={job.id}>
                  <td>{job.id}</td>
                  <td>{job.projectId}</td>
                  <td>{totalVideos}</td>
                  <td>
                    {resolvedVideos}/{resolvableVideos}
                  </td>
                  <td title={errorTitle}>{errors}</td>
                </tr>
              );
            }
          )}
        </tbody>
      </SimpleTable>
    </div>
  );
}

const errorTypes: Record<VideoDetectionStatus, string | false> = {
  [VideoDetectionStatus.COMPLETE]: false,
  [VideoDetectionStatus.QUEUED]: false,
  [VideoDetectionStatus.ERROR]: "Detection error",
  [VideoDetectionStatus.MISSING_URL]: "Missing url",
};
