import React, { useState } from "react";
import styled from "@emotion/styled";
import { VideoType } from "../../../../@core/types/domain/video/VideoType";
import {
  allVideoCustomStatus,
  VideoCustomStatus,
  videoCustomStatusById,
} from "../../../../@core/types/domain/video/VideoCustomStatus";
import DropdownMenu, {
  DropdownMenuOption,
} from "../../../../components/inputs/dropdown/DropdownMenu";
import { updateVideo } from "../../../../services/video/videoService";
import { alertError } from "../../../../utils/alert/alertUtils";
import { globalUpdateVideo } from "../../context/VideoProviderContext";
import { useHasPermission } from "../../../../store/login/permissionSelectors";
import { UserPermission } from "../../../../@core/types/domain/user/UserPermission";
import { CellProps, StatusTag } from "./videoTableCells";

type CustomStatusCellProps = CellProps<VideoType>;

function CustomStatusCell(props: CustomStatusCellProps) {
  const [opened, setOpen] = useState(false);
  const canEdit = useHasPermission(UserPermission.canUpdateUserStatus);
  const status = videoCustomStatusById[props.cell.value || 0];
  const isDecent = !props.cell.value;
  if (opened) {
    const close = () => setOpen(false);
    return (
      <DropdownMenu
        options={statusOptions}
        isOpened={opened}
        onChange={(option: DropdownMenuOption<number>) => {
          const video = props.cell.row.original;
          fetchCustomStatusUpdate(video.id, option.value)
            .then((video) => {
              globalUpdateVideo(video);
            })
            .catch(alertError);
          close();
        }}
      >
        <CustomStatusTag onClick={close} decent={isDecent}>
          {status.name}
        </CustomStatusTag>
      </DropdownMenu>
    );
  } else {
    return (
      // @ts-ignore
      <CustomStatusTag onClick={canEdit ? setOpen : null} decent={isDecent}>
        {status.name}
      </CustomStatusTag>
    );
  }
}

function fetchCustomStatusUpdate(
  videoId: number,
  customStatus: VideoCustomStatus
) {
  if (customStatus === 0) customStatus = null;
  return updateVideo(videoId, { customStatus });
}

const statusOptions = allVideoCustomStatus.map((value) => ({
  label: value.name,
  value: value.id,
}));

const CustomStatusTag = styled(StatusTag)`
  cursor: ${(props) => props.onClick && "pointer"};
`;

export default CustomStatusCell;
