import { Link, navigate } from "gatsby";
import React, { createElement, ComponentClass, FunctionComponent } from "react";
import Button from "~/components/button/Button";

interface PrivateRouteProps {
  component: ComponentClass<any> | FunctionComponent<any>;
  path: string;
  canAccess: boolean;
}

function PrivateRoute(props: PrivateRouteProps) {
  if (!isLoggedIn() && location.pathname !== `/app/login`) {
    // If we’re not logged in, redirect to the home page.
    navigate(`/app/login`);
    return null;
  }
  if (!props.canAccess) {
    return <MissingAccess />;
  }
  return createElement(props.component, props as any);
}

function MissingAccess() {
  return (
    <div>
      <div style={{ marginBottom: 12 }}>
        You don&apos;t have access to this route
      </div>
      <Link to={"/"}>
        <Button variant={"is-primary"}>Return to home</Button>
      </Link>
    </div>
  );
}

function isLoggedIn() {
  // TODO wtf
  return true;
}

export default PrivateRoute;
