import React from "react";
import styled from "@emotion/styled";
import { applyClassName } from "../../../utils/reactUtils";

const TextareaInput = React.forwardRef((props: any, ref) => {
  return (
    <Textarea
      ref={ref}
      {...props}
      className={applyClassName("textarea", props)}
    />
  );
});

const Textarea = styled.textarea`
  width: 100%;
  padding: 10px;
  border: 1px solid #a9a9a9;
`;

export default TextareaInput;
