import React, { useMemo } from "react";
import styled from "@emotion/styled";
import { faClock, faEye, faVideo } from "@fortawesome/free-solid-svg-icons";
import { CountUpProps } from "react-countup";
import { useSelectedProjects } from "../../../../store/project/projectHooks";
import NumberWidget from "../widgets/NumberWidget";
import { formatDuration } from "../../../../utils/formatUtils";
import {
  LiveStreamStatsType,
  ProjectType,
} from "../../../../@core/types/domain/ProjectType";
import DashboardSection from "./DashboardSection";

interface ProjectLiveStreamStatsProps {}

function ProjectLiveStreamStats(props: ProjectLiveStreamStatsProps) {
  const projects = useSelectedProjects();
  const stats = useMemo(() => {
    return sumLiveStreamStats(projects);
  }, [projects]);

  return (
    <Container>
      <NumberWidget
        color={color}
        title={"total livestreams"}
        value={stats.liveStreamCount}
        icon={faVideo}
      />
      <NumberWidget
        color={"color"}
        title={"total duration of livestreams"}
        value={stats.liveStreamDuration}
        icon={faClock}
        countUpProps={numberCountUpProps}
      />
      <NumberWidget
        color={color}
        title={"total views of livestreams"}
        value={stats.liveStreamViews}
        icon={faEye}
      />
      <NumberWidget
        color={color}
        title={"total views VoD"}
        value={stats.liveStreamVodViews}
        icon={faEye}
      />
    </Container>
  );
}

function sumLiveStreamStats(projects: ProjectType[]) {
  return projects.reduce(
    (result, project) => {
      result.liveStreamCount += project.liveStreamCount;
      result.liveStreamViews += project.liveStreamViews;
      result.liveStreamDuration += project.liveStreamDuration;
      result.liveStreamVodViews += project.liveStreamVodViews;
      return result;
    },
    {
      liveStreamCount: 0,
      liveStreamDuration: 0,
      liveStreamViews: 0,
      liveStreamVodViews: 0,
    } as LiveStreamStatsType
  );
}

const numberCountUpProps: Partial<CountUpProps> = {
  formattingFn: formatDuration,
  separator: undefined,
};

const Container = styled(DashboardSection)`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 24px;
`;

const color = "#4a4a4a";

export default ProjectLiveStreamStats;
