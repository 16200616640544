import React from "react";
import { Link } from "gatsby";
import AppPageLayout from "~/layouts/app/AppPageLayout";
import AppPageSpinner from "~/layouts/app/AppPageSpinner";
import { useSignals } from "~/screens/app/admin/signals/data/useSignals";
import { SignalsMonitor } from "~/screens/app/admin/signals/SignalsMonitor";
import { AppRoute } from "~/config/Routes";

interface SignalsOverviewScreenProps {}

export function SignalsOverviewScreen(props: SignalsOverviewScreenProps) {
  const { data: signals, isLoading } = useSignals();
  return (
    <AppPageLayout>
      <h1>Broadcast Signals</h1>
      {isLoading && <AppPageSpinner />}
      <ul>
        {signals?.map((s) => (
          <li key={s.id}>
            <Link to={AppRoute.AdminSignal.create(s.id)}>{s.name}</Link>
          </li>
        ))}
      </ul>
      {/*{signals && <SignalsMonitor signals={signals} />}*/}
    </AppPageLayout>
  );
}
