import { useMemo } from "react";
import { LivestreamType } from "@core/types/domain/Livestream";
import { parseDetectionResult } from "@core/types/utils/detectionUtils";
import {
  DetectionResult,
  VideoBrandStats,
} from "@core/types/detection/DetectionTypes";
import { useRangeStreamStats } from "~/screens/app/detection/stream/data/useRangeStreamStats";
import { TimeRangeInfo } from "~/screens/app/detection/stream/videorange/SectionSelectorView";

export function useRangeStreamResults(
  stream: LivestreamType,
  ranges: TimeRangeInfo[]
) {
  const { data, isFetching } = useRangeStreamStats(stream, ranges);
  return useMemo(() => {
    const parsed = parseDetectionResult(data?.all);
    if (!parsed) return { results: undefined, isFetching };
    return {
      results: [createDummyDetectionResult(parsed)],
      isFetching,
    };
  }, [data, isFetching]);
}

export function createDummyDetectionResult(
  stats: VideoBrandStats
): DetectionResult {
  return {
    id: -1,
    length: 0,
    logos: stats,
    status: "",
    createdAt: undefined,
  };
}
