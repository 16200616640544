import React, {
  FormEvent,
  useCallback,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import styled from "@emotion/styled";
import matchSorter from "match-sorter";
import TextLinkButton from "../../components/button/TextLinkButton";
import { SelectDropdownProps } from "./SelectDropdown";
import SearchInput from "./SearchInput";
import { DropdownItem } from "~/components/inputs/dropdown/Dropdown";

interface OptionsListProps<T> extends SelectDropdownProps<T> {
  keys: string[];
}

function useFilteredOptions<T>(options: T[], search: string, keys: string[]) {
  return useMemo(() => {
    if (!options) return undefined;
    return matchSorter(options, search, { keys });
  }, [options, search, keys]);
}

function OptionsList<T>({
  options,
  values,
  getOptionLabel,
  getOptionValue,
  onChange,
  keys,
  singleValue,
  hideClear,
}: OptionsListProps<T>) {
  const [search, setSearch] = useState("");

  const filteredOptions = useFilteredOptions(options, search, keys);
  const filteredValues = useFilteredOptions(values, search, keys);

  const selectedValues = useMemo(() => {
    if (singleValue) {
      if (!filteredValues?.length) return "";
      else return getOptionValue(filteredValues[0]);
    } else return filteredValues?.map(getOptionValue);
  }, [singleValue, filteredValues]);

  const optionsByValue = useMemo(() => {
    return options.reduce((result, option) => {
      const value = getOptionValue(option);
      result[value] = option;
      return result;
    }, {} as { [key: string]: T });
  }, [options]);

  const onSelectChange = useCallback(
    (event: FormEvent<HTMLSelectElement>) => {
      const options = Array.from(event.currentTarget.options);
      const selectedValues = options
        .filter((o) => o.selected)
        .map((o) => optionsByValue[o.value]);
      onChange(selectedValues);
    },
    [singleValue]
  );

  useLayoutEffect(() => {
    document.getElementById(inputId)?.focus();
  }, []);

  return (
    <Container>
      <ListContainer>
        <DropdownItem>
          <SearchInput
            id={inputId}
            value={search}
            onValueChange={setSearch}
            placeholder={"Filter entries..."}
          />
        </DropdownItem>
        <DropdownItem>
          <SelectContainer className={"select is-multiple"}>
            <Select
              multiple={!singleValue}
              size={10}
              value={selectedValues as string[]}
              onChange={onSelectChange}
            >
              {singleValue && (
                <option value="" disabled>
                  Please select
                </option>
              )}
              {options?.map((option) => {
                const value = getOptionValue(option);
                const filtered = filteredOptions.includes(option);
                return (
                  <Option key={value} value={value} hidden={!filtered}>
                    {getOptionLabel(option)}
                  </Option>
                );
              })}
            </Select>
          </SelectContainer>
        </DropdownItem>
      </ListContainer>
      <DropdownItem>
        <Note>
          {!singleValue && (
            <span>
              Select multiple elements by holding <i>Ctrl</i> when clicking.
            </span>
          )}
          {hideClear ? null : (
            <TextLinkButton
              onClick={() => {
                onChange([]);
              }}
            >
              Clear selection
            </TextLinkButton>
          )}
        </Note>
      </DropdownItem>
    </Container>
  );
}

const Note = styled.small`
  display: flex;
  justify-content: space-between;
`;

const Option = styled.option<{ hidden: boolean }>`
  // opacity: ${(props) => props.hidden && 0.4};
`;

const SelectContainer = styled.div`
  width: 100%;
`;

const Select = styled.select`
  width: 100%;
  border: none;
  &:not([multiple]) {
    // fix issues
    height: auto !important;
    padding: 0 !important;
    & > option {
      padding: 0.5em 1em;
    }
  }
`;

const inputId = "dropdown-projectname";

const ListContainer = styled.div`
  //max-height: 420px;
  //overflow-x: hidden;
  //overflow-y: scroll;
`;

const Container = styled.div`
  text-align: left;
`;

export default OptionsList;
