import React from "react";
import styled from "@emotion/styled";
import { faBullhorn } from "@fortawesome/free-solid-svg-icons";
import { useProjectTopPosts } from "../../../../store/project/projectHooks";
import { ProjectStatsFilter } from "../../../../@core/types/api/ProjectFilterType";
import {
  MainGrid,
  MainGridRow,
} from "../../../../components/widget/WidgetGrid";
import Widget from "../../../../components/widget/Widget";
import TopPost from "./TopPost";

interface TopPostsProps {
  filter: ProjectStatsFilter;
}

function TopPosts(props: TopPostsProps) {
  const { isLoading, data } = useProjectTopPosts(props.filter);

  if (!data?.length) return null;

  return (
    <MainGridRow>
      <Widget
        title={"Most successful posts"}
        icon={faBullhorn}
        loading={isLoading}
      >
        <Grid>
          {data?.map((post) => (
            <TopPost key={post.id} post={post} />
          ))}
        </Grid>
      </Widget>
    </MainGridRow>
  );
}

export const Grid = styled(MainGrid)`
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
`;

export default TopPosts;
