import React from "react";
import { faCommentDots, faUsers } from "@fortawesome/free-solid-svg-icons";
import Widget from "../../../../components/widget/Widget";
import { ProjectStatsFilter } from "../../dashboard/sections/filter/ProjectFilter";
import DailyBarChart from "../../../../components/charts/DailyBarChart";
import { useProjectPostHistory } from "../../../../store/project/projectHooks";
import PostHistory from "./PostHistory";

interface PostHistoryWidgetsProps {
  filter: ProjectStatsFilter;
}

function PostHistoryWidgets(props: PostHistoryWidgetsProps) {
  const { data } = useProjectPostHistory(props.filter);
  if (!data?.length) return null;
  return (
    <>
      <Widget icon={faCommentDots} title={"Post count"}>
        <PostHistory data={data} field={"count"} label={"Posts"} />
      </Widget>
      <Widget icon={faUsers} title={"Post reach"}>
        <PostHistory data={data} field={"reach"} label={"Reach"} />
      </Widget>
    </>
  );
}

export default PostHistoryWidgets;
