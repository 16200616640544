import React, { useCallback } from "react";
import { LivestreamTypeExternal } from "@core/types/domain/Livestream";
import { StoredTaskInfo } from "@core/service/detection/fetchStoredTaskInfo";
import { toast } from "react-toastify";
import Button from "~/components/button/Button";
import Explain from "~/components/bulma/Explain";
import { useOptimisticConfirm } from "~/utils/alert/alertOptimisticConfirm";
import { fetchApi, requireOk, toJson } from "~/services/fetchApi";
import { alertError } from "~/utils/alert/alertUtils";
import { roundN } from "~/utils/js/mathUtils";
import { VideoRange } from "~/screens/app/detection/stream/videorange/range/useVideoRanges";

interface CutRecordingButtonProps {
  stream: LivestreamTypeExternal;
  task: StoredTaskInfo;
  ranges: VideoRange[];
  disabled?: boolean;
}

export function CutRecordingButton({
  stream,
  ranges,
}: CutRecordingButtonProps) {
  const { onClick: onSubmit, isPending } = useOptimisticConfirm({
    onConfirm: () => {
      return fetchCutRecording(stream, ranges[0])
        .then(() => {
          // alertInfo("Task has been started.")
        })
        .catch((err) => {
          alertError(err);
          throw err;
        });
    },
    text: "Starting a static analysis with cropping",
    options: {
      time: process.env.NODE_ENV === "development" ? 1500 : undefined,
    },
  });
  const onClick = useCallback(() => {
    if (ranges.length !== 1) {
      toast.error(
        "Derzeit kann nur ein einzelner Zeitbereich geschnitten werden."
      );
      return;
    }
    onSubmit();
  }, []);
  return (
    <Button variant={"is-danger"} disabled={isPending} onClick={onClick}>
      Cut recording{" "}
      <Explain color={"white"} multiline>
        This command starts a static analysis where the video as well as the
        data are cut to the selected time period.
      </Explain>
    </Button>
  );
}

export function fetchCutRecording(
  stream: LivestreamTypeExternal,
  range: VideoRange
) {
  return fetchApi(`/livestream/${stream.id}/cut-recording`, {
    method: "POST",
    body: JSON.stringify({
      start: roundN(range.from, 2),
      end: roundN(range.to, 2),
    }),
  })
    .then(requireOk)
    .then(toJson);
}
