import { useMemo } from "react";
import { useDebugOptions } from "../../../../store/debug/debugHooks";
import {
  ChannelCell,
  DateCell,
  LengthCell,
  StatusCell,
  ThumbnailCell,
  TitleCell,
} from "../cells/videoTableCells";
import ActionsCell from "../../cells/ActionsCell";
import CustomStatusCell from "../cells/CustomStatusCell";
import CategoryCell from "../cells/CategoryCell";

export function useVideoColumns() {
  const debug = useDebugOptions();
  return useMemo(() => {
    return [
      { accessor: "id", Header: "ID", show: debug.general },
      { accessor: "thumbnail", Header: "", Cell: ThumbnailCell },
      { accessor: "title", Header: "Title", Cell: TitleCell },
      { accessor: "channelName", Header: "Uploader", Cell: ChannelCell },
      { accessor: "status", Header: "Status", Cell: StatusCell },
      {
        accessor: "customStatus",
        Header: "User Status",
        Cell: CustomStatusCell,
      },
      {
        accessor: "category",
        Header: "Category",
        Cell: CategoryCell,
      },
      { accessor: "length", Header: "Length", Cell: LengthCell },
      {
        accessor: "publishAt",
        Header: "Date",
        Cell: DateCell,
      },
      {
        id: "actions",
        Cell: ActionsCell,
      },
    ];
  }, [debug]);
}
