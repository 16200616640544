import React from "react";
import styled from "@emotion/styled";
import Card from "../../../components/card/Card";
import ActiveFilters from "./ActiveFilters";
import VideoFilterDebug from "./VideoFilterDebug";
import { useFilterContext } from "./context/VideoFilterContext";

interface VideoFilterProps {
  submitButton: React.ReactElement;
}

function VideoFilter(props: VideoFilterProps) {
  const { filter } = useFilterContext();
  return (
    <Container>
      <FilterCard>
        <ActiveFilters filter={filter} submitButton={props.submitButton} />
      </FilterCard>
      <VideoFilterDebug filter={filter} />
    </Container>
  );
}

const FilterCard = styled(Card)``;

const Container = styled.div`
  margin-bottom: 14px;
`;

export default VideoFilter;
