import React, { useMemo } from "react";
import SimpleSelect, {
  SimpleSelectProps,
} from "~/screens/app/checker/SimpleSelect";

interface OptionSelectorProps<K extends string>
  extends Pick<SimpleSelectProps, "value"> {
  options: Record<K, string>;
  onValueChange: (val: K) => void;
}

export function OptionSelector<K extends string>(
  props: OptionSelectorProps<K>
) {
  const arrayOptions = useMemo(() => {
    return Object.entries(props.options) as [K, string][];
  }, [props.options]);
  return (
    <SimpleSelect
      options={arrayOptions}
      value={props.value}
      getOptionLabel={(option) => option[1]}
      getOptionValue={(option) => option[0]}
      onValueChange={props.onValueChange}
    />
  );
}
