import React from "react";
import styled from "@emotion/styled";
import AppPageLayout from "../../../layouts/app/AppPageLayout";
import { ProjectSelectorWithLabel } from "../../../elements/context/project/FloatingProjectSelector";
import { ProjectSelectionContext } from "../../../elements/context/project/ProjectSelectionContext";
import SocialDashboard from "./SocialDashboard";

interface SocialDashboardScreenProps {}

function SocialDashboardScreen(props: SocialDashboardScreenProps) {
  return (
    <AppPageLayout>
      <ProjectSelectionContext resolveFromUrl>
        {/*<SelectionRow>*/}
        {/*  <ProjectSelectorWithLabel autoSelect />*/}
        {/*</SelectionRow>*/}
        <SocialDashboard />
      </ProjectSelectionContext>
    </AppPageLayout>
  );
}

const SelectionRow = styled.div`
  margin-bottom: 24px;
  //text-align: right;
`;

export default SocialDashboardScreen;
