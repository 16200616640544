import React, { Fragment, memo, useCallback, useMemo, useState } from "react";
import classNames from "classnames";
import { css } from "@emotion/react";
import { AddFilterFn, useAddFilter } from "../context/VideoFilterContext";
import { FilterType } from "../types/VideoFilterType";
import { optionalInvoke } from "../../../../utils/jsUtils";
import { DropdownDivider } from "../../../../components/inputs/dropdown/Dropdown";
import { getSearchResults } from "./getSearchResults";

interface AddFilterProps {}

const AddMenu = memo(function AddMenuInner(props: {
  search: string;
  addFilter: AddFilterFn;
}) {
  const { search, addFilter } = props;
  const resultGroups = useMemo(() => getSearchResults(search), [search]);
  return (
    <div className="dropdown-content">
      {resultGroups.map((results, i) => {
        return (
          <Fragment key={i}>
            {results.length > 0 && i !== 0 && <DropdownDivider />}
            {results.map((value) => {
              return (
                <a
                  key={value.key}
                  className="dropdown-item"
                  title={value.key}
                  onMouseDown={(event) => {
                    addFilter(value.field.name, optionalInvoke(value.filter));
                  }}
                >
                  {value.title || value.field.label}
                </a>
              );
            })}
          </Fragment>
        );
      })}
    </div>
  );
});

function AddFilter(props: AddFilterProps) {
  const [search, setSearch] = useState("");
  const [isFocused, setFocused] = useState(false);
  const addFilter = useAddFilter();
  const addFilterMiddleware = useCallback(
    (fieldName: string, filter: FilterType) => {
      setSearch("");
      addFilter(fieldName, filter);
    },
    [addFilter]
  );
  return (
    <div
      className={classNames("dropdown", {
        "is-active": isFocused,
      })}
    >
      <div className="dropdown-trigger">
        <input
          css={css`
            border-radius: 13px;
          `}
          type="text"
          placeholder={"Add filter"}
          className={"input"}
          value={search}
          onChange={(event) => setSearch(event.target.value)}
          onFocus={() => {
            setFocused(true);
          }}
          onBlur={() => {
            setFocused(false);
          }}
        />
      </div>
      <div className="dropdown-menu" id="dropdown-menu" role="menu">
        <AddMenu search={search} addFilter={addFilterMiddleware} />
      </div>
    </div>
  );
}

export default AddFilter;
