import React, { useMemo } from "react";
import { DetectionResult } from "@core/types/detection/DetectionTypes";
import { StateUpdater } from "@core/types/utils/utilTypes";
import { DetectionFilter } from "~/screens/app/detection/stats/components/filter/DetectionFilter";
import TextFilter, { Option } from "~/components/filter/TextFilter";
import {
  FilterContainer,
  FilterLabel,
} from "~/screens/app/detection/stats/components/filter/DetectionFilterView";

interface LocationFilterProps {
  filter: DetectionFilter;
  updateFilter: StateUpdater<DetectionFilter>;
  rawStats: DetectionResult[];
}

export function LocationFilter({
  filter,
  rawStats,
  updateFilter,
}: LocationFilterProps) {
  const locations = useLocationsInStats(rawStats);
  const options: Option[] = useMemo(() => {
    return locations?.map((location) => ({
      value: location,
      label: location,
    }));
  }, [locations]);
  if (locations.length < 2) return null;
  return (
    <FilterContainer>
      <FilterLabel>Filter by location</FilterLabel>
      <TextFilter
        options={options}
        value={filter.location}
        onChange={(value) => {
          updateFilter((f) => ({
            ...f,
            location: value,
          }));
        }}
      />
    </FilterContainer>
  );
}

const locationsWhitelist = ["all", "start", "track", "finish", "board"];

function useLocationsInStats(stats: DetectionResult[]): string[] {
  return useMemo(() => {
    const locations = locationsWhitelist.reduce((result, name) => {
      result[name] = false;
      return result;
    }, {} as Record<string, boolean>);

    Object.values(stats).forEach((value) => {
      Object.values(value.logos).forEach((byBrand) => {
        Object.keys(byBrand).forEach((key) => {
          const s = key.split("_");
          if (s.length > 0) {
            const [location] = s;
            if (locations[location] === false) {
              locations[location] = true;
            }
          }
        });
      });
    });

    return Object.entries(locations)
      .filter(([, isFound]) => isFound)
      .map(([name]) => name);
  }, [stats]);
}
