import React from "react";
import {
  faCommentDots,
  faThumbsUp,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import StatsByWidget, { StatsByWidgetProps } from "./StatsByWidget";

interface StatsByWidgetsProps
  extends Pick<StatsByWidgetProps, "getLabel" | "stats"> {
  suffix?: string;
}

function StatsByWidgets({ suffix, ...props }: StatsByWidgetsProps) {
  const fullSuffix = suffix ? ` ${suffix}` : "";
  return (
    <>
      <StatsByWidget
        {...props}
        field={"count"}
        title={"Total Posts" + fullSuffix}
        subTitle={"total count of posts"}
        icon={faCommentDots}
        explain={
          "The count may be inaccurate, because not all platforms provide a view count"
        }
      />
      <StatsByWidget
        {...props}
        field={"reach"}
        title={"Reach" + fullSuffix}
        subTitle={"total persons reached"}
        icon={faUsers}
        explain={
          "The count may be inaccurate, because not all platforms provide a view count"
        }
      />
      <StatsByWidget
        {...props}
        field={"reactions"}
        title={"Engagement" + fullSuffix}
        subTitle={"total reactions"}
        icon={faThumbsUp}
        explain={
          "The count may be inaccurate, because not all platforms provide a view count"
        }
      />
    </>
  );
}

export default StatsByWidgets;
