import React from "react";
import { Link } from "gatsby";
import AppPageLayout from "../../layouts/app/AppPageLayout";
import { useProjects } from "../../store/project/projectHooks";

interface ProjectsProps {}

function ProjectsScreen(props: ProjectsProps) {
  const projects = useProjects();
  return (
    <AppPageLayout>
      <ul>
        {projects &&
          projects.map((value) => (
            <Link key={value.id} to={"/app/videos?project=" + value.id}>
              <li>{value.name}</li>
            </Link>
          ))}
      </ul>
    </AppPageLayout>
  );
}

export default ProjectsScreen;
