import React, { useEffect } from "react";
import classNames from "classnames";
import { useProjects } from "../../../store/project/projectHooks";
import { EMPTY_STRING } from "../../../utils/jsUtils";
import { useProjectSelection } from "./ProjectSelectionContext";
import { ProjectSelectionProps } from "./ProjectSelector";

function SingleProjectSelector({
  autoSelect,
  projects: forcedProjects,
}: ProjectSelectionProps) {
  const { selection, setSelection } = useProjectSelection();
  const projects = forcedProjects || useProjects();
  useEffect(() => {
    /* select the first project if no project was selected */
    if (autoSelect && selection.projectIds.length === 0 && projects?.[0]) {
      setSelection({
        projectIds: [projects[0].id],
      });
    }
  }, [projects, selection]);
  return (
    <div
      className={classNames("select", {
        "is-loading": !projects,
      })}
    >
      <select
        disabled={!projects}
        value={selection.projectIds?.[0] || EMPTY_STRING}
        onChange={(event) => {
          const { value } = event.target;
          const id = parseInt(value);
          setSelection(() => ({
            projectIds: id ? [id] : [],
          }));
        }}
      >
        {projects ? (
          projects.map((project) => (
            <option key={project.id} value={project.id}>
              {project.name}
            </option>
          ))
        ) : (
          <option value="">Loading projects</option>
        )}
      </select>
    </div>
  );
}

export default SingleProjectSelector;
