import React, { useEffect, useMemo } from "react";
import styled from "@emotion/styled";
import {
  LivestreamDetectionType,
  LivestreamType,
} from "@core/types/domain/Livestream";
import { ProjectType } from "@core/types/domain/ProjectType";
import { maxBy } from "lodash";
import { sortStreamResults } from "~/screens/app/detection/stats/stream/sortStreamResults";
import {
  StreamFilterSelector,
  StreamSelectOption,
} from "~/screens/app/detection/stats/stream/StreamFilterSelector";
import { VideoDetectionView } from "~/screens/app/detection/stats/components/VideoDetectionView";
import { useSelectDropdownState } from "~/elements/selectdropdown/useSelectDropdownState";
import LinkButton from "~/components/button/LinkButton";
import { AppRoute } from "~/config/Routes";
import { useStreamStatsWithEuroValue } from "~/screens/app/detection/stats/stream/useStatsWithEuroValue";

interface StreamStatsComponentProps {
  streams: LivestreamType[];
  results: LivestreamDetectionType[];
  projectId: number;
  project: Pick<ProjectType, "costsPerSecond">;
}

export function StreamStatsComponent({
  streams,
  results,
  projectId,
  project,
}: StreamStatsComponentProps) {
  const streamSelection = useSelectDropdownState<StreamSelectOption>();

  const streamsWithResults = useMemo(() => {
    return streams.filter((s) => results.find((r) => r.streamId === s.id));
  }, [streams, results]);

  const resultByStream = useMemo(() => {
    return streamsWithResults.reduce((byStream, stream) => {
      const streamResults = results
        .filter((r) => r.streamId === stream.id)
        .sort();
      if (streamResults.length) {
        byStream[stream.id] = sortStreamResults(streamResults)?.[0];
      }
      return byStream;
    }, {} as Record<string, LivestreamDetectionType>);
  }, [streamsWithResults, results]);

  const selectableOptions = streamsWithResults;

  useEffect(() => {
    if (!streamSelection.values.length && selectableOptions?.length) {
      const latest = maxBy(selectableOptions, (o) => o.end?.getTime());
      streamSelection.setValues([latest]);
    }
  }, [selectableOptions]);

  const filteredResults = useMemo(() => {
    if (!resultByStream) return undefined;
    const unfiltered = Object.values(resultByStream);
    if (streamSelection.isEmpty) return unfiltered;
    return streamSelection.values.map((stream) => {
      return resultByStream[stream.id];
    });
  }, [resultByStream, streamSelection]);

  const parsedResults = useStreamStatsWithEuroValue(
    streamsWithResults,
    filteredResults,
    project.costsPerSecond
  );

  return (
    <>
      <SubtitleRow>
        <div>
          <h2>TV logo statistics</h2>
          {process.env.NODE_ENV === "development" && parsedResults && (
            <LinkButton
              url={AppRoute.DetectionLivestream.create(
                parsedResults[0].streamId
              )}
              variant={"is-primary"}
            >
              Single stream ({parsedResults[0].id})
            </LinkButton>
          )}
        </div>
        <SelectorRow>
          <label>Select streams</label>
          {streams && (
            <StreamFilterSelector
              streams={selectableOptions}
              {...streamSelection.bindings}
            />
          )}
        </SelectorRow>
      </SubtitleRow>
      {parsedResults && (
        <VideoDetectionView
          results={parsedResults}
          projectId={projectId}
          type={"stream"}
        />
      )}
    </>
  );
}

const SubtitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const SelectorRow = styled.div`
  margin-bottom: 14px;
  display: flex;
  align-items: center;
  label {
    font-weight: bold;
    margin-right: 12px;
  }
`;
