import React from "react";
import styled from "@emotion/styled";
import AppPageLayout from "../../../../../layouts/app/AppPageLayout";
import { useLiveStream } from "../../useProjectLiveStreams";
import LogoStreamView from "./LogoStreamView";

interface LogoDetectionScreenProps {
  streamId: string;
}

function LogoDetectionScreen(props: LogoDetectionScreenProps) {
  const { data } = useLiveStream(parseInt(props.streamId));
  return (
    <AppPageLayout>
      {/*<SummaryRow>*/}
      {/*  <BrandSummary />*/}
      {/*  <SingleBrandChart />*/}
      {/*</SummaryRow>*/}
      <LogoStreamView stream={data} />
    </AppPageLayout>
  );
}

const SummaryRow = styled.div`
  display: flex;
  margin-bottom: 24px;
  > div {
    flex: 1;
  }
`;

export default LogoDetectionScreen;
