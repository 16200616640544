import React, { Dispatch, SetStateAction, useCallback, useMemo } from "react";
import { StoredTaskInfo } from "@core/service/detection/fetchStoredTaskInfo";
import { OptionSelector } from "~/screens/app/detection/charts/filter/OptionSelector";
import {
  pieChartCategoryOptions,
  PieChartFilterState,
} from "~/screens/app/detection/charts/filter/PieChartFilterState";
import { SelectorLabelWrapper } from "~/elements/misc/SelectorLabelWrapper";
import SimpleSelect from "~/screens/app/checker/SimpleSelect";
import {
  allCriteriaOptions,
  CriteriaOption,
  CriteriaOptionType,
} from "~/screens/app/detection/charts/filter/CriteriaOption";

interface PieChartFiltersProps {
  filter: PieChartFilterState;
  setFilter: Dispatch<SetStateAction<PieChartFilterState>>;
  info: StoredTaskInfo;
}

export function PieChartFilters({
  filter,
  setFilter,
  info,
}: PieChartFiltersProps) {
  const updateFilter = useCallback(
    (update: Partial<PieChartFilterState>) => {
      setFilter((prev) => ({ ...prev, ...update }));
    },
    [setFilter]
  );
  const groupOptions = useMemo(() => {
    return Object.entries(pieChartCategoryOptions).reduce(
      (result, [key, value]) => {
        if (!isCategoryEnabled(key, info)) {
          return result;
        }
        result[key] = value;
        return result;
      },
      {}
    );
  }, [info]);
  return (
    <>
      <SelectorLabelWrapper label={"Show"}>
        <SimpleSelect<CriteriaOption, CriteriaOptionType>
          options={allCriteriaOptions}
          value={filter.criteria}
          getOptionLabel={(option) => option.title}
          getOptionValue={(option) => option.name}
          onValueChange={(value) => {
            updateFilter({ criteria: value });
          }}
        />
      </SelectorLabelWrapper>
      <SelectorLabelWrapper label={"Group by"}>
        <OptionSelector
          options={groupOptions}
          value={filter.category}
          onValueChange={(value) => {
            updateFilter({ category: value });
          }}
        />
      </SelectorLabelWrapper>
    </>
  );
}

function isCategoryEnabled(key: string, info: StoredTaskInfo) {
  if (
    key === pieChartCategoryOptions.Location &&
    !info?.request?.location?.length
  )
    return false;
  if (
    key === pieChartCategoryOptions.Place &&
    !info?.request?.on_person_detection === true
  )
    return false;
  return true;
}
