import React, { MouseEvent } from "react";
import { toast } from "react-toastify";
import styled from "@emotion/styled";
import { copyToClipboard } from "~/utils/jsUtils";

interface CopyTextProps {
  text: string;
  children: any;
}

function copyTextToClipboard(event: MouseEvent<HTMLAnchorElement>) {
  const text = event.currentTarget.title;
  copyToClipboard(text);
  event.preventDefault();
  toast.info("Copied to clipboard", {
    autoClose: 2500,
  });
}

export function CopyText(props: CopyTextProps) {
  return (
    <Span title={props.text} onClick={copyTextToClipboard}>
      {props.children}
    </Span>
  );
}

const Span = styled.span`
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
`;
