import React, { memo } from "react";
import WidgetGrid from "../../../components/widget/WidgetGrid";
import {
  getCategoryName,
  getPlatformName,
} from "../../../@core/utils/domainUtils";
import { PostFilter } from "../../../@core/types/api/stats/PostFilter";
import Notification from "../../../components/notification/Notification";
import { useProjectSocialStats } from "../../../store/project/projectHooks";
import AppPageSpinner from "../../../layouts/app/AppPageSpinner";
import StatsByWidgets from "./widgets/StatsByWidgets";
import SentimentChartWidget from "./widgets/SentimentChartWidget";
import TopPosts from "./top-posts/TopPosts";
import TopChannels from "./top-channel/TopChannels";
import PostHistoryWidgets from "./history/PostHistoryWidgets";

interface SocialDashboardWidgetsProps {
  filter: PostFilter;
  socialStats: ReturnType<typeof useProjectSocialStats>;
}

function SocialDashboardWidgets({
  socialStats,
  filter,
}: SocialDashboardWidgetsProps) {
  const { data, isFetching } = socialStats;

  if (isFetching) return <AppPageSpinner />;

  if (!isFetching && !data?.total?.count) {
    return (
      <Notification type={"info"}>
        No data has been collected for this project yet. Please contact OC
        support to enable Digital Noise Data for this project.
      </Notification>
    );
  }

  return (
    <>
      <WidgetGrid>
        <StatsByWidgets stats={data?.byPlatform} getLabel={getPlatformName} />
        <SentimentChartWidget stats={data?.total?.sentiments} />
      </WidgetGrid>
      <WidgetGrid>
        <StatsByWidgets
          stats={data?.byCategory}
          getLabel={getCategoryName}
          suffix={"by category"}
        />
      </WidgetGrid>
      <TopPosts filter={filter} />
      <TopChannels filter={filter} />
      <WidgetGrid>
        <PostHistoryWidgets filter={filter} />
      </WidgetGrid>
    </>
  );
}

export default memo(SocialDashboardWidgets);
