import React, { useState } from "react";
import styled from "@emotion/styled";
import {
  PostImportVideoBody,
  PostImportVideoResponse,
} from "@core/types/api/importRouteTypes";
import { TextInput } from "~/components/inputs/text/TextInput";
import Button from "~/components/button/Button";
import { useBindState } from "~/utils/hooks/useBindState";
import { fetchApi, toJson } from "~/services/fetchApi";
import { alertError } from "~/utils/alert/alertUtils";

interface ImportTestProps {}

export function ImportTest(props: ImportTestProps) {
  const projectId = useBindState(113);
  const url = useBindState("https://www.instagram.com/p/CINgF05Du87/");
  const [result, setResult] = useState("");
  return (
    <div>
      <h1>Instagram Import</h1>
      <Container>
        <p>
          The following input allows to import an Instagram video to a project.
        </p>
        <TextInput
          placeholder={"Project ID"}
          type={"number"}
          {...projectId.binders}
        />
        <TextInput placeholder={"Instagram Post URL"} {...url.binders} />
        <Button
          variant={"is-primary"}
          onClick={(event) => {
            const $target = event.currentTarget;
            $target.disabled = true;
            fetchVideoImport(projectId.value, url.value)
              .then((value) => {
                setResult(value ? JSON.stringify(value, null, 2) : "");
              })
              .catch(() => {
                alertError("Request failed");
              })
              .then(() => {
                $target.disabled = false;
              });
          }}
        >
          Import video
        </Button>
        {result && <pre>{result}</pre>}
      </Container>
    </div>
  );
}

function fetchVideoImport(projectId: number, url: string) {
  const body: PostImportVideoBody = { projectId, url };
  return fetchApi("/import/video", {
    method: "POST",
    body: JSON.stringify(body),
  }).then<PostImportVideoResponse>(toJson);
}

const Container = styled.div`
  max-width: 520px;

  input,
  p {
    margin-bottom: 12px;
  }
`;
