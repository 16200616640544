import { useMemo, useState } from "react";

export function useLocalBind<T>(initial: T, formatFn?: (value: any) => T) {
  const [state, setState] = useState<T>(initial);
  return useMemo(() => {
    return {
      value: state,
      onValueChange: (value: T) => {
        if (formatFn) {
          value = formatFn(value);
        }
        setState(value);
      },
    };
  }, [state]);
}
