import React, { useState } from "react";
import { toast } from "react-toastify";
import styled from "@emotion/styled";
import Button from "~/components/button/Button";
import { fetchApi, requireOk } from "~/services/fetchApi";
import { VIDEO_STATUS_ROUTE } from "~/utils/const";

interface ControlButtonsProps {
  isRunning?: boolean;
  refetch: VoidFunction;
}

export function ControlButtons({ isRunning, refetch }: ControlButtonsProps) {
  const [isFetching, setFetching] = useState(false);
  const disabled = isFetching;
  return (
    <Buttons>
      <Button
        disabled={!disabled && isRunning === true}
        variant={"is-primary"}
        onClick={() => {
          setFetching(true);
          fetchApi(`${VIDEO_STATUS_ROUTE}/start`, {
            method: "POST",
          })
            .then(requireOk)
            .then(() => {
              toast.success("Job will be started.");
              refetch();
              setTimeout(refetch, 250);
              setTimeout(refetch, 1000);
            })
            .catch((err) => {
              toast.error(`Something went wrong: ${err}`);
            })
            .then(() => {
              setFetching(false);
            });
        }}
      >
        Start
      </Button>
      <Button
        disabled={!disabled && isRunning === false}
        onClick={() => {
          setFetching(true);
          fetchApi(`${VIDEO_STATUS_ROUTE}/stop`, {
            method: "POST",
          })
            .then(requireOk)
            .then(() => {
              toast.success("Stopping the job");
              refetch();
            })
            .catch((err) => {
              toast.error(`Something went wrong: ${err}`);
            })
            .then(() => {
              setFetching(false);
            });
        }}
        variant={"is-danger"}
      >
        Stop
      </Button>
    </Buttons>
  );
}

const Buttons = styled.div`
  display: flex;
  gap: 1em;
`;
