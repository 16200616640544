import React from "react";
import styled from "@emotion/styled";
import { faStopCircle } from "@fortawesome/free-solid-svg-icons";
import { RecordingTask } from "@core/types/recordings/RecordingTask";
import Explain from "~/components/bulma/Explain";
import { formatLocalDateTime } from "~/utils/dateUtils";
import { CopyText } from "~/elements/anchor/CopyText";
import { Excerpt } from "~/components/misc/Excerpt";
import {
  refetchActiveRecordings,
  useActiveRecordings,
} from "~/screens/app/detection/debug/recording/service/fetchActiveRecordings";
import { useSorted } from "~/utils/hooks/useSorted";
import { LoadingIndicator } from "~/components/icons/LoadingIndicator";
import { useOptimisticConfirm } from "~/utils/alert/alertOptimisticConfirm";
import { alertError, alertInfo } from "~/utils/alert/alertUtils";
import { ClickableIcon } from "~/components/button/ClickableIcon";
import { fetchStopRecording } from "~/screens/app/detection/debug/recording/service/fetchStopRecording";

interface ActiveRecordingsProps {}

export function ActiveRecordings(props: ActiveRecordingsProps) {
  const { data: tasks, isFetching } = useActiveRecordings();
  const sorted = useSorted(
    tasks,
    (a, b) => b.created.getTime() - a.created.getTime()
  );
  return (
    <div>
      <h2>
        Running tasks
        <Explain size={"16px"}>
          The following recording jobs are currently running
        </Explain>
        <Loading isLoading={isFetching} />
      </h2>
      <RecordingTable>
        <thead>
          <tr>
            <th>#</th>
            <th>Erstellt</th>
            <th>Server</th>
            <th>Project ID</th>
            <th>Name / Stream ID</th>
            <th>Version</th>
            <th>Local file</th>
            <th>Public file</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {sorted?.map((t) => (
            <RecordingRow key={t.id} t={t} />
          ))}
        </tbody>
      </RecordingTable>
    </div>
  );
}

function RecordingRow({ t }: { t: RecordingTask }) {
  const { onClick: onStopTask, isPending } = useOptimisticConfirm({
    text: `Task ${t.name} (${t.id}) will be stopped`,
    onConfirm: () => {
      return fetchStopRecording({
        processId: t.id,
        server: t.server,
      })
        .then(() => {
          alertInfo(`Task ${t.name} (${t.id}) has been stopped`);
          setTimeout(() => {
            refetchActiveRecordings();
          }, 1000);
        })
        .catch((reason) => {
          alertError(`Failed stopping task ${t.id}: ${reason.message}`);
        });
    },
    options: {
      type: "error",
      time: process.env.NODE_ENV === "development" ? 2500 : 5000,
    },
  });
  const isRunning = !!t.name; // workaround
  return (
    <tr key={t.id}>
      <td>{t.id}</td>
      <td className={"nowrap"}>{formatLocalDateTime(t.created)}</td>
      <td>{t.server}</td>
      <td>{t.project_id}</td>
      <td>{t.name}</td>
      <td>{t.uid}</td>
      <td>
        <CopyText text={t.video_file}>
          <Excerpt length={36}>{t.video_file}</Excerpt>
        </CopyText>
      </td>
      <td>
        <a href={t.video_link}>
          <Excerpt length={36}>{t.video_link}</Excerpt>
        </a>
      </td>
      <td>
        {isRunning && (
          <ClickableIcon
            icon={faStopCircle}
            tooltip={`Task ${t.id} beenden`}
            onClick={onStopTask}
            disabled={isPending}
          />
        )}
      </td>
    </tr>
  );
}

const Loading = styled(LoadingIndicator)`
  margin-top: 2px;
  margin-left: 0.5rem;
  font-size: 16px;
  opacity: 0.6;
`;

export const RecordingTable = styled.table`
  td,
  th {
    padding: 0.2rem 1rem 0.2rem 0.2rem;
  }

  .shorten {
    max-width: 360px;
    overflow-x: hidden;
  }

  td.nowrap {
    white-space: nowrap;
  }

  tbody {
    tr:nth-of-type(odd) {
      background-color: #efefef;
    }
  }
`;
