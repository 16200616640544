import { Platform } from "../Platform";
import { DateType } from "../../utils/sharedTypes";
import { VideoStatus } from "./VideoStatus";
import { VideoCustomStatus } from "./VideoCustomStatus";
import { VideoCategory } from "./VideoCategory";

export interface VideoType {
  id: number;
  title: string;
  channelId: string;
  channelName: string;
  description: string;
  /** url of the page/post that contains the video */
  url: string;
  /** url of the video itself */
  downloadUrl: string;
  embedUrl: string;
  thumbnail: string;
  status: VideoStatus;
  customStatus?: VideoCustomStatus;
  category?: VideoCategory;
  length: number;
  views?: number;
  likeCount?: number;
  commentCount?: number;
  shareCount?: number;
  projectId: number;
  platformId: Platform;
  publishAt: DateType;
  comments: number;
  eventTag?: number;
  points: number;
  /** Internal note */
  note?: string;
}

type updateFields = "customStatus" | "category";

export type sortableVideoFields = "points";

export type VideoUpdate = Pick<VideoType, updateFields>;
export const updatableVideoFields: updateFields[] = [
  "customStatus",
  "category",
];

export type ExternalVideoType = Omit<VideoType, "note">;

export enum VideoDetectionStatus {
  QUEUED = 1,
  COMPLETE = 2,
  ERROR = -1,
  MISSING_URL = -2,
}
