import React, { ChangeEvent, createRef, useCallback, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faStepBackward,
  faStepForward,
} from "@fortawesome/free-solid-svg-icons";
import styled from "@emotion/styled";
import debounce from "lodash/debounce";
import { EMPTY_STRING } from "../../../utils/jsUtils";

interface PageSelectorProps {
  pageIndex: number;
  nextPage: () => void;
  previousPage: () => void;
  gotoPage: (page: number) => boolean;
  canPreviousPage: boolean;
  canNextPage: boolean;
  numberOfPages: number;
}

function pageIndexToString(pageIndex: number) {
  return pageIndex != null ? (pageIndex + 1).toString() : EMPTY_STRING;
}

function PageSelector(props: PageSelectorProps) {
  const input = createRef<HTMLInputElement>();
  useEffect(() => {
    input.current.value = pageIndexToString(props.pageIndex);
  }, [props.pageIndex]);
  const persistChange = useCallback(
    // @ts-ignore
    debounce((value) => {
      const page = parseInt(value);
      if (!isNaN(page)) {
        if (!props.gotoPage(page - 1)) {
          input.current.value = pageIndexToString(props.pageIndex);
        }
      }
    }, 1500),
    [props.gotoPage, props.pageIndex]
  );
  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      persistChange(value);
    },
    [persistChange]
  );
  return (
    <Container>
      <IconButton
        onClick={props.previousPage}
        disabled={!props.canPreviousPage}
      >
        <FontAwesomeIcon icon={faStepBackward} />
      </IconButton>
      <input
        className={"input is-small"}
        type="number"
        ref={input}
        onChange={onChange}
      />
      <span>/ {props.numberOfPages}</span>
      <IconButton onClick={props.nextPage} disabled={!props.canNextPage}>
        <FontAwesomeIcon icon={faStepForward} />
      </IconButton>
    </Container>
  );
}

const IconButton = styled.span<{ disabled: boolean }>`
  cursor: pointer;
  padding: 4px;
  &:hover {
    opacity: 0.7;
  }
  &[disabled] {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.4;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  input {
    max-width: 64px;
  }
  input,
  span {
    margin-left: 6px;
  }
`;

export default PageSelector;
