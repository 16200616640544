import React from "react";
import EbuFilterToggle, {
  EbuFilterToggleProps,
} from "../dashboard/filter/EbuFilterToggle";

type PostFilterWidgetProps = EbuFilterToggleProps;

function PostFilterWidget(props: PostFilterWidgetProps) {
  return <EbuFilterToggle {...props} />;
}

export default PostFilterWidget;
