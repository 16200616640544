import { useQuery } from "react-query";
import {
  LivestreamSectionType,
  LivestreamSectionTypeCreate,
  LivestreamSectionTypeUpdate,
} from "@core/types/domain/stream/LivestreamSectionType";
import { fetchApi, requireOk, toJson } from "~/services/fetchApi";
import { queryClient } from "~/utils/fetch/ReactQueryProvider";

export function fetchStreamSections(streamId: number) {
  return fetchApi(`/livestream/${streamId}/section`)
    .then(requireOk)
    .then<LivestreamSectionType[]>(toJson);
}

export function useStreamSections(streamId: number) {
  return useQuery([useSectionsKey, streamId], () =>
    fetchStreamSections(streamId)
  );
}

const useSectionsKey = "useSections";

export function refetchStreamSections(streamId?: number) {
  return queryClient.invalidateQueries(useSectionsKey);
}

export function fetchCreateStreamSection(
  streamId: number,
  section: LivestreamSectionTypeCreate
) {
  return fetchApi(`/livestream/${streamId}/section`, {
    method: "POST",
    body: JSON.stringify(section),
  }).then(toJson);
}

export function fetchUpdateStreamSection(
  streamId: number,
  sectionId: number,
  section: LivestreamSectionTypeUpdate
) {
  return fetchApi(`/livestream/${streamId}/section/${sectionId}`, {
    method: "PATCH",
    body: JSON.stringify(section),
  }).then(toJson);
}

export function fetchDeleteStreamSection(streamId: number, sectionId: number) {
  return fetchApi(`/livestream/${streamId}/section/${sectionId}`, {
    method: "DELETE",
  }).then(toJson);
}
