import React from "react";
import styled from "@emotion/styled";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { TimePicker } from "~/screens/app/detection/stream/videorange/TimePicker";
import { UseVideoRanges } from "~/screens/app/detection/stream/videorange/range/useVideoRanges";
import { ClickableIcon } from "~/components/button/ClickableIcon";

interface RangeSelectorProps extends UseVideoRanges {
  getCurrentPlayerTime: () => number;
}

export function RangeSelector({
  getCurrentPlayerTime,
  ranges,
  addRange,
  deleteRange,
  updateRange,
}: RangeSelectorProps) {
  return (
    <TimeTable>
      <thead>
        <tr>
          <th>From</th>
          <th>To</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {ranges.map((range, i) => {
          const isLastPosition = i === ranges.length - 1;
          const isLastItem = ranges.length === 1;
          return (
            <tr key={range.id}>
              <td>
                <TimePicker
                  value={range.from}
                  onClick={() => {
                    updateRange(range.id, {
                      from: getCurrentPlayerTime(),
                    });
                  }}
                  isSmall
                />
              </td>
              <td>
                <TimePicker
                  value={range.to}
                  onClick={() => {
                    updateRange(range.id, {
                      to: getCurrentPlayerTime(),
                    });
                  }}
                  isSmall
                />
              </td>
              <td>
                {!isLastItem && (
                  <ClickableIcon
                    icon={faTrash}
                    onClick={() => {
                      deleteRange(range.id);
                    }}
                  />
                )}
                {isLastPosition && (
                  <ClickableIcon
                    icon={faPlus}
                    onClick={() => {
                      addRange();
                    }}
                  />
                )}
              </td>
            </tr>
          );
        })}
      </tbody>
    </TimeTable>
  );
}

const TimeTable = styled.table`
  td {
    padding-right: 1em;
    padding-top: 6px;
    vertical-align: middle;
  }
  svg {
    margin-right: 0.5em;
  }
  margin-bottom: 1em;
`;
