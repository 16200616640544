import React, { useMemo } from "react";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { LivestreamTypeExternal } from "@core/types/domain/Livestream";
import { ProjectType } from "@core/types/domain/ProjectType";
import IconButton from "~/components/button/IconButton";
import {
  excelApiUrl,
  slugifyFileName,
} from "~/screens/app/excel/service/excelService";

interface ExcelDownloadButtonProps {
  project: ProjectType;
  stream: LivestreamTypeExternal;
  disabled?: boolean;
}

export function ExcelTimelineButton({
  project,
  stream,
  disabled,
}: ExcelDownloadButtonProps) {
  const data = useMemo(() => {
    const request = {
      title: stream.title,
      filename: slugifyFileName(stream.title),
      event_id: `${stream.id}`,
      project_id: `${project.id}`,
      step_seconds: 180,
    };
    return JSON.stringify(request);
  }, [project, stream]);
  return (
    <form method={"POST"} action={`${excelApiUrl}/timeline`}>
      <input type={"hidden"} name={"request"} value={data} />
      <IconButton
        icon={faDownload}
        type={"submit"}
        variant={"is-primary"}
        small
        disabled={disabled}
      >
        Event Report
      </IconButton>
    </form>
  );
}
