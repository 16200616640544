import { useQuery } from "react-query";
import { PostType } from "@core/types/domain/post/PostType";
import { PaginationState } from "@core/types/misc/PaginationState";
import { PostsSummary } from "@core/types/api/postRouteTypes";
import { fetchApi, toJson } from "~/services/fetchApi";
import { getCursorQueryParams } from "~/components/table/usePaginationState";

function fetchProjectPosts(projectId: number, cursor: PaginationState) {
  const params = getCursorQueryParams(cursor);
  return fetchApi(`/project/${projectId}/posts?${params}`).then<PostType[]>(
    toJson
  );
}

export function useProjectPosts(projectId: number, cursor: PaginationState) {
  return useQuery(projectId && ["usePosts", projectId, cursor], () =>
    fetchProjectPosts(projectId, cursor)
  );
}

export function useProjectPostsCount(projectId: number) {
  return useQuery(projectId && ["usePostsCount", projectId], () =>
    fetchApi(`/project/${projectId}/posts/count`).then<number>(toJson)
  );
}

export function useProjectPostsSum(projectId?: number) {
  return useQuery(projectId && ["usePostsSum", projectId], () =>
    fetchApi(`/project/${projectId}/posts/sum`).then<PostsSummary>(toJson)
  );
}
