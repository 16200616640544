import React from "react";
import { faEuroSign } from "@fortawesome/free-solid-svg-icons";
import { BrandStats } from "@core/types/detection/DetectionTypes";
import NumberWidget from "~/screens/app/dashboard/widgets/NumberWidget";
import { LivestreamType } from "~/@core/types/domain/Livestream";

interface BrandEuroValueProps {
  className?: string;
  summedStats: BrandStats;
  stream: LivestreamType | undefined; // TODO remove undefined
}

export function BrandEuroValue({
  summedStats,
  className,
  stream,
}: BrandEuroValueProps) {
  return (
    <NumberWidget
      title={"Euro value"}
      subTitle={"display value"}
      // subValue={
      //   scoreValue ? (
      //     <ReactCountUp end={scoreValue} suffix={" €"} separator={"."} />
      //   ) : null
      // }
      explain={`Views * (TKP / 1000) * Logo duration\n(taking into account the logo size and the logo position in the frame)`}
      icon={faEuroSign}
      value={summedStats.euroValue || null}
      className={className}
    />
  );
}
