import React from "react";
import { platforms } from "../../../../@core/types/domain/Platform";
import { IdsFilterType } from "../types/IdsFilterType";
import SelectFilter, { FilterOption } from "./select/SelectFilter";
import { FilterTagProps } from "./FilterTagProps";

type PlatformFilterProps = FilterTagProps<IdsFilterType>;

function PlatformFilter(props: PlatformFilterProps) {
  return <SelectFilter {...props} options={options} />;
}

const options: FilterOption[] = platforms.map((platform) => ({
  value: "" + platform.id,
  label: platform.name,
}));

export default PlatformFilter;
