import { FunctionComponent, useMemo } from "react";
import { UserRole } from "@core/types/domain/user/UserRole";
import { LoginUserState } from "~/store/login/loginReducer";
import { Dashboard } from "~/screens/app/dashboard/Dashboard";
import { DashboardNext } from "~/screens/app/dashboard/DashboardNext";
import SocialDashboardScreen from "~/screens/app/social/SocialDashboardScreen";
import { AppRoute } from "~/config/Routes";
import Support from "~/screens/app/Support";
import VideosScreen from "~/screens/app/videos/VideosScreen";
import LawyerVideosScreen from "~/screens/app/videos/LawyerVideosScreen";
import CheckPostsScreen from "~/screens/app/checker/CheckPostsScreen";
import DebugDetectionScreen from "~/screens/app/detection/debug/DebugDetectionScreen";
import { DebugLiveDetectionScreen } from "~/screens/app/detection/debug/live/DebugLiveDetectionScreen";
import { DetectionStatusScreen } from "~/screens/app/detection/debug/status/DetectionStatusScreen";
import LogoDetectionScreen from "~/screens/app/detection/demo/live/LogoDetectionScreen";
import ProjectDetectionScreen from "~/screens/app/detection/stats/project/ProjectDetectionScreen";
import VideoScreen from "~/screens/app/video/VideoScreen";
import ProjectsScreen from "~/screens/app/ProjectsScreen";
import AdminScreen from "~/screens/app/admin/AdminScreen";
import ReportsScreen from "~/screens/app/reports/ReportsScreen";
import { LivestreamOverviewScreen } from "~/screens/app/detection/livestreams/LivestreamOverviewScreen";
import { LivestreamScreen } from "~/screens/app/detection/livestreams/livestream/LivestreamScreen";
import { StreamStatsScreen } from "~/screens/app/detection/stats/stream/StreamStatsScreen";
import { DebugDetectionJobScreen } from "~/screens/app/detection/debug/live/DebugDetectionJobScreen";
import { FootageScreen } from "~/screens/app/footage/FootageScreen";
import { AdminStreamsScreen } from "~/screens/app/admin/streams/AdminStreamsScreen";
import { SignalsOverviewScreen } from "~/screens/app/admin/signals/SignalsOverviewScreen";
import { SignalMonitorScreen } from "~/screens/app/admin/signals/single/SignalMonitorScreen";
import { StreamStatsPage } from "~/screens/app/detection/stream/StreamStatsPage";
import { PostsScreen } from "~/screens/app/posts/PostsScreen";
import { DetectionModelsScreen } from "~/screens/app/detection/debug/models/DetectionModelsScreen";
import { DebugRecordingScreen } from "~/screens/app/detection/debug/recording/DebugRecordingScreen";
import { StreamCutScreen } from "~/screens/app/admin/stream/cut/StreamCutScreen";
import { ExcelReportsScreen } from "~/screens/app/excel/ExcelReportsScreen";
import { StreamChartScreen } from "~/screens/app/detection/charts/StreamChartScreen";
import { StreamAdminScreen } from "~/screens/app/detection/livestreams/livestream/admin/StreamAdminScreen";
import { DebugVideoDetectionScreen } from "~/screens/app/detection/debug/videos/DebugVideoDetectionScreen";
import { VideoImportScreen } from "~/screens/admin/videoimport/VideoImportScreen";
import { BrandReportScreen } from "~/screens/app/brandreport/BrandReportScreen";

interface RouteConfig {
  path: string;
  component: FunctionComponent<any>;
  canAccess?: boolean;
}

const mainRoute = "/app/";

function getAppRoutes(user: LoginUserState): RouteConfig[] {
  if (!user) return [];
  const routes: RouteConfig[] = [
    { path: mainRoute, component: Dashboard },
    { path: "/app/dashboard-next", component: DashboardNext },
    { path: AppRoute.SocialDashboard, component: SocialDashboardScreen },
    { path: "/app/support", component: Support },
    { path: "/app/videos", component: VideosScreen },
    { path: "/app/lawyervideos", component: LawyerVideosScreen },
    { path: AppRoute.Checker, component: CheckPostsScreen },
    { path: AppRoute.DebugDetection, component: DebugDetectionScreen },
    { path: AppRoute.DebugLiveDetections, component: DebugLiveDetectionScreen },
    {
      path: AppRoute.DebugLiveDetectionJob.path,
      component: DebugDetectionJobScreen,
    },
    { path: AppRoute.DebugDetectionStatus, component: DetectionStatusScreen },
    {
      path: AppRoute.DebugRecordingStatus.path,
      component: DebugRecordingScreen,
    },
    { path: AppRoute.DebugDetectionModels, component: DetectionModelsScreen },
    {
      path: AppRoute.DebugVideoDetection,
      component: DebugVideoDetectionScreen,
    },
    { path: AppRoute.LogoDetectionDetail.path, component: LogoDetectionScreen },
    { path: AppRoute.LivestreamOverview, component: LivestreamOverviewScreen },
    { path: AppRoute.Livestream.path, component: LivestreamScreen },
    { path: AppRoute.LivestreamAdmin.path, component: StreamAdminScreen },
    { path: AppRoute.DetectionProject, component: ProjectDetectionScreen },
    { path: AppRoute.DetectionLivestreams, component: StreamStatsScreen },
    { path: AppRoute.StreamCharts, component: StreamChartScreen },
    { path: AppRoute.DetectionLivestream.path, component: StreamStatsPage },
    { path: "/app/video/:videoId", component: VideoScreen },
    { path: "/app/projects", component: ProjectsScreen },
    { path: "/app/admin", component: AdminScreen },
    { path: "/app/stream/:streamId/cut", component: StreamCutScreen },
    { path: "/app/admin/streams", component: AdminStreamsScreen },
    { path: AppRoute.AdminSignals, component: SignalsOverviewScreen },
    { path: AppRoute.AdminSignal.path, component: SignalMonitorScreen },
    { path: AppRoute.Reports, component: ReportsScreen },
    { path: AppRoute.ExcelReports, component: ExcelReportsScreen },
    { path: AppRoute.FootageUpload, component: FootageScreen },
    { path: AppRoute.Posts, component: PostsScreen },
    { path: AppRoute.VideoImport, component: VideoImportScreen },
    {
      path: AppRoute.BrandReports,
      component: BrandReportScreen,
    },
    // ...(process.env.NODE_ENV === "development"
    //   ? [{ path: "/app/icons", component: IconsScreen }]
    //   : null), // TODO fix
  ];

  routes.forEach((r) => (r.canAccess = true));

  if (user.role === UserRole.LogoDetection) {
    routes
      .filter(
        (f) =>
          f.path !== AppRoute.LivestreamOverview &&
          f.path !== AppRoute.Livestream.path
      )
      .forEach((value) => {
        value.canAccess = false;
      });
  }
  return routes;
}

export function useAppRoutes(user: LoginUserState): RouteConfig[] {
  return useMemo(() => {
    return getAppRoutes(user);
  }, [user]);
}
