import React, { useMemo, useState } from "react";
import {
  activeDetectionServers,
  DetectionServerInfo,
} from "@core/types/detection/DetectionServer";
import SelectDropdown from "~/elements/selectdropdown/SelectDropdown";

interface ServerSelectionProps {
  value: string;
  onChange: (name: string, next: DetectionServerInfo) => void;
}

export function ServerSelection({ value, onChange }: ServerSelectionProps) {
  const options = activeDetectionServers;
  const values = useMemo(() => {
    const server = options.find((o) => o.name === value);
    return server ? [server] : null;
  }, [value]);
  return (
    <SelectDropdown<DetectionServerInfo>
      values={values}
      onChange={(values) => {
        const next = values?.[0];
        onChange(next?.name, next);
      }}
      options={activeDetectionServers}
      getOptionLabel={(s) => s.label}
      getOptionValue={(s) => s.name}
      formatTitle={(s) => s?.[0]?.label || "Select server"}
      keys={["name"]}
      hideClear
    />
  );
}

export function useServerSelection<T = DetectionServerInfo>() {
  const [selected, setSelected] = useState<T>();
  return useMemo(() => {
    return {
      bindings: {
        values: [selected],
        onChange(next: T[]) {
          setSelected(next[0]);
        },
      },
    };
  }, []);
}
