import React, {
  Fragment,
  MouseEventHandler,
  useCallback,
  useMemo,
  useState,
} from "react";
import styled from "@emotion/styled";
import FlipMove from "react-flip-move";
import { BrandStats } from "@core/types/domain/livestream/BrandStats";
import { isMobile } from "~/utils/style/styleUtils";
import { useBrands } from "~/services/brands/fetchBrands";
import { keyBy } from "~/utils/jsUtils";

interface StreamStatsProps {
  stats: BrandStats[];
}

function StreamStats({ stats }: StreamStatsProps) {
  if (!stats) return null;

  const { brands: allBrands } = useBrands();
  // const streamBrands = useRecoilValue(brandsState)

  const brands = useMemo(() => {
    return keyBy(allBrands, (b) => b.name) || {};
  }, [allBrands]);

  const max = useMemo(() => {
    if (!stats) return null;
    return stats && 1.2 * Math.max(...Object.values(stats).map((s) => s.score));
  }, [stats]);

  // const max = Math.max(60, ...stats.map((s) => s.duration))
  const [activeItems, setActiveItems] = useState<string[]>([]);

  const sorted = useMemo(() => {
    return stats?.sort((a, b) => {
      return a.score < b.score ? 1 : -1;
    });
  }, [stats]);

  const onRowClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    const name = event.currentTarget.closest("tr").dataset["name"];
    setActiveItems((items) => {
      const existing = items.indexOf(name) !== -1;
      if (existing) {
        return items.filter((i) => i !== name);
      } else {
        return [...items, name];
      }
    });
  }, []);

  if (!sorted.length) return null;

  return (
    <div>
      <Table>
        <FlipMove
          staggerDurationBy={30}
          duration={750}
          delay={30}
          typeName={"tbody"}
        >
          {sorted.map((s) => {
            const pc = (s.score / max) * 100;
            const name = s.name;
            const brand = brands[name];
            const active = activeItems.indexOf(name) !== -1;
            return (
              <Fragment key={name}>
                <tr
                  data-name={name}
                  className={active ? "is-active" : undefined}
                >
                  <td>
                    <LogoColumn>
                      {brand?.img && <img src={brand.img} alt={"Brand logo"} />}
                    </LogoColumn>
                  </td>
                  <th onClick={onRowClick} className={"logo-name"}>
                    <div>
                      {/*{brand?.img && <Logo src={brand.img} alt="" />}*/}
                      {brand?.label || name}
                    </div>
                  </th>
                  <ValueTd
                    // value={formatDuration(s.duration)}
                    value={formatDuration(s.score)}
                    onClick={onRowClick}
                  />
                  <ProgressTd onClick={onRowClick} progress={pc} />
                </tr>
                {active && (
                  <ValueRow
                    label={"Visibility score"}
                    value={formatDuration(s.score, 2)}
                    progress={pc}
                  />
                )}
                {active && (
                  <ValueRow
                    label={"Duration"}
                    value={formatDuration(s.duration, 1) + "s"}
                    progress={pc}
                  />
                )}
                {active && (
                  <ValueRow
                    label={"Ø Scale"}
                    value={formatDuration(s.scale * 100) + " %"}
                    progress={pc}
                  />
                )}
              </Fragment>
            );
          })}
        </FlipMove>
      </Table>
      <Explain>Click on a bar to see more details</Explain>
      {/*<StreamChart stats={stats} />*/}
    </div>
  );
}

const Explain = styled.div`
  margin-top: 12px;
`;

const logoSize = 36;
const LogoColumn = styled.div`
  background-color: unset !important;
  padding: 0 !important;
  margin-right: 12px;
  min-height: ${logoSize}px;
  img {
    display: block;
    border-radius: 5px;
    width: ${logoSize}px;
    min-width: ${logoSize}px;
    height: ${logoSize}px;
  }
`;

const Table = styled.table`
  width: 100%;
  table-layout: auto;
  margin-top: -4px;
  th,
  td {
    padding-top: 4px;

    div {
      background-color: #64bd52;
      padding: 6px 12px;
      color: white;
    }
  }

  tr:not(.details) {
    cursor: pointer;
  }

  tr {
    &.details {
      td,
      th {
        padding-top: 0;
        div {
          font-size: 0.9rem;
          padding-top: 0;
        }
      }
    }

    &.details div,
    &.is-active div {
      margin-top: 12px;
      background-color: #1b325e;
    }

    &.details div {
      color: black;
      background-color: transparent;
      margin: 0;
      padding: 4px 0 0 12px;
    }

    th,
    td {
      width: 1px;
      &:last-child {
        width: 100%;
      }
    }

    th {
      white-space: nowrap;
    }
  }

  ${isMobile} {
    th.logo-name {
      display: none;
    }
  }
`;

const ValueRow = (props: {
  label: string;
  value: string;
  progress: number;
}) => (
  <tr className={"details"}>
    <td></td>
    <td>
      <div>{props.label}</div>
    </td>
    <ValueTd value={props.value} />
    <ProgressTd progress={props.progress} />
  </tr>
);

const ValueTd = (props: { value: string; onClick?: MouseEventHandler }) => (
  <td onClick={props.onClick}>
    <ValueCell duration={props.value}>{props.value}</ValueCell>
  </td>
);

const ProgressTd = (props: {
  progress: number;
  onClick?: MouseEventHandler;
}) => (
  <td>
    <ProgressCell width={props.progress} onClick={props.onClick}>
      &nbsp;
    </ProgressCell>
  </td>
);

const ValueCell = styled.div<{ duration: string }>`
  white-space: nowrap;
  //   position: relative;
  //   color: transparent !important;
  // &:after {
  //   color: white;
  //   content: '${(props) => props.duration}';
  //   position: absolute;
  //   right: 0;
  //   top: 0;
  //   bottom: 0;
  //    display: flex;
  //   align-items: center;
  //   justify-content: center;
  // }
`;

const ProgressCell = styled.div<{ width: number }>`
  width: ${(props) => props.width}%;
  transition: width ease 2s;
`;

function formatDuration(seconds: number, toFixed = 2) {
  return seconds.toFixed(toFixed).replace(".", ",");
}

export default StreamStats;
