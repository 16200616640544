import React from "react";
import { StaticFilterType } from "../types/StaticFilterType";
import { FilterTagProps } from "./FilterTagProps";
import ActiveFilter from "./ActiveFilter";

type StaticFilterProps = FilterTagProps<StaticFilterType>;

function StaticFilter(props: StaticFilterProps) {
  return (
    <ActiveFilter
      allFilters={props.allFilters}
      filter={props.filter}
      field={props.field}
    >
      {props.field.label}
    </ActiveFilter>
  );
}

export default StaticFilter;
