import { useMemo } from "react";
import { mapValues } from "lodash";
import {
  LivestreamDetectionType,
  LivestreamType,
} from "@core/types/domain/Livestream";
import {
  BrandStatsBySize,
  DetectionResult,
  DetectionWithStream,
} from "@core/types/detection/DetectionTypes";
import { DetectionServer } from "@core/types/detection/DetectionServer";
import { sum } from "~/utils/jsUtils";

export function useStreamStatsWithEuroValue(
  streams: LivestreamType[],
  results: LivestreamDetectionType[],
  fallbackCosts?: number
) {
  return useMemo(() => {
    return results?.map<StreamWithEuroValue>((result) => {
      const stream = streams?.find((s) => s.id === result.streamId);
      const costPerSecond = stream.costPerSecond || fallbackCosts;
      if (!costPerSecond) return result;
      return {
        ...result,
        logos: mapLogoStats(result, costPerSecond),
        _stream: stream,
      };
    });
  }, [streams, results]);
}

export interface StreamWithEuroValue
  extends LivestreamDetectionType,
    DetectionWithStream {}

export function useVideoStatsWithEuroValue(
  results: DetectionResult[],
  costsPerView: number
) {
  return useMemo(() => {
    return (
      results
        // ?.filter((_, i) => i > 290 && i <300)
        ?.map((result) => {
          const views = result.views || 10; // TODO OK?
          const length = result.length || 20; // TODO OK?
          return {
            ...result,
            logos: mapValues(result.logos, (bySize) => {
              const allDuration = getTotalDuration(bySize);
              const partialDuration = allDuration / length;
              const allEuroValue = partialDuration * costsPerView * views;
              return Object.entries(bySize).reduce((result, [size, stats]) => {
                const quota = stats.duration / allDuration;
                const sizeMultiplier = getMultiplizer(size);
                const euroValue = allEuroValue * (quota * sizeMultiplier);
                result[size] = {
                  ...stats,
                  euroValue,
                };
                return result;
              }, {} as BrandStatsBySize);
            }),
          };
        })
    );
  }, [results, costsPerView]);
}

function getTotalDuration(bySize: BrandStatsBySize) {
  const all = bySize.all_all;
  if (all) return all.duration;
  console.warn("could not find all");
  return sum(Object.values(bySize), (s) => s.duration);
}

function mapLogoStats(result: DetectionResult, costPerSecond: number) {
  return mapValues(result.logos, (bySize) => {
    return Object.entries(bySize).reduce((result, [size, stats]) => {
      const sizeMultiplier = getMultiplizer(size);
      result[size] = {
        ...stats,
        euroValue: costPerSecond * stats.duration * sizeMultiplier,
      };
      return result;
    }, {} as BrandStatsBySize);
  });
}

export function getMultiplizer(size: string) {
  switch (size) {
    case "all":
    case "all_all":
      return 0;
    case "all_big":
    case "big":
      return 1;
    case "all_small":
    case "small":
      return 0.25;
    default:
      return 0.5;
  }
}
