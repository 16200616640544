export enum CriteriaOptionType {
  visibility = "visibility",
  visibility_relative = "visibility_relative",
  lduration = "lduration",
  // multiple = "multiple",
  // age = "age",
  dominance = "dominance",
  contrast = "contrast",
}

export const criteriaOptions: Record<CriteriaOptionType, CriteriaOption> = {
  visibility: {
    name: "visibility",
    title: "Visibility",
    description: "Estimated attention impact of logo while visible",
    explanation:
      "A percentage that estimates how much of the viewer attention the logo is getting - when the logo is visible The value is calculated based on the logo-size, the exclusivity to other brands and its contrast. We assume that the attention increase as the display-time get close to 1 second, and then slowly decrease. (half-time 15sec) Ultimately we calculate an attention center on the screen and assume that logos more close to this point get more attention.",
  },
  visibility_relative: {
    name: "visibility_relative",
    title: "Visibility % over time",
    description: "Estimated attention on logo",
    explanation:
      "Same as Visibility. But measured over the total time instead of only the time the logo was visible.",
  },
  lduration: {
    name: "lduration",
    title: "Duration",
    description: "In how many seconds the logo was visible",
    explanation:
      "The duration indicates in how many seconds of the video at least one logo of the brand was visible (the size of the logos does not matter in this respect).",
  },
  // multiple: {
  //   name: "multiple",
  //   title: "Ø Count",
  //   description: "Average logo count at the same time",
  //   explanation:
  //     'The average count indicates whether the logos of this brand appear more often "next to each other" or "standing alone". A value of 2 means that on average 2 logos of the same brand were visible at the same time, while a value of 1.5 means that the logo was partially visible alone and partially visible twice.',
  // },
  // age: {
  //   name: "age",
  //   title: "Ø Display Time",
  //   description: "Average duration of a single logo (approximate)",
  //   explanation:
  //     "The average display time indicates how long the recognized logos were visible on average. The value is always an approximation and not 100% reliable, as logo tracking is prone to error during fast camera movements.",
  // },
  dominance: {
    name: "dominance",
    title: "Ø Exclusivity",
    description: "How much the logo was visible without other logos",
    explanation:
      'The average exclusivity indicates in how many cases logos of this brand were visible without "foreign" brands being visible at the same time. This detection only includes the trained brands that are also visible in the charts.',
  },
  contrast: {
    name: "contrast",
    title: "Ø Contrast (beta)",
    description: "Color difference in logos (beta)",
    explanation:
      "The average contrast indicates how well the displayed logos were visible on average. The contrast is the difference between dark and light colors of the logo. Higher contrast means better perceptibility/visibility.",
  },
};

export const allCriteriaOptions = Object.values(criteriaOptions);

export interface CriteriaOption {
  /** internal name */
  name: string;
  title: string;
  description: string;
  explanation: string;
}
