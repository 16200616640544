import React, { useMemo } from "react";
import { PostType } from "../../@core/types/domain/post/PostType";
import { Platform } from "../../@core/types/domain/Platform";
import EmbedoView from "./Embedo";

interface EmbeddedPostProps {
  post: PostType;
}

function EmbeddedPost({ post }: EmbeddedPostProps) {
  const url = useMemo(() => {
    if (post.platform === Platform.Facebook) {
      return `https://www.facebook.com/${post.channelId}/posts/${post.postId}`;
    } else return post.url;
  }, [post]);

  return <EmbedoView url={url} />;
}

export default EmbeddedPost;
