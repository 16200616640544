import React, { useMemo } from "react";
import AppPageLayout from "../../../layouts/app/AppPageLayout";
import {
  ProjectSelectionContext,
  useProjectSelection,
} from "../../../elements/context/project/ProjectSelectionContext";
import FloatingProjectSelector from "../../../elements/context/project/FloatingProjectSelector";
import {
  useProject,
  useSelectedProject,
} from "../../../store/project/projectHooks";
import VideoList from "../../../views/videos/VideoList";
import { VideoFilterType } from "../../../views/videos/filter/types/VideoFilterType";
import { useUserRole } from "../../../store/login/loginHooks";
import { UserRole } from "../../../@core/types/domain/user/UserRole";
import LawyerVideosScreen from "./LawyerVideosScreen";

interface VideosScreenProps {}

function VideosScreen(props: VideosScreenProps) {
  const role = useUserRole();
  if (role === UserRole.Lawyer) {
    return <LawyerVideosScreen />;
  }
  return (
    <AppPageLayout>
      <h1>Videos</h1>
      <ProjectSelectionContext resolveFromUrl>
        <FloatingProjectSelector autoSelect />
        <ProjectVideoView />
      </ProjectSelectionContext>
    </AppPageLayout>
  );
}

function ProjectVideoView() {
  const project = useSelectedProject();
  const forcedFilter = useMemo(() => {
    if (!project) return null;
    return {
      project: {
        in: [project && project.id],
      },
    } as VideoFilterType;
  }, [project]);
  return project ? <VideoList forcedFilter={forcedFilter} /> : null;
}

export default VideosScreen;
