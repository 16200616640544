import React from "react";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import Notification from "~/components/notification/Notification";

interface ChartHelpIntroProps {}

export function ChartHelpIntro(props: ChartHelpIntroProps) {
  return (
    <Notification type={"info"} marginTop={"1rem"} icon={faInfoCircle}>
      The following charts show the desired value (e.g. the display time of
      logos) grouped by brand and a desired criterion (e.g. the logo size). One
      chart is grouped by brand first, the other by the selected criterion.
      Moreover, the first grouping can be clicked in each case to get a better
      overview.
    </Notification>
  );
}
