import React, { memo, useEffect, useRef } from "react";
import Chart, {
  ChartConfiguration,
  ChartData,
  ChartOptions,
  ChartType,
} from "chart.js";
import styled from "@emotion/styled";
import ChartDataLabels from "chartjs-plugin-datalabels";
Chart.plugins.unregister(ChartDataLabels);

interface ChartjsChartProps {
  type: ChartType;
  data: ChartData;
  options?: ChartOptions;
  plugins?: ChartConfiguration["plugins"];
}

function ChartjsChart({ data, options, type, plugins }: ChartjsChartProps) {
  const chart = useRef<Chart>();
  const canvas = useRef<HTMLCanvasElement>(null);
  useEffect(() => {
    if (!canvas.current) return;
    chart.current = new Chart(canvas.current, {
      type,
      data,
      options,
      plugins,
    });
    return () => {
      chart.current.destroy();
    };
  }, [options, type]);
  useEffect(() => {
    chart.current.data = data;
    chart.current.update({ duration: 0 });
  }, [data]);
  return (
    <ChartContainer>
      <canvas ref={canvas} />
    </ChartContainer>
  );
}

const ChartContainer = styled.div`
  position: relative;
  width: 100%;
`;

export default memo(ChartjsChart);
