import React, { memo, useState } from "react";
import styled from "@emotion/styled";
import { useHasPermission } from "../../store/login/permissionSelectors";
import { UserPermission } from "../../@core/types/domain/user/UserPermission";
import {
  allVideoCategories,
  videoCategoryById,
} from "../../@core/types/domain/video/VideoCategory";
import DropdownMenu, {
  DropdownMenuOption,
} from "../inputs/dropdown/DropdownMenu";
import { StatusTag } from "../../views/videos/table/cells/videoTableCells";

interface CategorySelectionProps {
  value: number;
  onChange: (option: DropdownMenuOption<number>) => void;
}

function CategorySelection(props: CategorySelectionProps) {
  const [opened, setOpen] = useState(false);
  const canEdit = useHasPermission(UserPermission.canUpdateCategory);
  const categoryId = props.value;
  const category = videoCategoryById[categoryId || 0];
  if (opened) {
    const close = () => setOpen(false);
    return (
      <DropdownMenu
        options={categoryOptions}
        isOpened={opened}
        onChange={(option: DropdownMenuOption<number>) => {
          props.onChange(option);
          close();
        }}
      >
        <CategoryTag onClick={close}>
          {category ? category.name : categoryId}
        </CategoryTag>
      </DropdownMenu>
    );
  } else {
    return (
      <CategoryTag onClick={canEdit ? () => setOpen(true) : null}>
        {category ? category.name : categoryId}
      </CategoryTag>
    );
  }
}

const CategoryTag = styled(StatusTag)`
  cursor: ${(props) => props.onClick && "pointer"};
`;

const categoryOptions = allVideoCategories.map((value) => ({
  label: value.name,
  value: value.id,
}));

export default memo(CategorySelection);
