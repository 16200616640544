import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { DemoLiveStream, LiveStream } from "@core/types/domain/Livestream";
import StreamStats from "./stats/StreamStats";
import { getQueryParam, toInt } from "~/utils/jsUtils";
import { DemoConfig } from "~/screens/app/detection/demo/data/useDemoStreamStats";
import { useStreamStats } from "~/screens/app/detection/demo/data/useStreamStats";
import { useSummedStreamStats } from "~/screens/app/detection/utils/useSummedStreamStats";

interface LogoStreamViewProps {
  stream: LiveStream & DemoLiveStream;
}

const startTime =
  process.env.NODE_ENV === "development"
    ? getQueryParam("start_time", toInt)
    : undefined;

const debug =
  process.env.NODE_ENV === "development" && getQueryParam("debug") === "";

function LogoStreamView({ stream }: LogoStreamViewProps) {
  const $video = useRef<HTMLVideoElement>();
  // const [videoInfo, setVideoInfo] = useState<
  //   Pick<HTMLVideoElement, "duration">
  // >()
  const { data } = useStreamStats(stream);
  const stats = data?.stats;

  const [seconds, setSeconds] = useState<number>(startTime);

  const onTimeUpdate = useCallback(
    (event: React.SyntheticEvent<HTMLVideoElement>) => {
      // console.debug("onTimeUpdate", event.currentTarget.currentTime)
      const currentSeconds = Math.round(event.currentTarget.currentTime);
      if (seconds !== currentSeconds) {
        setSeconds(currentSeconds);
      }
    },
    [seconds]
  );

  // useEffect(() => {
  //   sumStreamStats(data, 10)
  // }, [data]);
  // return null

  // const onCanPlay = useCallback(
  //   (event: React.SyntheticEvent<HTMLVideoElement>) => {
  //     setVideoInfo({ duration: event.currentTarget.duration })
  //   },
  //   []
  // )

  useEffect(() => {
    if (!startTime || !$video.current) return;
    $video.current.currentTime = startTime;
  }, []);

  const summedStats = useSummedStreamStats(stats, seconds);

  return (
    <Container>
      <div>
        <video
          ref={$video}
          controls
          muted
          onTimeUpdate={onTimeUpdate}
          // onCanPlay={onCanPlay}
        >
          <source src={stream.url} />
        </video>
      </div>
      <div>
        <StreamStats stats={summedStats} />
        {debug && data && !stats && (data as DemoConfig).version && (
          <div>Version: {(data as DemoConfig).version}</div>
        )}
      </div>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  margin: -24px;
  > div {
    padding: 24px;
    flex: 1;
  }
`;

export default LogoStreamView;
