import { KeyBy } from "@core/types/utils/utilTypes";

/**
 * merges source into target and resolves conflicts
 */
export function mergeObjects<T>(
  target: KeyBy<T>,
  source: KeyBy<T>,
  resolveConflict: (target: T, source: T, key: string) => T
) {
  if (!source) return target;
  return Object.entries(source).reduce(
    (next, [key, value]) => {
      if (next[key]) {
        // duplicate
        next[key] = resolveConflict(value, next[key], key);
      } else {
        next[key] = value;
      }
      return next;
    },
    {
      ...target,
    }
  );
}
