import React from "react";
import styled from "@emotion/styled";
import { GetUncheckedPostsQuery } from "@core/types/api/checkerApiTypes";
import { PostCheckCard } from "~/screens/app/checker/post/PostCheckCard";
import { useCheckStatus } from "~/services/checker/checkerHooks";
import { RefreshButton } from "~/components/icons/RefreshButton";

interface CheckStatusCardProps {
  filter: GetUncheckedPostsQuery;
}

export function CheckStatusCard(props: CheckStatusCardProps) {
  const { data: stats, isFetching, refetch } = useCheckStatus(props.filter);
  return (
    <Card>
      <div>
        Remaining approximately: <b>{!isFetching && stats?.remaining}</b>
      </div>
      <div>
        <RefreshButton isLoading={isFetching} onClick={() => refetch()} />
      </div>
    </Card>
  );
}

const Card = styled(PostCheckCard)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;
