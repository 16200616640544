import React from "react";
import styled from "@emotion/styled";
import { toast } from "react-toastify";
import AppPageLayout from "~/layouts/app/AppPageLayout";
import { ProjectSelectionContext } from "~/elements/context/project/ProjectSelectionContext";
import { ExcelReportsView } from "~/screens/app/excel/ExcelReportsView";
import Notification from "~/components/notification/Notification";
import { useSelectedProject } from "~/store/project/projectHooks";

export function ExcelReportsScreen() {
  return (
    <AppPageLayout>
      <ProjectSelectionContext resolveFromUrl>
        <Inner />
      </ProjectSelectionContext>
    </AppPageLayout>
  );
}

function Inner() {
  const project = useSelectedProject();
  return (
    <Container>
      <h1>Livestream Reports (Excel)</h1>
      <Notification type={"info"}>
        Use our interactive Excel tables for a detailed evaluation of individual
        livestreams or the entire project.{" "}
        <a
          href="#"
          onClick={(event) => {
            toast.error("Not implemented yet"); // TODO
            event.preventDefault();
          }}
        >
          Further explanations.
        </a>
      </Notification>
      <ExcelReportsView project={project} />
    </Container>
  );
}

const Container = styled.div`
  & > * {
    margin-bottom: 1rem;
  }
`;
