import React from "react";
import AppPageLayout from "~/layouts/app/AppPageLayout";
import { ProjectSelectionContext } from "~/elements/context/project/ProjectSelectionContext";
import FloatingProjectSelector from "~/elements/context/project/FloatingProjectSelector";
import { PostsTableContainer } from "~/screens/app/posts/PostsTable";
import { PostsDashboard } from "~/screens/app/posts/PostsDashboard";
import { useSelectedProject } from "~/store/project/projectHooks";
import { useProjectPostsSum } from "~/services/post/fetchProjectPosts";
import { Spinner } from "~/components/spinner/Spinner";

export function PostsScreen() {
  return (
    <AppPageLayout>
      <h1>Social Posts</h1>
      <ProjectSelectionContext resolveFromUrl>
        <FloatingProjectSelector autoSelect />
        <PostsView />
      </ProjectSelectionContext>
    </AppPageLayout>
  );
}

function PostsView() {
  const project = useSelectedProject();
  const { data: summary, isFetching } = useProjectPostsSum(project?.id);
  if (isFetching || project === undefined) return <Spinner />;
  if (!project) return null;
  return (
    <div>
      <PostsDashboard project={project} summary={summary} />
      <PostsTableContainer project={project} summary={summary} />
    </div>
  );
}
