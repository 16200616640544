import React from "react";
import { LivestreamType } from "@core/types/domain/Livestream";
import { useStreamStats } from "~/screens/app/brandreport/data/useStreamStats";
import AppPageSpinner from "~/layouts/app/AppPageSpinner";
import { BrandReport } from "~/screens/app/brandreport/report/BrandReport";
import { BrandReportView } from "~/screens/app/brandreport/report/BrandReportView";

interface BrandReportProps {
  streams: LivestreamType[];
}

export function BrandReportContainer({ streams }: BrandReportProps) {
  const { data: stats, isLoading } = useStreamStats(streams);
  if (isLoading || !stats.length) return <AppPageSpinner />;
  return <BrandReportView stats={stats} />;
}
