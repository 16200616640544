import { LivestreamType } from "@core/types/domain/Livestream";
import { useMemo } from "react";
import { parseDetectionResult } from "@core/types/utils/detectionUtils";
import { TimeRangeInfo } from "~/screens/app/detection/stream/videorange/SectionSelectorView";
import { useRangeStreamStats } from "~/screens/app/detection/stream/data/useRangeStreamStats";

export function usePlacementStats(
  stream: LivestreamType,
  ranges: TimeRangeInfo[]
) {
  const { data, isFetching } = useRangeStreamStats(stream, ranges);
  return useMemo(() => {
    return {
      data: getResults(),
      isFetching,
    };
    function getResults() {
      if (!data) return undefined;
      return parseDetectionResult(data["Place"]);
    }
  }, [data, isFetching]);
}
