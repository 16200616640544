import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClipboard,
  faComments,
  faMinusCircle,
  faShareSquare,
} from "@fortawesome/free-solid-svg-icons";
import styled from "@emotion/styled";
import { Link } from "gatsby";
import { CellProps } from "../table/cells/videoTableCells";
import { VideoType } from "../../../@core/types/domain/video/VideoType";
import { shorten } from "../../../utils/jsUtils";
import { Platform } from "../../../@core/types/domain/Platform";
import { alertError } from "../../../utils/alert/alertUtils";
import { getExternalVideoUrl } from "../../../screens/video/getExternalVideoUrl";

type ActionsCellProps = CellProps<VideoType>;

function ActionsCell(props: ActionsCellProps) {
  const video = props.cell.row.original;
  const badgeClass =
    video.comments > 0
      ? "has-badge-rounded"
      : "has-badge-rounded has-badge-dark";
  return (
    <ActionButtons>
      <ActionButton
        to={`/app/video/${video.id}`}
        className={badgeClass}
        data-badge={video.comments}
        title={"Go to comments"}
      >
        <FontAwesomeIcon icon={faComments} size={"lg"} fixedWidth />
      </ActionButton>
      {video.note && (
        <ActionButton
          to={`/app/video/${video.id}`}
          title={shorten(video.note, 64)}
        >
          <FontAwesomeIcon icon={faClipboard} size={"lg"} fixedWidth />
        </ActionButton>
      )}
      {video.platformId === Platform.YouTube && (
        <BaseActionButton title={"Request takedown"} onClick={alertTakedown}>
          <FontAwesomeIcon icon={faMinusCircle} size={"lg"} fixedWidth />
        </BaseActionButton>
      )}
      <ShareAction video={video} />
    </ActionButtons>
  );
}

function ShareAction({ video }: { video: VideoType }) {
  const url = getExternalVideoUrl(video.id);
  if (!url) return null;
  return (
    <LinkButton href={url} target={"_blank"} title={"Open external page"}>
      <FontAwesomeIcon icon={faShareSquare} size={"lg"} fixedWidth />
    </LinkButton>
  );
}

function alertTakedown() {
  alertError(`Takedown procedure not available, contact OC support`);
}

const ActionButtons = styled.div`
  display: flex;
`;

const BaseActionButton = styled.div`
  display: inline-block;
  color: inherit;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
  :not(:last-child) {
    margin-right: 12px;
  }
`;

const DisabledButton = styled(BaseActionButton)`
  &,
  &:hover {
    opacity: 0.3;
  }
  cursor: not-allowed;
`;

const ActionButton = BaseActionButton.withComponent(Link);
const LinkButton = BaseActionButton.withComponent("a");

export default ActionsCell;
