import React from "react";
import styled from "@emotion/styled";
import AppPageLayout from "~/layouts/app/AppPageLayout";
import { StreamPieCharts } from "~/screens/app/detection/charts/StreamPieCharts";
import { useIsClient } from "~/utils/hooks/useIsClient";
import { ProjectSelectionContext } from "~/elements/context/project/ProjectSelectionContext";

export function StreamChartScreen() {
  if (!useIsClient()) return null;
  return (
    <AppPageLayout>
      <ProjectSelectionContext resolveFromUrl>
        <StreamPieCharts />
      </ProjectSelectionContext>
      {/*<Iframe src="https://l1api.liquid.ai/chart/177/L_534/Size/lduration/0/sunburst?&event=/177/L_534&category=/Size&focus=/lduration&typ1=/0/sunburst" />*/}
    </AppPageLayout>
  );
}

const Iframe = styled.iframe`
  width: 100%;
  height: 2000px;
`;
