import React, { useMemo } from "react";
import { ChartOptions } from "chart.js";
import ChartjsChart from "./ChartjsChart";

interface DateDate {
  value: number;
  date: Date;
}

export interface DailyBarChartProps {
  label?: string;
  data: DateDate[] | undefined;
}

function DailyBarChart(props: DailyBarChartProps) {
  const data = useMemo(
    () => props.data?.length && getData(props),
    [props.data]
  );

  const options = useMemo(() => {
    if (!data) return undefined;

    const min = data.datasets[0].data
      .map((value) => value.y)
      .reduce((min, value) => {
        return min > value ? value : min;
      });

    const max = data.datasets[0].data
      .map((value) => value.y)
      .reduce((min, value) => {
        return min < value ? value : min;
      }, 0);

    return {
      legend: {
        display: false,
      },
      scales: {
        xAxes: [
          {
            type: "time",
            time: {
              unit: "day",
            },
            offset: true,
          },
        ],
        yAxes: [
          {
            ticks: {
              suggestedMin: min * 0.9,
              suggestedMax: max * 1.01,
              callback(
                value: number,
                index: any,
                values: any
              ): string | number {
                return value.toLocaleString();
              },
            },
          },
        ],
      },
    } as ChartOptions;
  }, [data]);

  if (!data) return null;
  return <ChartjsChart type={"bar"} data={data} options={options} />;
}

function getData({ data, label }: DailyBarChartProps) {
  return {
    // labels: data.map(value => value.date.toString()),
    datasets: [
      {
        label,
        backgroundColor: "red",
        data: data.map((value) => ({
          t: value.date,
          y: value.value,
        })),
      },
    ],
  };
}

export default DailyBarChart;
