import React, { useCallback, useEffect, useState } from "react";
import styled from "@emotion/styled";
import {
  faCheck,
  faQuestion,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import {
  allVideoCategories,
  VideoCategory,
  VideoCategoryType,
} from "@core/types/domain/video/VideoCategory";
import { PostType } from "@core/types/domain/post/PostType";
import {
  allPostMediaTypes,
  PostMediaOption,
  PostMediaType,
} from "@core/types/domain/post/PostMediaType";
import { CheckerProject } from "@core/types/domain/ProjectType";
import { Platform } from "@core/types/domain/Platform";
import { PostCheckStatus } from "@core/types/domain/post/PostCheckStatus";
import { alertError } from "~/utils/alert/alertUtils";
import { toInt } from "~/utils/jsUtils";
import { PostCheckCard } from "~/screens/app/checker/post/PostCheckCard";
import IconButton from "~/components/button/IconButton";
import SimpleSelect from "~/screens/app/checker/SimpleSelect";
import { useLocalBind } from "~/utils/hooks/useLocalBind";
import { fetchUpdatePostAsChecker } from "~/services/post/postService";

interface CheckControlsProps {
  project: CheckerProject;
  post: PostType;
  getNext: () => void;
}

function getDefaultType(post: PostType): PostMediaType {
  if (post.platform === Platform.YouTube) return PostMediaType.Video;
  else if (post.platform === Platform.Instagram) return PostMediaType.Image;
  else return undefined;
}

function CheckControls({ project, post, getNext }: CheckControlsProps) {
  const [isLoading, setLoading] = useState(false);
  const typeState = useLocalBind<PostMediaType>(undefined);
  const categoryState = useLocalBind<VideoCategory>(undefined);

  useEffect(() => {
    if (!post) return;
    typeState.onValueChange(post.type || getDefaultType(post));
    categoryState.onValueChange(post.category);
  }, [post]);

  const type = typeState.value;
  const category = categoryState.value;

  const onUpdate = useCallback(
    (isCausal: boolean, checkStatus: PostCheckStatus) => {
      const update = {
        category,
        type,
        isCausal,
        checkStatus,
        checkLock: null,
      };
      setLoading(true);
      fetchUpdatePostAsChecker(post.id, update)
        .then(() => {
          Object.assign(post, update);
          getNext();
        })
        .catch(alertError)
        .then(() => setLoading(false));
    },
    [type, category, getNext, post]
  );

  const isFormComplete = !!type && !!category;

  return (
    <>
      <ProjectInfo>
        <ProjectName>{project.name}</ProjectName>
        {/*<div>*/}
        {/*  <small>*/}
        {/*    <b>{remaining}</b> remaining.*/}
        {/*  </small>*/}
        {/*</div>*/}
      </ProjectInfo>
      <PostCheckCard>
        <Table>
          <tbody>
            <tr>
              <th>Type</th>
              <td>
                <SimpleSelect<PostMediaOption>
                  {...typeState}
                  options={allPostMediaTypes}
                  getOptionLabel={(v) => v.title}
                  getOptionValue={(v) => v.id}
                  formatValue={toInt}
                />
              </td>
            </tr>
            <tr>
              <th>Category</th>
              <td>
                <SimpleSelect<VideoCategoryType>
                  {...categoryState}
                  options={allVideoCategories}
                  getOptionLabel={(v) => v.name}
                  getOptionValue={(v) => v.id}
                  formatValue={toInt}
                />
              </td>
            </tr>
          </tbody>
        </Table>
        <Buttons>
          <IconButton
            icon={faCheck}
            variant={"is-success"}
            disabled={!isFormComplete || isLoading}
            onClick={() => onUpdate(true, PostCheckStatus.Checked)}
          >
            Causal
          </IconButton>
          <IconButton
            icon={faTimes}
            variant={"is-danger"}
            disabled={isLoading}
            onClick={() => onUpdate(false, PostCheckStatus.Checked)}
          >
            Non-Causal
          </IconButton>
          <IconButton
            icon={faQuestion}
            variant={"is-info"}
            disabled={isLoading}
            onClick={() => onUpdate(null, PostCheckStatus.ReCheck)}
          >
            Unsure
          </IconButton>
        </Buttons>
      </PostCheckCard>
    </>
  );
}

const Table = styled.table`
  margin-bottom: 1.2rem;
  th {
    font-weight: normal;
    padding-right: 16px;
  }
  th,
  td {
    vertical-align: middle;
    padding-top: 4px;
    padding-bottom: 4px;
  }
`;

const Buttons = styled.div`
  button {
    margin-right: 16px;
  }
`;

const ProjectInfo = styled(PostCheckCard)`
  margin-bottom: 1.2rem;
`;

const ProjectName = styled.div`
  font-size: 1.4rem;
`;

export default CheckControls;
