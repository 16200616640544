import React, { useEffect, useMemo, useState } from "react";
import AppPageLayout from "../../layouts/app/AppPageLayout";
import VideoTable from "./table/VideoTable";
import VideoFilter from "./filter/VideoFilter";
import SubmitFilter from "./filter/SubmitFilter";
import {
  useVideoProvider,
  VideoProviderContext,
} from "./context/VideoProviderContext";
import { useVideoColumns } from "./table/columns/getVideoColumns";
import { VideoFilterContext } from "./filter/context/VideoFilterContext";
import { getDefaultFilter } from "./context/persistContextOptions";

interface VideoListProps {
  forcedFilter?: any;
}

function VideoList(props: VideoListProps) {
  const [filter, setFilter] = useState(getDefaultFilter());
  const videoProviderContext = useVideoProvider();
  const columns = useVideoColumns();

  useEffect(() => {
    videoProviderContext.setFilter(finalFilter);
  }, [props.forcedFilter]);

  const finalFilter = useMemo(() => {
    return {
      ...props.forcedFilter,
      ...filter,
    };
  }, [filter, props.forcedFilter]);

  const filterContext = useMemo(
    () => ({
      filter: finalFilter,
      setFilter,
    }),
    [finalFilter]
  );

  return (
    <>
      <VideoFilterContext value={filterContext}>
        <VideoFilter submitButton={<SubmitFilter />} />
      </VideoFilterContext>
      {finalFilter && <VideoTable columns={columns} />}
    </>
  );
}

function VideoListContainer(props: VideoListProps) {
  return (
    <AppPageLayout>
      <VideoProviderContext>
        <VideoList forcedFilter={props.forcedFilter} />
      </VideoProviderContext>
    </AppPageLayout>
  );
}

export default VideoListContainer;
