import React from "react";
import styled from "@emotion/styled";
import AppPageLayout from "../../../layouts/app/AppPageLayout";
import WaitForServer from "../../../components/WaitForServer";
import {
  ProjectSelectionContext,
  useProjectSelection,
} from "../../../elements/context/project/ProjectSelectionContext";
import { ProjectSelectorWithLabel } from "../../../elements/context/project/FloatingProjectSelector";
import Alert from "../../../components/alert/Alert";
import { useLoginState } from "../../../store/login/loginHooks";
import { PdfRoute } from "../../../config/Routes";
import PdfDownloadButton from "../../../modules/pdf/PdfDownloadButton";

interface ReportsScreenProps {}

function ReportsScreen(props: ReportsScreenProps) {
  return (
    <AppPageLayout>
      <WaitForServer>
        <Alert type={"info"}>
          On this page you can download various reports in PDF format. Please
          note that this function is currently still in development and will be
          expanded over time.{" "}
          <u>Ad blockers and popup blockers must be deactivated.</u>
        </Alert>
        <Container>
          <ProjectSelectionContext resolveFromUrl>
            <SelectionRow>
              <ProjectSelectorWithLabel autoSelect />
            </SelectionRow>
            <h2>Generate PDF reports</h2>
            <PdfButtons />
          </ProjectSelectionContext>
        </Container>
      </WaitForServer>
    </AppPageLayout>
  );
}

const PdfButtons = () => {
  const { token } = useLoginState();
  const { selection } = useProjectSelection();
  return (
    <ButtonContainer>
      <PdfDownloadButton
        disabled={!token || !selection}
        variant={"is-primary"}
        title={"Video Overview"}
        route={PdfRoute.Dashboard}
        params={{
          token,
          project: selection.projectIds,
        }}
      />
      <PdfDownloadButton
        disabled={!token || !selection}
        variant={"is-primary"}
        title={"TV Logos"}
        route={PdfRoute.LogoDashboard}
        params={{
          token,
          project: selection.projectIds,
        }}
      />
      <PdfDownloadButton
        disabled={!token || !selection}
        variant={"is-primary"}
        title={"Social Logos"}
        route={PdfRoute.SocialLogoDashboard}
        params={{
          token,
          project: selection.projectIds,
        }}
      />
    </ButtonContainer>
  );
};

const ButtonContainer = styled.div`
  button {
    margin-right: 16px;
  }
`;

const SelectionRow = styled.div`
  margin-bottom: 24px;
`;

const Container = styled.div`
  position: relative;
`;

export default ReportsScreen;
