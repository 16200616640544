import React, { useMemo, useState } from "react";
import styled from "@emotion/styled";
import { addHours } from "~/utils/dateUtils";
import NativeDateInput from "~/components/inputs/date/NativeDateInput";
import Button from "~/components/button/Button";
import { formatDateDiff } from "~/utils/date/formatDateDiff";
import { RangeSlider, RangeState } from "~/components/inputs/range/RangeSlider";
import { useTransformed } from "~/utils/hooks/useTransformed";
import { toDate } from "~/utils/jsUtils";

export interface RecordingSelectState {
  from: Date;
  to: Date;
}

interface RecordingUrlSelectorProps {
  initialValue: RecordingSelectState;
  onSelect: (state: RecordingSelectState) => void;
}

const maxHours = 24;

export function RecordingUrlSelector({
  initialValue,
  onSelect,
}: RecordingUrlSelectorProps) {
  const { min, max, defaultFrom, defaultTo } = useMemo(() => {
    const now = new Date();
    return {
      max: now,
      min: addHours(now, -maxHours),
      defaultFrom: initialValue.from,
      defaultTo: initialValue.to,
    };
  }, [initialValue]);
  const [range, setRange] = useState<RangeState>({
    from: defaultFrom.getTime(),
    to: defaultTo.getTime(),
  });
  const from = useTransformed(range.from, toDate);
  const to = useTransformed(range.to, toDate);

  const { diff } = useMemo(() => {
    return {
      diff: formatDateDiff(to, from),
    };
  }, [from, to]);
  return (
    <div>
      <SliderContainer>
        <RangeSlider
          min={min.getTime()}
          max={max.getTime()}
          value={range}
          onChange={(value) => {
            setRange(value);
          }}
        />
      </SliderContainer>
      <Field>
        <label>From</label>
        <NativeDateInput
          value={from}
          onChange={(date) => {
            setRange((prev) => ({ ...prev, from: date.getTime() }));
          }}
        />
        <label>To</label>
        <NativeDateInput
          value={to}
          onChange={(date) => {
            setRange((prev) => ({ ...prev, to: date.getTime() }));
          }}
        />
        <Button
          onClick={() =>
            onSelect({
              from,
              to,
            })
          }
        >
          Load Video
        </Button>
        <div>{diff}</div>
      </Field>
      {/*<Slider*/}
      {/*  className="slider is-info"*/}
      {/*  step={1000}*/}
      {/*  min={min.getTime()}*/}
      {/*  max={max.getTime()}*/}
      {/*  value={range.from}*/}
      {/*  onChange={(event) => {*/}
      {/*    const time = parseInt(event.currentTarget.value)*/}
      {/*    setRange((prev) => ({ ...prev, from: time }))*/}
      {/*  }}*/}
      {/*  type="range"*/}
      {/*/>*/}
    </div>
  );
}

const Field = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  & > label {
    font-weight: bold;
    margin-right: 12px;
  }
  & > * {
    margin-right: 12px;
  }
  & > input {
    width: 250px;
    max-width: 100%;
  }
`;

const SliderContainer = styled.div`
  width: 960px;
  max-width: 100%;
  margin-top: 24px;
  margin-bottom: 16px;
`;

const Slider = styled.input`
  width: 960px;
  max-width: 100%;
`;
