import React from "react";
import styled from "@emotion/styled";
import TextFilter, { Option } from "../../../../components/filter/TextFilter";
import { Stylable } from "../../../../types/reactTypes";

export interface EbuFilterToggleProps extends Stylable {
  value: boolean;
  onValueChange: (onlyEbu: boolean) => void;
}

function EbuFilterToggle(props: EbuFilterToggleProps) {
  return (
    <Container className={props.className}>
      <TextFilter
        options={options}
        value={props.value ? ebu : all}
        onChange={(value) => {
          props.onValueChange(value === ebu);
        }}
      />
    </Container>
  );
}

const Container = styled.div`
  text-align: right;
`;

const all = "all";
const ebu = "ebu";
const options: Option[] = [
  { label: "all", value: all },
  { label: "EBU members", value: ebu, title: "Show only EBU members" },
];

export default EbuFilterToggle;
