import React from "react";
import { ProjectType } from "../../../@core/types/domain/ProjectType";
import MultiProjectSelector from "./MultiProjectSelector";
import SingleProjectSelector from "./SingleProjectSelector";

export type SelectableProject = Pick<ProjectType, "id" | "name">;

export interface ProjectSelectionProps {
  /** select the first project if no project was selected */
  autoSelect?: boolean;
  multi?: boolean;
  projects?: SelectableProject[];
}

function Switch({ multi, ...props }: ProjectSelectionProps) {
  // TODO REMOVE later
  if (multi) {
    return <MultiProjectSelector {...props} />;
  } else {
    return <SingleProjectSelector {...props} />;
  }
}

export default Switch;
