import React, { memo } from "react";
import styled from "@emotion/styled";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faShareSquare } from "@fortawesome/free-solid-svg-icons";
import Icon from "~/components/icons/Icon";
import { fetchApi, requireOk, toJson } from "~/services/fetchApi";
import { AppRoute } from "~/config/Routes";
import { alertError, alertInfo } from "~/utils/alert/alertUtils";
import { copyToClipboard } from "~/utils/jsUtils";

interface OpenExternalStreamButtonProps {
  streamId: number;
  isNew?: boolean;
  withVideo?: boolean;
  title?: string;
  icon?: IconProp;
  color?: string;
}

export const OpenExternalStreamButton = memo(
  ({
    streamId,
    title,
    icon,
    color,
    isNew,
    withVideo,
  }: OpenExternalStreamButtonProps) => {
    return (
      <ClickableIcon
        icon={icon || faShareSquare}
        title={title}
        color={color}
        onClick={() => {
          return fetchLivestreamCode(streamId)
            .then(async (json) => {
              if (!json.code) throw new Error("missing code");
              function getRoute() {
                if (isNew) {
                  let route = AppRoute.ExternalLiveStreamNew.create(
                    streamId,
                    json.code
                  );
                  if (withVideo) route += "&video=1";
                  return `https://logodetect.com${route}`;
                } else {
                  const route = AppRoute.ExternalLivestream.create(
                    streamId,
                    json.code
                  );
                  return `${window.location.protocol}//${window.location.host}${route}`;
                }
              }
              const url = getRoute();
              try {
                await copyToClipboard(url);
                alertInfo(
                  <>
                    <a href={url} target={"_blank"} rel="noreferrer">
                      Link
                    </a>{" "}
                    copied to clipboard:
                  </>
                );
              } catch (e) {
                alertError("Failed copy to clipboard: " + e?.message);
              }
            })
            .catch((err) => {
              alertError("Failed fetching code: " + err?.message);
            });
        }}
      />
    );
  }
);

function fetchLivestreamCode(streamId: number) {
  return fetchApi(`/livestream/${streamId}/external`, {
    method: "POST",
  })
    .then(requireOk)
    .then<{ code: string }>(toJson);
}

const ClickableIcon = styled(Icon)`
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
`;
