import React, { useState } from "react";
import { BroadcastSignalType } from "@core/types/detection/BroadcastSignalType";
import { useSignals } from "~/screens/app/admin/signals/data/useSignals";
import AppPageLayout from "~/layouts/app/AppPageLayout";
import AppPageSpinner from "~/layouts/app/AppPageSpinner";
import { SignalRecordingView } from "~/screens/app/admin/signals/single/SignalRecordingView";
import SimpleSelect from "~/screens/app/checker/SimpleSelect";

interface SignalMonitorScreenProps {
  signal: string;
}

export function SignalMonitorScreen(props: SignalMonitorScreenProps) {
  const { data: signals } = useSignals();
  if (!signals) return <AppPageSpinner />;
  return <Inner signals={signals} signal={props.signal} />;
}

function Inner({
  signals,
  signal: initialSignal,
}: SignalMonitorScreenProps & { signals: BroadcastSignalType[] }) {
  const [signal, setSignal] = useState(() => {
    const initialSignalId = parseInt(initialSignal);
    return signals.find((s) => s.id === initialSignalId);
  });
  if (!signal) return <AppPageSpinner />;
  return (
    <AppPageLayout>
      <SimpleSelect
        value={signal.id}
        options={signals}
        getOptionLabel={getLabel}
        getOptionValue={getValue}
        onValueChange={(value) => {
          setSignal(signals.find((s) => s.id == value));
        }}
      />
      <SignalRecordingView signal={signal} />
    </AppPageLayout>
  );
}

const getLabel = (s: BroadcastSignalType) => s.name;
const getValue = (s: BroadcastSignalType) => s.id;
