import React, { useMemo } from "react";
import { IdsFilterType } from "../types/IdsFilterType";
import { useProject } from "../../../../store/project/projectHooks";
import { VideoFilterType } from "../types/VideoFilterType";
import { FilterTagProps } from "./FilterTagProps";
import SelectFilter, { FilterOption } from "./select/SelectFilter";

type EventTagFilterProps = FilterTagProps<IdsFilterType>;

function EventTagFilter(props: EventTagFilterProps) {
  const projectId = getProjectId(props.allFilters);
  if (!projectId) return null;
  const project = useProject(projectId);
  const options = useMemo(() => {
    if (!project) return null;
    return project.eventTags.map(
      (value) =>
        ({
          value: value.id,
          label: value.name,
        } as FilterOption)
    );
  }, [project]);
  return <SelectFilter {...props} options={options} />;
}

function getProjectId(filter: VideoFilterType) {
  if (!filter) return null;
  if (!filter.project) return null;
  const inFilter = filter.project.in;
  if (!inFilter) return null;
  const projectId = inFilter[0];
  return projectId || null;
}

export default EventTagFilter;
