import { useMemo } from "react";
import {
  BrandStats,
  DetectionResult,
  VideoBrandStats,
} from "@core/types/detection/DetectionTypes";
import { mergeObjects } from "~/utils/js/transformUtils";
import { sum } from "~/utils/jsUtils";
import {
  DetectionFilter,
  DetectionFilterTarget,
} from "~/screens/app/detection/stats/components/filter/DetectionFilter";
import { mapObject } from "~/utils/js/operationUtils";

export function useDynamicDetectionSummary(
  results: DetectionResult[],
  filter?: DetectionFilter // TODO can be removed?
) {
  return useMemo(() => {
    const totalLength = results && sum(results, (r) => r.length || 0);
    const totalViews = results && sum(results, (r) => r.views || 0);
    const prepared = prepareData(results, filter);
    const summed: VideoBrandStats =
      results && filterToShort(accumulateDynamicResults(prepared));
    return {
      summed,
      totalLength,
      totalViews,
    };
  }, [results, filter]);
}

function filterToShort(summed: VideoBrandStats) {
  // remove brands that have a too few duration
  return (
    summed &&
    Object.entries(summed).reduce((result, [brand, stats]) => {
      const totalDuration = sum(Object.values(stats), (item) => item.duration);
      if (totalDuration > 2) {
        result[brand] = stats;
      }
      return result;
    }, {})
  );
}

function accumulateDynamicResults(results: DetectionResult[]): VideoBrandStats {
  return results?.reduce((summed, result, i) => {
    if (!result) return summed;

    // single video stats
    return mergeObjects(summed, result.logos, (byAnyTarget, byAny) => {
      return mergeObjects(byAnyTarget, byAny, (target, source) => {
        return {
          name: target.name,
          duration: target.duration + source.duration,
          score: target.score + source.score,
          scale: target.scale + source.scale,
          euroValue: target.euroValue + source.euroValue,
        };
      });
    });
  }, {} as VideoBrandStats);
}

function prepareData(results: DetectionResult[], filter?: DetectionFilter) {
  return mapAllStats(results, (stats) => {
    const factor = getDurationFactor(stats, filter);
    return {
      ...stats,
      duration: factor * stats.duration,
      score: factor * stats.score,
    };
  });
}

function mapAllStats(
  results: DetectionResult[],
  transform: (stats: BrandStats) => BrandStats
) {
  return results?.map((result) => {
    return {
      ...result,
      logos: mapObject(result.logos, (byBrand) =>
        mapObject(byBrand, (byAny) => transform(byAny))
      ),
    };
  });
}

function getDurationFactor(stats: BrandStats, filter: DetectionFilter) {
  if (filter.target === DetectionFilterTarget.person) {
    return stats.onPerson || 0;
  } else if (filter.target === DetectionFilterTarget.other) {
    return 1 - (stats.onPerson || 0);
  } else return 1;
}
