import React from "react";
import styled from "@emotion/styled";
import { PostType } from "@core/types/domain/post/PostType";
import { CheckerProject } from "@core/types/domain/ProjectType";
import { GetUncheckedPostsQuery } from "@core/types/api/checkerApiTypes";
import { PostCheckCard } from "~/screens/app/checker/post/PostCheckCard";
import A from "~/components/links/A";
import PlatformIcon from "~/views/videos/PlatformIcon";
import { excerpt } from "~/utils/formatUtils";
import EmbeddedPost from "~/components/embed/EmbeddedPost";
import { CheckStatusCard } from "~/screens/app/checker/post/CheckStatusCard";
import CheckControls from "~/screens/app/checker/post/CheckControls";

interface PostCheckProps {
  project: CheckerProject;
  post: PostType;
  getNext: () => void;
  filter: GetUncheckedPostsQuery;
}

function PostCheck({ post, project, getNext, filter }: PostCheckProps) {
  const title = post.title || excerpt(post.description, 32);

  return (
    <Container>
      <LeftCol>
        <Preview>
          <ChannelInfo>
            <PlatformIcon id={post.platform} />
            <A>{post.channelName}</A>
          </ChannelInfo>
          <Title href={post.url} target={"_blank"} title={post.id?.toString()}>
            {title}
          </Title>
          <PreviewContainer>
            <EmbeddedPost post={post} />
          </PreviewContainer>
          <div>{post.description}</div>
          {/*<img src={post.thumbnail} alt="" />*/}
          <div>
            <small>ID: {post.id}</small>
          </div>
        </Preview>
      </LeftCol>
      <RightCol>
        <CheckControls project={project} post={post} getNext={getNext} />
        <CheckStatusCard filter={filter} />
      </RightCol>
    </Container>
  );
}

const ChannelInfo = styled.div`
  svg {
    margin-right: 4px;
  }
`;

const Title = styled(A)`
  display: block;
  font-weight: bold;
  margin-bottom: 1rem;
`;

const Preview = styled(PostCheckCard)``;

const LeftCol = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 550px;
  max-width: 100%;
`;

const RightCol = styled.div``;

const PreviewContainer = styled.div`
  max-width: 100%;
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  margin: -12px;
  margin-top: 0;
  > div {
    margin: 12px;
  }
`;

export default PostCheck;
