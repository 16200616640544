import React, { memo, useCallback } from "react";
import classNames from "classnames";
import { useVideoProvider } from "../context/VideoProviderContext";
import { useFilterContext } from "./context/VideoFilterContext";

interface SubmitFilterProps {}

function SubmitFilter(props: SubmitFilterProps) {
  const { filter } = useFilterContext();
  const {
    fetchState: { isLoading },
    setState,
  } = useVideoProvider();

  const onClick = useCallback(() => {
    setState((state) => ({
      ...state,
      pagination: {
        ...state.pagination,
        pageIndex: 0,
      },
      filter,
    }));
  }, [filter]);

  return (
    <button
      id={btnApplyFilterId}
      className={classNames("button", "is-primary", "is-rounded", {
        "is-loading": isLoading,
      })}
      onClick={onClick}
    >
      Apply
    </button>
  );
}

export const btnApplyFilterId = "apply-filter";

export default memo(SubmitFilter);
