import React, { useEffect } from "react";
import { navigate } from "gatsby";
import { ProjectSelectionContext } from "../../../elements/context/project/ProjectSelectionContext";
import AppPageLayout from "../../../layouts/app/AppPageLayout";
import {
  FloatingContainer,
  ProjectSelectorWithLabel,
} from "../../../elements/context/project/FloatingProjectSelector";
import { useUserRole } from "../../../store/login/loginHooks";
import { UserRole } from "../../../@core/types/domain/user/UserRole";
import { getDefaultLoginRoute } from "../../login/useDefaultLoginRoutet";
import WaitForServer from "../../../components/WaitForServer";
import Intro from "./Intro";
import DashboardWidgets from "./DashboardWidgets";
import ProjectStatsSection from "./sections/stats/ProjectStatsSection";
import BestVideosSection from "./sections/bestVideos/BestVideosSection";
import ProjectValueSection from "./sections/ProjectValueSection";
import ProjectLiveStreamStats from "./sections/ProjectLiveStreamStats";
import { ProjectFilterProvider } from "./sections/filter/ProjectFilter";
import StateSelector from "./sections/filter/StateSelector";
import VideoFilterWidget from "./filter/VideoFilterWidget";

interface DashboardProps {}

export function Dashboard(props: DashboardProps) {
  const role = useUserRole();
  useEffect(() => {
    if (role === UserRole.Lawyer) {
      const route = getDefaultLoginRoute();
      navigate(route);
    }
  }, [role]);

  return (
    <AppPageLayout>
      <WaitForServer>
        <ProjectSelectionContext resolveFromUrl>
          <ProjectFilterProvider>
            <FloatingContainer>
              <ProjectSelectorWithLabel autoSelect multi />
              <VideoFilterWidget />
              {/*<VideoFilterWidget value={ebu} onValueChange={onValueChange} />*/}
            </FloatingContainer>
            <Intro />
            <DashboardWidgets />
            <StateSelector />
            <ProjectStatsSection />
            <ProjectValueSection />
            <BestVideosSection />
            <ProjectLiveStreamStats />
          </ProjectFilterProvider>
        </ProjectSelectionContext>
      </WaitForServer>
    </AppPageLayout>
  );
}

// const VideoFilterWidget = styled(PostFilterWidget)`
//   margin-top: 8px;
// `
