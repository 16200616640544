import { CellProps, Column } from "react-table";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { ProjectType } from "@core/types/domain/ProjectType";
import { PostType } from "@core/types/domain/post/PostType";
import React, { MouseEventHandler } from "react";
import { tooltip } from "~/utils/misc/tooltipUtils";
import { formatDateTime } from "~/utils/dateUtils";
import PlatformIcon from "~/views/videos/PlatformIcon";
import { PostMediaTypeIcon } from "~/components/domain/post/PostMediaTypeIcon";
import { copyToClipboard } from "~/utils/jsUtils";
import { alertInfo } from "~/utils/alert/alertUtils";
import SafeImage from "~/components/img/SafeImage";
import { formatEuro } from "~/utils/formatUtils";
import { Excerpt } from "~/components/misc/Excerpt";

export function getPostColumns(
  isAdmin: boolean,
  project: ProjectType
): Column<PostType>[] {
  const hasEuroValue = !!project.viewEuroValue;
  const columns: BetterColumn<PostType>[] = [
    // { Header: "#", accessor: "id" },
    {
      Header: "Date",
      accessor: "date",
      Cell: ({ value, row: { original } }: CellProps<any>) => {
        const props = isAdmin && {
          ...tooltip(original.id),
          onClick: copyTooltipToClipboard,
        };
        return (
          <InlineBlockSpan {...props}>{formatDateTime(value)}</InlineBlockSpan>
        );
      },
    },
    {
      Header: "Thumbnail",
      accessor: "thumbnail",
      Cell: ({ value }: CellProps<any>) => <ThumbnailImage src={value} />,
    },
    {
      id: "type",
      Cell: ({ _, row: { original } }: CellProps<any>) => (
        <>
          <div
            css={css`
              margin-bottom: 0.2rem;
            `}
          >
            <PlatformIcon id={original.platform} />
          </div>
          {original.type && (
            <div>
              <PostMediaTypeIcon type={original.type} />
            </div>
          )}
        </>
      ),
    },
    {
      accessor: "title",
      Header: "Title",
      Cell({ value, row: { original } }: CellProps<any>) {
        return (
          <div>
            <a href={original.url} target={"_blank"} rel="noreferrer">
              {value}
            </a>
            <br />
            <Excerpt length={256}>{original.description}</Excerpt>
          </div>
        );
      },
    },
    // {
    //   Header: "Platform",
    //   accessor: "platform",
    //   Cell({ value }) {
    //     return (
    //       <NoBreak>
    //         <PlatformIcon id={value} /> {platformById[value]?.name}
    //       </NoBreak>
    //     )
    //   },
    // },
    {
      Header: "Channel",
      accessor: "channelName",
    },
    {
      Header: "Reach",
      accessor: "reach",
    },
    {
      Header: "Value",
      accessor: "euroValue",
      hide: !hasEuroValue,
      Cell({ value, row: { original } }: CellProps<any>) {
        return <NoWrap>{formatEuro(value)}</NoWrap>;
      },
    },
    // {
    //   Header: "Type",
    //   accessor: "type",
    //   Cell: ({ value }) => <PostMediaTypeIcon type={value} />,
    // },
    // {
    //   Header: "Causal",
    //   accessor: "isCausal",
    //   Cell: CausalCell,
    // },
  ];
  return columns
    .filter((c) => c.hide !== true)
    .map((c) => {
      delete c.hide;
      return c;
    });
}

const copyTooltipToClipboard: MouseEventHandler<any> = (event) => {
  const tooltip = event.currentTarget.dataset["tooltip"];
  if (!tooltip) return;
  copyToClipboard(tooltip);
  alertInfo(`Copied ${tooltip}`);
};

// eslint-disable-next-line @typescript-eslint/ban-types
type BetterColumn<T extends object = {}> = Column<T> & {
  hide?: boolean;
};

const InlineBlockSpan = styled.span`
  display: inline-block;
`;

const NoWrap = styled.span`
  white-space: nowrap;
`;

const ThumbnailImage = styled(SafeImage)`
  object-fit: cover;
  width: 100%;
  height: 60px;
`;
