import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from "@fortawesome/react-fontawesome";
import React from "react";
import styled from "@emotion/styled";
import classNames from "classnames";

interface ClickableIconProps extends FontAwesomeIconProps {
  disabled?: boolean;
  tooltip?: string;
}

/** the real IconButton */
export function ClickableIcon({
  disabled,
  tooltip,
  onClick,
  ...props
}: ClickableIconProps) {
  return (
    <IconWrapper
      className={classNames({
        "has-tooltip-right": !!tooltip,
        disabled: disabled,
      })}
      data-tooltip={tooltip}
      onClick={() => {
        if (disabled) return;
        onClick(undefined);
      }}
    >
      <FontAwesomeIcon {...props} />
    </IconWrapper>
  );
}

const IconWrapper = styled.span`
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;
