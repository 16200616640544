import { useMemo, useState } from "react";

export enum DetectionFilterTarget {
  "all" = "all",
  "person" = "person",
  "other" = "other",
}

export const allTargets = Object.values(DetectionFilterTarget);

export interface DetectionFilter {
  target: DetectionFilterTarget;
  location?: string;
}

const defaultValue: DetectionFilter = {
  target: DetectionFilterTarget.all,
  location: "all",
};

export function useDetectionFilter() {
  const [filter, setFilter] = useState<DetectionFilter>(defaultValue);

  const dispatches = useMemo(() => {
    return {
      setTarget: (target: DetectionFilterTarget) => {
        setFilter((f) => ({ ...f, target }));
      },
    };
  }, []);

  return useMemo(
    () => ({
      filter,
      setFilter,
    }),
    [filter]
  );
}
