import React, { memo } from "react";
import { z } from "~/utils/dateUtils";

interface NativeDateInputProps {
  value: Date;
  onChange: (date: Date) => void;
}

function NativeDateInput({ value, onChange }: NativeDateInputProps) {
  return (
    <input
      className={"input"}
      type="datetime-local"
      value={toInputDate(value)}
      onChange={(event) => {
        const { value } = event.currentTarget;
        onChange(value ? new Date(value) : null);
      }}
    />
  );
}

function toInputDate(d: Date) {
  // target YYYY-MM-DDThh:mm:ss.ms
  const year = `${d.getFullYear()}-${z(d.getMonth() + 1)}-${z(d.getDate())}`;
  const hour = `${z(d.getHours())}:${z(d.getMinutes())}:${z(d.getSeconds())}`;
  return `${year}T${hour}`;
}

export default memo(NativeDateInput);
