import { ComponentClass, FunctionComponent } from "react";
import TextFilter from "../active/TextFilter";
import DateFilter from "../active/date/DateFilter";
import StatusFilter from "../test/StatusFilter";
import ProjectFilter from "../active/ProjectFilter";
import PlatformFilter from "../active/PlatformFilter";
import CustomStatusFilter from "../test/CustomStatusFilter";
import StaticFilter from "../active/StaticFilter";
import CategoryFilter from "../test/CategoryFilter";
import EventTagFilter from "../active/EventTagFilter";
import { FilterableFieldNames } from "./videoFields";

export const filterComponentsByType: {
  [s in FilterableFieldNames]: FunctionComponent<any> | ComponentClass<any>;
} = {
  project: ProjectFilter,
  title: TextFilter,
  channelName: TextFilter,
  description: TextFilter,
  status: StatusFilter,
  customStatus: CustomStatusFilter,
  category: CategoryFilter,
  publishAt: DateFilter,
  platform: PlatformFilter,
  eventTag: EventTagFilter,
  hasComments: StaticFilter,
  hasNote: StaticFilter,
  isEbuMember: StaticFilter,
};
