import React from "react";
import styled from "@emotion/styled";
import AppPageLayout from "~/layouts/app/AppPageLayout";
import { ProjectSelectionContext } from "~/elements/context/project/ProjectSelectionContext";
import { ProjectSelectorWithLabel } from "~/elements/context/project/FloatingProjectSelector";
import { FootageList } from "~/screens/app/footage/FootageList";
import { FootageUploadUppy } from "~/screens/app/footage/FootageUploadUppy";

export function FootageScreen() {
  return (
    <AppPageLayout>
      <ProjectSelectionContext resolveFromUrl>
        <SelectionRow>
          <ProjectSelectorWithLabel autoSelect />
        </SelectionRow>
        <FootageUploadUppy />
        <FootageList />
      </ProjectSelectionContext>
    </AppPageLayout>
  );
}

const SelectionRow = styled.div`
  margin-bottom: 24px;
`;
