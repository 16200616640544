import React from "react";
import styled from "@emotion/styled";
import { PieChartFilterState } from "~/screens/app/detection/charts/filter/PieChartFilterState";
import { criteriaOptions } from "~/screens/app/detection/charts/filter/CriteriaOption";

interface ChartExplainProps {
  filter: PieChartFilterState;
}

export function ChartExplain(props: ChartExplainProps) {
  const criteria = criteriaOptions[props.filter.criteria];
  return (
    <Container>
      <Title>{criteria.title}</Title>
      <p>{criteria.explanation}</p>
    </Container>
  );
}

const Container = styled.div`
  padding-top: 2rem;
`;

const Title = styled.div`
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 0.2em;
`;
