import React, { useCallback, useEffect, useMemo } from "react";
import { ProjectType } from "@core/types/domain/ProjectType";
import SelectDropdown from "../../selectdropdown/SelectDropdown";
import { useProjectSelection } from "./ProjectSelectionContext";
import { ProjectSelectionProps } from "./ProjectSelector";
import { useProjects } from "~/store/project/projectHooks";
import { notNull } from "~/utils/jsUtils";

function MultiProjectSelector({ autoSelect, multi }: ProjectSelectionProps) {
  const projects = useProjects();
  const { selection, setSelection } = useProjectSelection();

  const loading = !projects?.length;

  useEffect(() => {
    /* select the first project if no project was selected */
    if (autoSelect && selection.projectIds.length === 0 && projects?.[0]) {
      setSelection({
        projectIds: [projects[0].id],
      });
    }
  }, [projects, selection]);

  const onChange = useCallback((projects: ProjectType[]) => {
    setSelection({
      projectIds: projects.map((p) => p.id),
    });
  }, []);

  const values = useMemo(() => {
    if (!projects) return undefined;
    return selection.projectIds
      .map((id) => projects.find((p) => p.id === id))
      .filter(notNull);
  }, [projects, selection.projectIds, multi]);

  return (
    <SelectDropdown
      values={values}
      options={projects}
      onChange={onChange}
      getOptionLabel={getProjectLabel}
      getOptionValue={getProjectValue}
      formatTitle={formatTitle}
      isLoading={loading}
      keys={keys}
      singleValue={multi === false}
    />
  );
}

const keys = ["name"];

const formatTitle = (projects: ProjectType[]) => {
  if (!projects?.length) return "Select a project";
  if (projects.length === 1) {
    return projects[0].name;
  } else {
    return `${projects.length} projects selected`;
  }
};

const getProjectLabel = (project: ProjectType) => project.name;
const getProjectValue = (project: ProjectType) => {
  return project.id.toString();
};

export default MultiProjectSelector;
