import React, { createRef, useEffect } from "react";
import Embedo from "embedo";
import { fbAppId } from "../../config";

interface EmbedoViewProps {
  url: string;
}

function EmbedoView({ url }: EmbedoViewProps) {
  const container = createRef<HTMLDivElement>();
  useEffect(() => {
    const embedo = new Embedo({
      facebook: {
        appId: fbAppId,
        version: "v6.0",
      },
      twitter: true,
      instagram: true,
    });

    container.current.innerHTML = null;

    embedo.load(container.current, url, {
      centerize: false,
      strict: true,
    });
  }, [url]);
  return <div ref={container} />;
}

export default EmbedoView;
