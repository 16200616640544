import React, { memo } from "react";
import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { formatDateTime } from "../../../../utils/dateUtils";
import { VideoCommentType } from "../../../../@core/types/domain/video/VideoCommentType";

interface CommentProps {
  comment: VideoCommentType;
  onDelete: (commentId: number) => void;
}

function Comment(props: CommentProps) {
  const { onDelete } = props;
  const { id, createdAt, message, userName } = props.comment;
  return (
    <Container>
      <span>{message}</span>
      <Date>
        <i>{formatDateTime(createdAt)}</i> {userName && `– ${userName}`}
      </Date>
      <Actions>
        {onDelete && (
          <IconButton title={"Remove"} onClick={() => onDelete(id)}>
            <FontAwesomeIcon icon={faTimesCircle} fixedWidth />
          </IconButton>
        )}
      </Actions>
    </Container>
  );
}

const Date = styled.small`
  display: block;
  text-align: right;
  opacity: 0.8;
`;

const Container = styled.div`
  background-color: white;
  padding: 10px 14px;
  margin-bottom: 12px;
  border: 1px solid #e6e6e6;
  position: relative;
`;

const Actions = styled.div`
  background-color: white;
  position: absolute;
  top: 0;
  right: 0;

  display: none;
  ${Container}:hover & {
    display: inherit;
  }
`;

const IconButton = styled.div`
  cursor: pointer;
  display: inline-block;
  padding: 7px;
  &:hover {
    opacity: 0.7;
  }
`;

export default memo(Comment);
