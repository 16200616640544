import React from "react";
import styled from "@emotion/styled";
import { WithChildren } from "../../../types/reactTypes";

type TableCardHeaderProps = WithChildren;

function TableCardHeader(props: TableCardHeaderProps) {
  return <TableCardHeaderContainer>{props.children}</TableCardHeaderContainer>;
}

const TableCardHeaderContainer = styled.div`
  padding-right: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  align-items: center;
  > div {
    margin-left: 12px;
  }
`;

export default TableCardHeader;
