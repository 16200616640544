import { VideoFilterType } from "../../views/videos/filter/types/VideoFilterType";
import { fetchApi, toJson } from "../fetchApi";

export interface PredefinedFilter {
  id: number;
  label: string;
  filter: VideoFilterType;
}

interface PredefinedFilterJson {
  id: number;
  project_id: number;
  name: string;
  json: string;
}

export function fetchPredefinedFilters(
  projectId: string | number
): Promise<PredefinedFilter[]> {
  return fetchApi(`/project/${projectId}/filter`)
    .then(toJson)
    .then((json: PredefinedFilterJson[]) => {
      return (
        json &&
        json.map(
          (value) =>
            ({
              id: value.id,
              label: value.name,
              filter: JSON.parse(value.json),
            } as PredefinedFilter)
        )
      );
    });
}
