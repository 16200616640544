import { BroadcastSignalType } from "@core/types/detection/BroadcastSignalType";

export function getSignalRecordingUrl(
  signal: BroadcastSignalType,
  from: Date,
  to?: Date
) {
  const fromTs = getTimestamp(from);
  const toTs = to ? getTimestamp(to) - fromTs : "now";
  return `${signal.streamUrl}/playlist_dvr_range-${fromTs}-${toTs}.m3u8`;
}

export function getSignalRecordingDownloadUrl(
  signal: BroadcastSignalType,
  from: Date,
  to: Date
) {
  if (!signal.downloadUrl) return undefined;
  const fromTs = getTimestamp(from);
  const toTs = getTimestamp(to);
  return `${signal.downloadUrl}?start=${fromTs}&end=${toTs}`;
}

function getTimestamp(date?: Date) {
  if (!date) return undefined;
  return Math.floor(date.getTime() / 1000);
}
