import React, { memo } from "react";
import { WithChildren } from "../../../types/reactTypes";
import Dropdown, { DropdownLink } from "./Dropdown";

export interface DropdownMenuOption<T> {
  label: string;
  value: T;
}

interface DropdownMenuProps<T = number> extends WithChildren {
  isOpened?: boolean;
  onChange?: (option: DropdownMenuOption<T>) => void;
  options: DropdownMenuOption<T>[];
}

function DropdownMenu(props: DropdownMenuProps) {
  const popup = (
    <div>
      {props.options.map((option) => (
        <DropdownLink
          key={option.value}
          onClick={() => {
            props.onChange(option);
          }}
          data-value={option.value}
        >
          {option.label}
        </DropdownLink>
      ))}
    </div>
  );

  return (
    <Dropdown popup={popup} isOpened={props.isOpened}>
      {props.children}
    </Dropdown>
  );
}

export default memo(DropdownMenu);
