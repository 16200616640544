import React, { memo, useEffect } from "react";
import { usePagination, useSortBy, useTable } from "react-table";
import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLongArrowAltDown,
  faLongArrowAltUp,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import { EMPTY_ARRAY } from "../../../utils/jsUtils";
import TableCard from "../../../components/card/TableCard";
import { useVideoProvider } from "../context/VideoProviderContext";
import TableCardHeader from "./TableCardHeader";
import PageSelector from "./PageSelector";
import PageSizeSelector from "./PageSizeSelector";

export interface VideoTableProps {
  columns: any;
}

const sortUp = <FontAwesomeIcon icon={faLongArrowAltUp} />;
const sortDown = <FontAwesomeIcon icon={faLongArrowAltDown} />;

function VideoTable(props: VideoTableProps) {
  const { columns } = props;

  const {
    filter,
    fetchState: { data, isLoading },
    pagination: { pageIndex, pageSize },
    setPagination,
    requestVideos,
  } = useVideoProvider();

  const videos = (data && data.results) || EMPTY_ARRAY;
  const numberOfPages = data && data.pages;

  // const tableState = useTableState({
  //   sortBy: [
  //     {
  //       id: "publishAt",
  //     },
  //   ],
  //   pageSize,
  //   pageIndex,
  //   manualPagination: true,
  // })

  const instance = useTable<any>(
    {
      columns,
      data: videos,
    },
    useSortBy,
    usePagination
  );

  const { getTableProps, headerGroups, rows, prepareRow } = instance;

  // TODO memo this?
  const canNextPage = pageIndex < numberOfPages - 1;
  const nextPage = () => {
    if (!canNextPage) return;
    setPagination({ pageIndex: pageIndex + 1 });
  };
  const canPreviousPage = pageIndex > 0;
  const previousPage = () => {
    setPagination({ pageIndex: pageIndex - 1 });
  };
  const setPageSize = (pageSize: number) => {
    setPagination({ pageSize, pageIndex: 0 });
  };
  const gotoPage = (page: number) => {
    if (page < 0) return false;
    if (page > numberOfPages - 1) return false;
    setPagination({ pageIndex: page });
    return true;
  };

  useEffect(() => {
    if (!filter) return;
    requestVideos();
  }, [filter, pageIndex, pageSize]);

  return (
    <TableCard
      title={"Videos"}
      icon={faVideo}
      header={
        <TableCardHeader>
          <PageSelector
            pageIndex={pageIndex}
            nextPage={nextPage}
            previousPage={previousPage}
            canNextPage={canNextPage}
            canPreviousPage={canPreviousPage}
            gotoPage={gotoPage}
            numberOfPages={numberOfPages}
          />
          <PageSizeSelector pageSize={pageSize} setPageSize={setPageSize} />
        </TableCardHeader>
      }
      loading={isLoading}
    >
      <TableContainer>
        <StyledTable
          {...getTableProps()}
          className={"table is-striped is-hoverable"}
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {
                  return (
                    <th
                      key={column.id}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className={`cell-${column.id}`}
                    >
                      {column.render("Header")}
                      <ArrowContainer>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? sortUp
                            : sortDown
                          : ""}
                      </ArrowContainer>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr key={row.id} {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        key={cell.column.id}
                        {...cell.getCellProps()}
                        className={`cell-${cell.column.id}`}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </StyledTable>
      </TableContainer>
    </TableCard>
  );
}

const TableContainer = styled.div`
  max-width: 100%;
  width: 100%;
`;

const StyledTable = styled.table`
  width: 100%;

  td,
  th {
    width: auto;
    &.cell-thumbnail {
      min-width: 200px;
    }
    &.cell-title {
      min-width: 150px;
      word-break: break-word;
    }
    &.cell-actions {
      width: 1px;
      padding-right: 24px;
    }
    &.cell-customStatus {
      min-width: 125px;
    }
  }

  tr:nth-of-type(odd) {
    td {
      background-color: white;
    }
  }

  tr td,
  tr th {
    padding: 14px;

    &:first-of-type {
      padding-left: 8px;
    }
  }
`;

const ArrowContainer = styled.span`
  svg {
    margin-left: 6px;
  }
`;

export default memo(VideoTable);
