import React from "react";
import { useDebugOptions } from "../../../store/debug/debugHooks";
import { VideoFilterType } from "./types/VideoFilterType";

interface VideoFilterDebugProps {
  filter?: VideoFilterType;
}

function VideoFilterDebug(props: VideoFilterDebugProps) {
  const debugOptions = useDebugOptions();
  return (
    <div>
      {debugOptions.general && (
        <pre>{JSON.stringify(props.filter, null, 2)}</pre>
      )}
      {debugOptions.general && <pre>{JSON.stringify(props.filter, null)}</pre>}
    </div>
  );
}

export default VideoFilterDebug;
