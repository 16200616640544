export function formatDateDiff(from: Date, to: Date) {
  let diff = Math.abs(from.getTime() - to.getTime());
  const ms = diff % 1000;
  diff = (diff - ms) / 1000;
  const seconds = diff % 60;
  diff = (diff - seconds) / 60;
  const minutes = diff % 60;
  diff = (diff - minutes) / 60;
  const hours = diff % 24;
  const days = (diff - hours) / 24;

  const items: Array<[number, string]> = [];

  if (days) items.push([days, "d"]);
  if (hours) items.push([hours, "h"]);
  if (minutes) items.push([minutes, "m"]);

  return items
    .map(([amount, unit]) => {
      return amount + unit;
    })
    .join(" ");
}
