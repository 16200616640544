import styled from "@emotion/styled";
import React, { useEffect, useRef, useState } from "react";
import { VideoImport } from "~/screens/admin/videoimport/VideoImport";
import { fetchVideoImport } from "~/screens/admin/videoimport/fetchVideoImport";
import { ApiFetchError } from "~/services/utils/ApiFetchError";

interface VideoImportTableProps {
  imports: VideoImport[];
}

export function VideoImportTable({ imports }: VideoImportTableProps) {
  const [active, setActive] = useState<VideoImportWithStatus[]>([]);

  useEffect(() => {
    if (active.length) return;
    const next: VideoImportWithStatus[] = [];
    imports.forEach((v) => {
      if (next.find((n) => n.url === v.url)) return;
      next.push({
        ...v,
        status: "pending",
      });
    });
    setActive(next);
  }, [imports]);

  const $active = useRef(active);
  useEffect(() => {
    $active.current = active;
  }, [active]);

  useEffect(() => {
    function updateVideo(
      video: VideoImportWithStatus,
      update: Partial<VideoImportWithStatus>
    ) {
      setActive((prev) => {
        return prev.map((v) => {
          if (v.url !== video.url) return v;
          return {
            ...v,
            ...update,
          };
        });
      });
    }
    const job = setInterval(async () => {
      const isLoading = $active.current.find((i) => i.status === "loading");
      if (isLoading) return;
      const next = $active.current.find((i) => i.status === "pending");
      if (!next) {
        clearInterval(job);
        console.log("done");
        return;
      }
      updateVideo(next, {
        status: "loading",
        text: undefined,
        error: undefined,
      });
      fetchVideoImport(next.url, next.projectId)
        .then((text) => {
          updateVideo(next, { status: "success", text: text });
        })
        .catch((error: ApiFetchError | any) => {
          updateVideo(next, {
            status: "error",
            error: error.toString(),
            text: undefined,
          });
        });
    }, 1000);
    return () => clearInterval(job);
  }, []);

  if (!active.length) return null;
  return (
    <Table>
      <thead>
        <tr>
          <th>Projekt</th>
          <th>URL</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        {active.map((v) => (
          <tr key={v.url}>
            <td>{v.projectId}</td>
            <td>{v.url}</td>
            <td>{v.error ? "Error: " + v.error : v.text || v.status}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

const Table = styled.table`
  th,
  td {
    padding-right: 0.5em;
  }
`;

export interface VideoImportWithStatus extends VideoImport {
  status: "pending" | "loading" | "success" | "error";
  error?: string;
  text?: string;
}
