import React, { useMemo } from "react";
import styled from "@emotion/styled";
import { useWindowUrl } from "~/utils/hooks/useWindowUrl";

interface SwapEnvButtonProps {}

export function SwapEnvButton(props: SwapEnvButtonProps) {
  if (process.env.NODE_ENV !== "development") return null;
  const currentUrl = useWindowUrl();
  const nextUrl = useMemo(() => {
    return currentUrl?.replace(
      "http://localhost:8000/",
      "https://report.onlinecontrol.eu/"
    );
  }, [currentUrl]);
  return (
    <Wrapper>
      <a href={nextUrl}>Live</a>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-right: 1em;
`;
