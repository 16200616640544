import React, { useCallback, useMemo } from "react";
import styled from "@emotion/styled";
import { useTaskInfo } from "../../../../services/detection/detectionService";
import { WebRtcVideo } from "../../../../modules/webrtc/WebRtcVideo";
import { useLiveDetection } from "../../../../services/detection/fetchLiveDetection";
import Notification from "../../../../components/notification/Notification";
import { ApiFetchError } from "../../../../services/utils/ApiFetchError";
import { DebugInfo } from "../../../../components/misc/Debug";
import { fetchStopTask } from "../../../../services/detection/fetchStopTask";
import { SocketStats } from "./SocketStats";

interface LiveStreamViewProps {
  id: string;
  url: string;
}

export function LiveStreamView({ id, url }: LiveStreamViewProps) {
  const createResult = useLiveDetection(id, url);
  const createError = createResult.error as ApiFetchError;

  const isSuccess = createResult.isSuccess || createError?.status === 409;
  const { data: info } = useTaskInfo(isSuccess && id);

  const isRunning = info?.status === "init" || info?.status === "working";

  const webRtcUrl = useMemo(() => {
    return isRunning && info.webrtcUrl;
  }, [isRunning, info]);

  const stopTask = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      if (!info) return;
      fetchStopTask(info.id).then(console.log).catch(console.error);
    },
    [info]
  );

  const error = useMemo(() => {
    if (!createError) return null;
    // TODO read statusText
    if (createError?.status === 409) {
      return (
        <Notification type={"danger"}>
          The job could not be started because a livestream is already running.
          It is now displayed.
          {isRunning && (
            <span>
              {" "}
              You can also <a onClick={stopTask}>stop the active job.</a>{" "}
            </span>
          )}
        </Notification>
      );
    } else {
      return (
        <Notification type={"danger"}>
          The job could not be started.
        </Notification>
      );
    }
  }, [createError, info]);

  return (
    <div>
      <DebugInfo title={"Task info"} value={info} />
      {error}
      <View>
        <div>{webRtcUrl && <WebRtcVideo url={webRtcUrl} />}</div>
        <SocketStats
          id={id}
          active={isRunning}
          server={info.server}
          task={info}
        />
      </View>
    </div>
  );
}

const View = styled.div`
  display: flex;
  margin: -24px;
  & > div {
    padding: 24px;
    flex: 1;
  }
`;
