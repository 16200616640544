import { useEffect, useMemo, useState } from "react";
import {
  queryPersistRange,
  resolvePeristedRanges,
} from "~/screens/app/detection/stream/videorange/range/queryPersistRange";

export type UseVideoRanges = ReturnType<typeof useVideoRanges>;

export function useVideoRangeState(
  initialValue: RenderVideoRange[] | (() => RenderVideoRange[]) = []
) {
  const [ranges, setRanges] = useState<RenderVideoRange[]>(initialValue);
  return useMemo(() => {
    return {
      ranges,
      setRanges: (next: VideoRange[]) => {
        setRanges(prepareInitialValues(next));
      },
      updateRange: (id: number, update: Partial<VideoRange>) => {
        setRanges((prev) =>
          prev.map((r) => {
            if (id === r.id) {
              return {
                ...r,
                ...update,
              };
            } else return r;
          })
        );
      },
      deleteRange: (id: number) => {
        setRanges((prev) => prev.filter((r) => r.id !== id));
      },
      addRange: (from = 0, to = 0) => {
        setRanges((prev) => {
          const nextId = Math.max(...prev.map((i) => i.id)) + 1;
          return [
            ...prev,
            {
              id: nextId,
              from,
              to,
            },
          ];
        });
      },
    };
  }, [ranges]);
}

interface UseVideoRangesArgs {
  resolveFromUrl2?: boolean;
}

function getInitialValue(args?: UseVideoRangesArgs) {
  const resolved = resolvePeristedRanges();
  if (resolved?.length) {
    return prepareInitialValues(resolved);
  } else {
    return initialValue;
  }
}

export function useVideoRanges() {
  const state = useVideoRangeState(getInitialValue);
  useEffect(() => {
    queryPersistRange(state.ranges);
  }, [state.ranges]);
  return state;
}

const initialValue = prepareInitialValues([{ from: 0, to: 0 }]);

function prepareInitialValues(ranges: VideoRange[]): RenderVideoRange[] {
  return ranges.map((r, i) => ({
    ...r,
    id: i + 1,
  }));
}

export interface VideoRange {
  from: number;
  to: number;
}

interface RenderVideoRange extends VideoRange {
  id: number;
}
