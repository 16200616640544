import { fetchApi, requireOk } from "~/services/fetchApi";
import { sleep, withQueryParams } from "~/utils/jsUtils";

export function fetchVideoImport(
  url: string,
  projectId: number
): Promise<string | undefined> {
  if (url.includes("//twitter.com/") || url.includes("//www.instagram.com/")) {
    return fetchByServer(url, projectId);
  } else if (url.includes("facebook.com/")) {
    return resolveByChromeExtension(url, projectId).then(async () => {
      await sleep(1000);
      return "External Import (unknown status)";
    });
  } else {
    return Promise.reject(new Error("unknown url"));
  }
}

function resolveByChromeExtension(url: string, projectId: number) {
  return new Promise<void>((resolve, reject) => {
    url = tranformFbLink(url);
    const tab = window.open(
      withQueryParams(url, {
        autoImport: 1,
        projectId: process.env.NODE_ENV === "development" ? -1 : projectId,
      }),
      "_blank"
    );
    const task = setInterval(() => {
      if (tab.closed) {
        clearInterval(task);
        resolve();
      }
    }, 1000);
  });
}

function tranformFbLink(url: string): string {
  // transform https://www.facebook.com/ECMunich2022/videos/606800297644035
  // to https://www.facebook.com/watch/?v=331982159123870

  const basePattern = "https://www.facebook.com/";
  const videoPattern = /\/videos\/(\d+)/;

  const match = url.match(videoPattern);
  if (!match) return url; // Wenn das Muster nicht gefunden wird, geben Sie die ursprüngliche URL zurück.

  const videoId = match[1];
  return `${basePattern}watch/?v=${videoId}`;
}

function fetchByServer(url: string, projectId: number) {
  return fetchApi("/import/video/v3", {
    method: "POST",
    body: JSON.stringify({
      url,
      projectId,
    }),
  })
    .then(requireOk)
    .then(() => undefined);
}
