import React from "react";
import styled from "@emotion/styled";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faThumbsUp, faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PostType } from "../../../../@core/types/domain/post/PostType";
import A from "../../../../components/links/A";
import PlatformIcon from "../../../../views/videos/PlatformIcon";
import { formatDateTime } from "../../../../utils/dateUtils";
import {
  DateInfo,
  DateRow,
  PlatformInfo,
  Title,
} from "../../dashboard/sections/bestVideos/BestVideo";
import { formatNumber } from "../../../../utils/formatUtils";

interface TopPostProps {
  post: PostType;
}

function TopPost({ post }: TopPostProps) {
  return (
    <Container>
      <img src={post.thumbnail} alt="" />
      <InfoContainer>
        <A href={post.url} target={"_blank"}>
          <Title title={post.title}>{post.title}</Title>
        </A>
        <DateRow>
          <PlatformInfo>
            <A target={"_blank"}>
              <PlatformIcon id={post.platform} />
              <span title={post.channelName}>{post.channelName}</span>
            </A>
          </PlatformInfo>
          <DateInfo>{formatDateTime(post.date)}</DateInfo>
        </DateRow>
        <StatsRow>
          <StatsInfo label={"Reach"} value={post.reach} icon={faUsers} />
          <StatsInfo
            label={"Engagement"}
            value={post.reactions}
            icon={faThumbsUp}
          />
        </StatsRow>
      </InfoContainer>
    </Container>
  );
}

const StatsInfo = (props: { label: string; value: number; icon: IconProp }) => (
  <StatsContainer title={props.label}>
    <FontAwesomeIcon icon={props.icon} fixedWidth />
    <div>{formatNumber(props.value)}</div>
  </StatsContainer>
);

const StatsRow = styled.div`
  display: flex;
`;

const StatsContainer = styled.div`
  margin-right: 10px;
  display: flex;
  align-items: center;
  svg {
    margin-right: 4px;
  }
`;

const InfoContainer = styled.div`
  padding: 4px;
`;

const Container = styled.div`
  position: relative;
  font-size: 12px;
  img {
    width: 100%;
    display: block;
  }
`;

export default TopPost;
