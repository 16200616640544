import { useQuery } from "react-query";
import { UseQueryOptions } from "react-query/types/react/types";
import { GetUncheckedPostsQuery } from "@core/types/api/checkerApiTypes";
import { PostType } from "@core/types/domain/post/PostType";
import { CheckerProject } from "@core/types/domain/ProjectType";
import {
  CheckStatus,
  fetchCheckerProjects,
  fetchCheckStatus,
  fetchUncheckedPosts,
} from "./checkerService";

export function useCheckerProjects() {
  return useQuery<CheckerProject[]>(
    "useCheckerProjects",
    () => fetchCheckerProjects(),
    {
      retry: false,
    }
  );
}

export function useCheckerPosts(
  filter: GetUncheckedPostsQuery,
  options?: UseQueryOptions<any>
) {
  return useQuery<PostType[]>(
    ["useCheckerPosts", "a"],
    () => fetchUncheckedPosts(filter),
    { retry: false, enabled: !!filter, ...options }
  );
}

export function useCheckStatus(filter: GetUncheckedPostsQuery) {
  return useQuery<CheckStatus>(["useCheckStatus", filter], () =>
    fetchCheckStatus(filter)
  );
}
