import React from "react";
import SelectFilter, { FilterOption } from "../active/select/SelectFilter";
import { FilterTagProps } from "../active/FilterTagProps";
import { IdsFilterType } from "../types/IdsFilterType";
import { allVideoCustomStatus } from "../../../../@core/types/domain/video/VideoCustomStatus";

type CustomStatusFilterProps = FilterTagProps<IdsFilterType>;

function CustomStatusFilter(props: CustomStatusFilterProps) {
  return <SelectFilter {...props} options={options} />;
}

const options: FilterOption[] = allVideoCustomStatus.map((platform) => ({
  value: "" + platform.id,
  label: platform.name,
}));

export default CustomStatusFilter;
