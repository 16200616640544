import React, { useCallback, useEffect, useState } from "react";
import { RouteComponentProps } from "@reach/router";
import styled from "@emotion/styled";
import { toast } from "react-toastify";
import { StreamInstruction } from "@core/types/domain/stream/StreamInstruction";
import { toInt } from "~/utils/jsUtils";
import {
  fetchUpdateLivestream,
  invalidateLivestream,
  useLivestream,
} from "~/screens/app/detection/livestreams/data/useLivestreams";
import AppPageSpinner from "~/layouts/app/AppPageSpinner";
import { TextInput } from "~/components/inputs/text/TextInput";
import Button from "~/components/button/Button";
import Explain from "~/components/bulma/Explain";

interface StreamAdminScreenProps extends RouteComponentProps {
  streamId: string;
}

export function StreamAdminScreen(props: StreamAdminScreenProps) {
  const streamId = toInt(props.streamId);
  const { data: stream, isLoading: l1 } = useLivestream(streamId);
  const instructions = stream?.liveInstructions;
  const [next, setNext] = useState<StreamInstruction>(undefined);
  const [fetching, setFetching] = useState(false);
  const updateInstructions = useCallback(() => {
    setFetching(true);
    fetchUpdateLivestream(streamId, {
      liveInstructions: {
        ...instructions,
        ...next,
      },
    })
      .then((next) => {
        invalidateLivestream(streamId);
        toast.success("Updated");
      })
      .catch((err) => {
        toast.error("Update failed");
      })
      .finally(() => {
        setFetching(false);
      });
  }, [streamId, next]);
  useEffect(() => {
    if (!instructions) return;
    setNext(instructions || {});
  }, [instructions]);
  if (l1) return <AppPageSpinner />;
  if (!stream) return null;
  return (
    <Container>
      <h1>
        #{stream.id} {stream.title}
      </h1>
      <div className="field">
        <label className="label">
          Redirect URL
          <Explain>All viewers will be redirected to this URL</Explain>
        </label>
        <div className="control">
          <TextInput
            title={"Redirect URL"}
            value={next?.redirect || ""}
            onChange={(event) => {
              const value = event.target.value;
              setNext((prev) => {
                return { ...prev, redirect: value || null };
              });
            }}
            placeholder={"https://google.de"}
          />
        </div>
      </div>
      <Button
        variant={"is-link"}
        loading={fetching}
        onClick={updateInstructions}
      >
        Save
      </Button>
      {/*<pre>{JSON.stringify(instructions, null, 2)}</pre>*/}
      {/*<pre>{JSON.stringify(next, null, 2)}</pre>*/}
    </Container>
  );
}

const Container = styled.div`
  input {
    max-width: 420px;
  }
`;
