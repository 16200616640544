import React, { ComponentClass, FunctionComponent, useState } from "react";
import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import { WithChildren } from "../../../../types/reactTypes";
import { useRemoveFilter } from "../context/VideoFilterContext";
import { DropdownContent } from "../../../../components/inputs/dropdown/Dropdown";
import { FilterTagProps } from "./FilterTagProps";

interface ActiveFilterProps extends WithChildren, FilterTagProps<any> {
  popup?: React.ReactNode;
  popupWrapper?: FunctionComponent<any> | ComponentClass<any>;
  invalid?: boolean;
  unremovable?: boolean;
}

function ActiveFilter(props: ActiveFilterProps) {
  const removeFilter = useRemoveFilter();
  const [showDropdown, setShowDropdown] = useState(false);
  const DropdownWrapper = props.popupWrapper || DropdownContent;
  return (
    <Container
      className={classNames("dropdown", {
        "is-hoverable": props.popup,
        "is-invalid": props.invalid,
      })}
    >
      <div
        className="dropdown-trigger"
        onClick={() => {
          setShowDropdown(!showDropdown);
        }}
      >
        <span>{props.children}</span>
        {!props.unremovable && (
          <RemoveButton onClick={() => removeFilter(props.field.name)}>
            <FontAwesomeIcon icon={faTimes} color={"gray"} size={"xs"} />
          </RemoveButton>
        )}
      </div>
      {props.popup && <DropdownWrapper>{props.popup}</DropdownWrapper>}
    </Container>
  );
}

const containerSidePadding = 12;
const removeSitePadding = 4;
const RemoveButton = styled.span`
  cursor: pointer;
  padding-left: ${removeSitePadding}px;
  margin-left: ${containerSidePadding - removeSitePadding}px;
  padding-right: ${removeSitePadding}px;
  // margin-right: ${containerSidePadding - removeSitePadding}px;
  &:hover {
    opacity: 0.7;
  }
`;

const Container = styled.div`
  background-color: #f2f3f5;
  // padding: 5px ${containerSidePadding}px 5px ${containerSidePadding}px;

  border-radius: 100px;
  padding: calc(0.5em - 1px) calc(1em + 0.25em);
}

  &.is-hoverable .dropdown-trigger {
    cursor: pointer;
  }

  &.is-invalid {
    background-color: #ffcbcb;
  }
`;

export default ActiveFilter;
