import React, { useMemo } from "react";
import styled from "@emotion/styled";
import { LiveStream } from "@core/types/domain/Livestream";
import { useProjectLiveStreams } from "../useProjectLiveStreams";
import { DebugDetectionPage } from "./DebugDetectionPage";
import { AppRoute } from "~/config/Routes";

interface LogoLiveStreamScreenProps {}

function DebugDetectionScreen(props: LogoLiveStreamScreenProps) {
  const { data } = useProjectLiveStreams({ projectId: 88 });
  const { completed, demo } = useMemo(() => {
    return {
      demo: data?.filter((d) => !d.hasSummary),
      completed: data?.filter((d) => !!d.hasSummary),
    };
  }, [data]);
  return (
    <DebugDetectionPage>
      <StreamList
        title={"Pre-tagged static sample videos"}
        streams={demo}
        createRoute={AppRoute.LogoDetectionDetail.create}
      />
      {/*<StreamList*/}
      {/*  title={"Pre-tagged static sample summaries"}*/}
      {/*  streams={completed}*/}
      {/*/>*/}
    </DebugDetectionPage>
  );
}

const StreamListContainer = styled.div`
  margin-bottom: 24px;
`;

function StreamList(props: {
  title: string;
  streams: LiveStream[];
  createRoute: (id: number) => string;
}) {
  return (
    <StreamListContainer>
      <h2>{props.title}</h2>
      <ul>
        {props.streams?.map((value) => (
          <li key={value.id}>
            <a href={props.createRoute(value.id)}>
              {`#${value.id} ${value.label}`}
            </a>
          </li>
        ))}
      </ul>
    </StreamListContainer>
  );
}

export default DebugDetectionScreen;
