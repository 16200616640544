import { useQuery } from "react-query";
import { UseQueryOptions } from "react-query/types/react/types";
import { fetchApi, toJson } from "~/services/fetchApi";

interface Options<T, R> extends UseQueryOptions<R> {
  mapResult?: (val: T) => R;
}

export function useFetchApi<T, R = T>(
  endpoint: string,
  options?: Options<T, R>
) {
  return useQuery<R>(
    endpoint,
    () => {
      return fetchApi(endpoint)
        .then(toJson)
        .then((result) => {
          if (options?.mapResult) return options.mapResult(result);
          else return result as R;
        });
    },
    options
  );
}
