import { useMemo } from "react";
import { DetectionResult } from "@core/types/detection/DetectionTypes";
import { DetectionFilter } from "~/screens/app/detection/stats/components/filter/DetectionFilter";
import { useDetectionSummary } from "~/screens/app/detection/data/useDetectionSummary";
import { useBrandsInStats } from "~/screens/app/detection/utils/useBrandsInStats";

export function useDetectionStats(
  results: DetectionResult[],
  filter: DetectionFilter
) {
  const summary = useDetectionSummary(results, filter);
  const { brands } = useBrandsInStats(summary.summed);
  return useMemo(
    () => ({
      results,
      summary,
      brands,
    }),
    [results, summary, brands]
  );
}
