export type FilterableFieldNames =
  | "publishAt"
  | "title"
  | "channelName"
  | "description"
  | "status"
  | "customStatus"
  | "category"
  | "platform"
  | "eventTag"
  | "project"
  | "hasComments"
  | "hasNote"
  | "isEbuMember";

type FilterFieldType = "text" | "date" | "ids" | "static";

export interface FilterFieldConfig {
  name: FilterableFieldNames;
  type: FilterFieldType;
  label: string;
}

export const videoFields: {
  [s in FilterableFieldNames]: FilterFieldConfig;
} = {
  // ATTENTION: if you add fields here, make sure to also add them to videoService.ts (filterToQuery)
  title: {
    name: "title",
    type: "text",
    label: "Title",
  },
  channelName: {
    name: "channelName",
    type: "text",
    label: "Uploader",
  },
  project: {
    name: "project",
    type: "ids",
    label: "Project",
  },
  publishAt: {
    name: "publishAt",
    type: "date",
    label: "Publish date",
  },
  description: {
    name: "description",
    type: "text",
    label: "Description",
  },
  status: {
    name: "status",
    type: "ids",
    label: "Status",
  },
  customStatus: {
    name: "customStatus",
    type: "ids",
    label: "User Status",
  },
  category: {
    name: "category",
    type: "ids",
    label: "Category",
  },
  platform: {
    name: "platform",
    type: "ids",
    label: "Platform",
  },
  eventTag: {
    name: "eventTag",
    type: "ids",
    label: "Event Tag",
  },
  hasComments: {
    name: "hasComments",
    type: "static",
    label: "Has comments",
  },
  hasNote: {
    name: "hasNote",
    type: "static",
    label: "Has note",
  },
  isEbuMember: {
    name: "isEbuMember",
    type: "static",
    label: "Is EBU-member",
  },
};
