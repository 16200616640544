import { useMemo } from "react";
import { ProjectId } from "@core/types/domain/ProjectType";
import { useLivestreams } from "~/screens/app/detection/livestreams/data/useLivestreams";

export function useFinishedLivestreams(
  projects: ProjectId[] | ProjectId | null
) {
  const fetchStreams = useLivestreams(projects);
  return useMemo(() => {
    return {
      ...fetchStreams,
      data: fetchStreams.data?.filter((s) => !!s.end),
    };
  }, [fetchStreams]);
}
