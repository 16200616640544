import React, { useEffect } from "react";
import { navigate } from "gatsby";
import { ProjectSelectionContext } from "../../../elements/context/project/ProjectSelectionContext";
import AppPageLayout from "../../../layouts/app/AppPageLayout";
import FloatingProjectSelector from "../../../elements/context/project/FloatingProjectSelector";
import { useUserRole } from "../../../store/login/loginHooks";
import { UserRole } from "../../../@core/types/domain/user/UserRole";
import { getDefaultLoginRoute } from "../../login/useDefaultLoginRoutet";
import DashboardWidgets from "./DashboardWidgets";
import Intro from "./Intro";
import ProjectStatsSection from "./sections/stats/ProjectStatsSection";
import BestVideosSection from "./sections/bestVideos/BestVideosSection";
import ProjectValueSection from "./sections/ProjectValueSection";
import ProjectLiveStreamStats from "./sections/ProjectLiveStreamStats";

interface DashboardProps {}

export function DashboardNext(props: DashboardProps) {
  const role = useUserRole();
  useEffect(() => {
    if (role === UserRole.Lawyer) {
      const route = getDefaultLoginRoute();
      navigate(route);
    }
  }, [role]);
  return (
    <AppPageLayout>
      <ProjectSelectionContext resolveFromUrl>
        <FloatingProjectSelector autoSelect />
        <Intro />
        <DashboardWidgets />
        <ProjectStatsSection />
        <ProjectValueSection />
        <BestVideosSection />
        <ProjectLiveStreamStats />
      </ProjectSelectionContext>
    </AppPageLayout>
  );
}
