import { useMemo, useState } from "react";
import { LivestreamSectionType } from "@core/types/domain/stream/LivestreamSectionType";

export type UseStreamSectionState = ReturnType<typeof useStreamSectionState>;

export function useStreamSectionState() {
  const [section, setSection] = useState<LivestreamSectionType>();
  return useMemo(() => {
    return {
      section,
      setSection,
    };
  }, [section]);
}
