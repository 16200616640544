import React, { ReactElement, useMemo } from "react";
import { faCrop } from "@fortawesome/free-solid-svg-icons";
import { BrandStatsBySize } from "@core/types/detection/DetectionTypes";
import { Brand } from "@core/types/domain/Brand";
import { PieChartData } from "~/components/charts/BetterPieChart";
import { notNull } from "~/utils/jsUtils";
import GroupedPieChartWidget, {
  GroupedPieChartWidgetProps,
} from "~/screens/app/dashboard/sections/GroupedPieChartWidget";
import { formatDuration } from "~/utils/formatUtils";

interface AttributePieChartProps extends AttributeChartTexts {
  brandStats: BrandStatsBySize;
  brand: Brand;
  className?: string;
}

export interface AttributeChartTexts
  extends Pick<GroupedPieChartWidgetProps, "title" | "explain" | "subTitle"> {
  getAttrLabel?: (attr: string) => string;
}

export function AttributePieChart({
  brandStats,
  className,
  title,
  subTitle,
  explain,
  getAttrLabel,
}: AttributePieChartProps) {
  const data: PieChartData[] = useMemo(() => {
    return Object.entries(brandStats)
      .map(([attr, stats]) => {
        if (attr === "all") return null;
        return {
          value: stats.duration,
          label: getAttrLabel ? getAttrLabel(attr) : attr,
        };
      })
      .filter(notNull);
  }, [brandStats]);
  const total = useMemo(() => {
    return data.reduce((result, value) => {
      return result + value.value;
    }, 0);
  }, [data, getAttrLabel]);
  return (
    <GroupedPieChartWidget
      data={data}
      icon={faCrop}
      title={title}
      subTitle={subTitle}
      explain={explain}
      total={formatDuration(total)}
      formatValue={formatDuration}
      className={className}
    />
  );
}
