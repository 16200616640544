import React, { useCallback, useEffect, useState } from "react";
import styled from "@emotion/styled";
import {
  fetchCreateStreamSection,
  fetchDeleteStreamSection,
  fetchUpdateStreamSection,
  refetchStreamSections,
} from "~/services/stream/fetchStreamSection";
import { alertError, alertInfo } from "~/utils/alert/alertUtils";
import { UseVideoRanges } from "~/screens/app/detection/stream/videorange/range/useVideoRanges";
import { UseStreamSectionState } from "~/screens/app/detection/stream/videorange/select/useStreamSectionState";
import { serializeRanges } from "~/screens/app/detection/stream/videorange/range/queryPersistRange";

interface VideoRangeListProps
  extends Pick<UseVideoRanges, "ranges">,
    Pick<UseStreamSectionState, "section" | "setSection"> {
  streamId: number;
}

export function SectionSaveButton({
  streamId,
  ranges,
  section,
  setSection,
}: VideoRangeListProps) {
  console.log("SectionSaveButton", ranges);
  const [name, setName] = useState<string>();
  const hasData = !!ranges?.[0]?.from;
  const isUpdate = !!section?.id;

  useEffect(() => {
    setName(section?.name || "");
  }, [section]);

  const onSave = useCallback(() => {
    const body = {
      ...section,
      name,
      streamId,
      ranges: serializeRanges(ranges),
    };
    if (isUpdate) {
      fetchUpdateStreamSection(streamId, section.id, body)
        .then(() => {
          alertInfo("Section updated");
          refetchStreamSections();
        })
        .catch((reason) => alertError("Failed saving section"));
    } else {
      fetchCreateStreamSection(streamId, body)
        .then(() => {
          alertInfo("Section saved");
          refetchStreamSections();
        })
        .catch((reason) => alertError("Failed saving section"));
    }
  }, [section, name, ranges, streamId]);

  const onDelete = useCallback(() => {
    fetchDeleteStreamSection(streamId, section.id)
      .then(() => {
        alertInfo("Section deleted");
        refetchStreamSections();
        setSection(undefined);
      })
      .catch((reason) => alertError("Failed deleting section"));
  }, [streamId, section?.id]);

  return (
    <Container>
      <NameRow className="field has-addons">
        <div className="control">
          <input
            className="input"
            type="text"
            placeholder="Name"
            autoComplete="new-name"
            value={name}
            onChange={(event) => {
              setName(event.currentTarget.value || "");
            }}
          />
        </div>
        <div className="control">
          <button
            className="button is-info"
            disabled={!hasData}
            onClick={onSave}
          >
            {isUpdate ? "Update" : "Save"}
          </button>
        </div>
      </NameRow>
      {isUpdate && (
        <DeleteButton
          className="button is-danger"
          disabled={!hasData}
          onClick={onDelete}
        >
          Delete
        </DeleteButton>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
`;

const NameRow = styled.div`
  width: 303px;
  & > div:first-of-type {
    flex-grow: 1;
  }
`;

const DeleteButton = styled.button`
  margin-left: 1em;
`;
