import React, { Dispatch, SetStateAction, useState } from "react";
import { LivestreamTypeExternal } from "@core/types/domain/Livestream";
import styled from "@emotion/styled";
import { StoredTaskInfo } from "@core/service/detection/fetchStoredTaskInfo";
import { CutRecordingButton } from "~/screens/app/admin/stream/cut/CutRecordingButton";
import Switch from "~/components/inputs/checkbox/Switch";
import Explain from "~/components/bulma/Explain";
import { VideoRange } from "~/screens/app/detection/stream/videorange/range/useVideoRanges";

interface RecordingCutControlsProps {
  stream: LivestreamTypeExternal;
  task: StoredTaskInfo;
  state: RecordingCutControlState;
  setState: Dispatch<SetStateAction<RecordingCutControlState>>;
  ranges: VideoRange[];
}

export function RecordingCutControls({
  stream,
  task,
  state,
  setState,
  ranges,
}: RecordingCutControlsProps) {
  const hasCut = task.originalPreviewUrl !== task.previewVideoUrl;
  return (
    <Container>
      <CutRecordingButton
        disabled={ranges.length !== 1}
        stream={stream}
        task={task}
        ranges={ranges}
      />
      <Switch
        disabled={!hasCut}
        title={
          <span>
            Show original preview
            <Explain multiline>
              By default, the last preview video (which may have been cut) is
              displayed here. If you want to re-cut a video that has already
              been cutted, use this function to display the original.
            </Explain>
          </span>
        }
        value={state.showOriginalPreview}
        onChange={(checked) => {
          setState((prev) => ({ ...prev, showOriginalPreview: checked }));
        }}
      />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  & > * {
    margin-right: 1rem;
  }
`;

interface RecordingCutControlState {
  showOriginalPreview: boolean;
}

export function useRecordingCutControlState() {
  return useState<RecordingCutControlState>({
    showOriginalPreview: false,
  });
}
