import React from "react";

interface PageSizeSelectorProps {
  pageSize: number;
  setPageSize: (pageSize: number) => void;
}

function PageSizeSelector(props: PageSizeSelectorProps) {
  return (
    <div className={"select is-small"}>
      <select
        value={props.pageSize}
        onChange={(e) => {
          props.setPageSize(Number(e.target.value));
        }}
      >
        {[5, 25, 50, 100, 250].map((pageSize) => (
          <option key={pageSize} value={pageSize}>
            Show {pageSize}
          </option>
        ))}
      </select>
    </div>
  );
}

export default PageSizeSelector;
