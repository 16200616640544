import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { GetTopChannelResult } from "../../../../@core/types/api/stats/getTopChannels";
import PlatformIcon from "../../../../views/videos/PlatformIcon";
import A from "../../../../components/links/A";
import { formatNumber } from "../../../../utils/formatUtils";

interface TopChannelTableProps {
  channels: GetTopChannelResult[];
}

function TopChannelTable({ channels }: TopChannelTableProps) {
  return (
    <div>
      <Table>
        <thead>
          <tr>
            <th>Channel</th>
            <th align={"right"}>Posts</th>
            <th align={"right"}>Reach</th>
            <th align={"right"}>Engagement</th>
          </tr>
        </thead>
        <tbody>
          {channels?.map((channel) => (
            <tr key={channel.channelId}>
              <td>
                <PlatformIcon id={channel.platform} />
                <A
                  css={paddingLeft}
                  href={channel.channelUrl}
                  target={"_blank"}
                >
                  {channel.channelName}
                </A>
              </td>
              <td align={"right"}>{formatNumber(channel.count)}</td>
              <td align={"right"}>{formatNumber(channel.reach)}</td>
              <td align={"right"}>{formatNumber(channel.engagement)}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

const paddingLeft = css`
  margin-left: 8px;
`;

const Table = styled.table`
  width: 100%;

  th {
    padding-bottom: 8px;
  }

  td,
  th {
    padding-right: 8px;
  }
`;

const NumberTd = styled.td``;

export default TopChannelTable;
