import { useMemo } from "react";

export function useSorted<T>(
  items: T[] | undefined,
  compareFn?: (a: T, b: T) => number
) {
  return useMemo(() => {
    if (!items) return items;
    return [...items].sort(compareFn);
  }, [items]);
}
