import React, { useMemo, useState } from "react";
import { Link } from "gatsby";
import { faVideo } from "@fortawesome/free-solid-svg-icons";
import styled from "@emotion/styled";
import { LivestreamType } from "@core/types/domain/Livestream";
import TableCard from "~/components/card/TableCard";
import { StreamTable } from "~/screens/app/detection/livestreams/StreamTable";
import { addDays, isToday } from "~/utils/dateUtils";
import { AppRoute } from "~/config/Routes";
import { OpenExternalStreamButton } from "~/screens/app/admin/streams/OpenExternalStreamButton";
import { StreamEngineInfo } from "~/screens/app/admin/streams/StreamEngineInfo";
import { AdminStreamControls } from "~/screens/app/admin/streams/components/controls/AdminStreamControls";
import { CardHeader, CardHeaderRight } from "~/components/card/Card";
import { useSelectedProjectIds } from "~/store/project/projectHooks";
import Notification from "~/components/notification/Notification";
import { useStreamsWithResults } from "~/screens/app/admin/streams/data/useStreamsWithResults";
import { DetectionsCol } from "~/screens/app/admin/streams/cols/DetectionsCol";
import { NoWrapBlock } from "~/elements/utils/NoWrap";
import { CompactTimeInfo } from "~/screens/app/admin/streams/components/CompactTimeInfo";
import Switch from "~/components/inputs/checkbox/Switch";

interface AdminStreamListProps {
  streams: LivestreamType[];
}

export function AdminStreamList({ streams }: AdminStreamListProps) {
  const projectIds = useSelectedProjectIds();

  const [_showOld, setShowOld] = useState(false);
  const isProjectView = projectIds?.length > 0;
  const showOld = isProjectView ? _showOld : false;

  const filtered = useMemo(() => {
    if (!streams) return undefined;
    let filtered = streams;
    if (projectIds?.length > 0) {
      filtered = filtered.filter((s) => projectIds.indexOf(s.projectId) !== -1);
    }
    if (!showOld) {
      const maxAge = addDays(new Date(), -1).getTime();
      filtered = filtered.filter((stream) => {
        return stream.plannedStart?.getTime() > maxAge;
      });
    }
    return filtered;
  }, [streams, projectIds, showOld]);

  const filteredStreams = useStreamsWithResults(filtered);

  return (
    <>
      {!projectIds.length && (
        <Notification type={"info"}>
          This view shows all upcoming streams by default. You can select
          projects to see all streams of the given projects.
        </Notification>
      )}
      <TableCard>
        <CardHeader
          title={"Upcoming Livestreams"}
          icon={faVideo}
          header={
            isProjectView ? (
              <CardHeaderRight>
                <Switch
                  title={"Show old streams"}
                  value={showOld}
                  onChange={setShowOld}
                />
              </CardHeaderRight>
            ) : undefined
          }
        />
        {filteredStreams?.length > 0 ? (
          <Table fullwidth>
            <thead>
              <tr>
                <th>#</th>
                <th>Title</th>
                <th>Engine</th>
                <th>Results</th>
                <th>Controls</th>
              </tr>
            </thead>
            <tbody>
              {filteredStreams?.map((stream, i) => {
                const today = isToday(stream?.plannedStart);
                return (
                  <tr
                    key={stream.id}
                    className={today ? "is-today" : undefined}
                  >
                    <td className={"no-wrap"}>
                      <Link to={AppRoute.Livestream.create(stream.id)}>
                        {stream.id}
                      </Link>
                      <ColButton>
                        <OpenExternalStreamButton
                          streamId={stream.id}
                          title={"Get external viewable link"}
                        />
                      </ColButton>
                      <ColButton>
                        <OpenExternalStreamButton
                          streamId={stream.id}
                          isNew
                          title={"Get new link without video"}
                          color={"green"}
                        />
                      </ColButton>
                      <ColButton>
                        <OpenExternalStreamButton
                          streamId={stream.id}
                          isNew
                          withVideo
                          title={"Get new link with video"}
                          color={"red"}
                        />
                      </ColButton>
                      <div>
                        <small>
                          <Link
                            to={`${AppRoute.LivestreamOverview}?project=${stream.projectId}`}
                            title={"Go to project stream view"}
                          >
                            {stream.projectId}
                          </Link>
                        </small>
                      </div>
                    </td>
                    <td className={"is-text"}>
                      {stream.title}
                      <NoWrapBlock>
                        <small>
                          <CompactTimeInfo stream={stream} />
                        </small>
                      </NoWrapBlock>
                    </td>
                    <td>
                      <StreamEngineInfo stream={stream} />
                    </td>
                    <td>
                      <DetectionsCol stream={stream} />
                    </td>
                    <ActionsCol>
                      <AdminStreamControls stream={stream} />
                    </ActionsCol>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        ) : (
          <Table>
            <tbody>
              <tr>
                <td>No streams found.</td>
              </tr>
            </tbody>
          </Table>
        )}
      </TableCard>
    </>
  );
}

const ActionsCol = styled.td`
  min-width: 400px;
`;

const ColButton = styled.span`
  margin-left: 8px;
`;

const Table = styled(StreamTable)`
  tr.is-today {
    background-color: rgba(100, 189, 82, 0.25) !important;
  }

  .no-wrap {
    white-space: nowrap;
  }
`;
