import React from "react";
import styled from "@emotion/styled";
import { DebugDetectionPage } from "~/screens/app/detection/debug/DebugDetectionPage";
import { useDetectionModels } from "~/services/detection/fetchDetectionModels";
import Notification from "~/components/notification/Notification";
import AppPageSpinner from "~/layouts/app/AppPageSpinner";

interface DetectionModelsScreenProps {}

export function DetectionModelsScreen(props: DetectionModelsScreenProps) {
  const { data: models, isFetching, isError } = useDetectionModels();
  return (
    <DebugDetectionPage>
      <Notification type={"info"}>
        This page shows the models that are currently available within the Logo
        Engine.
      </Notification>
      {isError && (
        <Notification type={"danger"}>
          Data could not be retreived from the server.
        </Notification>
      )}
      {isFetching && <AppPageSpinner />}
      {!!models && (
        <Table>
          <thead>
            <tr>
              <th>Model</th>
              <th>Logos</th>
            </tr>
          </thead>
          <tbody>
            {models?.map((model) => (
              <tr key={model.name}>
                <td>{model.name}</td>
                <td>{model.logos.join(",")}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </DebugDetectionPage>
  );
}

const Table = styled.table`
  td,
  th {
    padding-right: 1em;
    padding-bottom: 0.2em;
    padding-top: 0.2em;
  }
`;
