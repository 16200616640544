import React from "react";
import { ProjectSelectionContext } from "~/elements/context/project/ProjectSelectionContext";
import FloatingProjectSelector from "~/elements/context/project/FloatingProjectSelector";
import AppPageLayout from "~/layouts/app/AppPageLayout";
import { StreamStatsView } from "~/screens/app/detection/stats/stream/StreamStatsView";

// this is for multiple streams (bad naming)
export function StreamStatsScreen() {
  return (
    <AppPageLayout>
      <ProjectSelectionContext resolveFromUrl>
        <FloatingProjectSelector autoSelect />
        <StreamStatsView />
      </ProjectSelectionContext>
    </AppPageLayout>
  );
}
