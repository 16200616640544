import React, { useCallback } from "react";
import styled from "@emotion/styled";
import { VideoType } from "../../../../@core/types/domain/video/VideoType";
import SafeImage from "../../../../components/img/SafeImage";
import { formatDate } from "../../../../utils/dateUtils";
import { videoStatusById } from "../../../../@core/types/domain/video/VideoStatus";
import { EMPTY_STRING } from "../../../../utils/jsUtils";
import { useVideoModal } from "../../VideoModal";
import VideoSocialStats from "../columns/VideoSocialStats";
import VideoPlatformLink from "../../../../components/domain/video/VideoPlatformLink";

export interface CellProps<T> {
  cell: {
    value: any;
    row: {
      original: T;
    };
  };
}

export function ThumbnailCell(props: CellProps<VideoType>) {
  const src = props.cell.value;
  const video = props.cell.row.original;
  const [modalElement, setOpen] = useVideoModal(video.embedUrl);
  const onClick = useCallback(() => {
    setOpen(true);
  }, []);
  return (
    <>
      <ThumbnailCellImage
        src={src}
        alt="thumbnail"
        onClick={video.embedUrl && onClick}
      />
      {modalElement}
    </>
  );
}

const ThumbnailCellImage = styled(SafeImage)<{ onClick: any }>`
  max-width: 180px;
  cursor: ${(props) => props.onClick && "pointer"};
`;

export function ChannelCell(props: CellProps<VideoType>) {
  return <VideoPlatformLink video={props.cell.row.original} />;
}

export function TitleCell(props: CellProps<VideoType>) {
  const video = props.cell.row.original;
  const { id, url } = video;
  const hasUrl = url && !url.startsWith("/");
  return (
    <>
      {hasUrl ? (
        <a
          href={props.cell.row.original.url}
          target={"_blank"}
          rel="noreferrer"
        >
          {props.cell.value}
        </a>
      ) : (
        props.cell.value
      )}
      <SocialStats video={video} />
    </>
  );
}

const SocialStats = styled(VideoSocialStats)`
  width: 320px;
  font-size: 0.9rem;
  margin-top: 4px;
  color: #4a4a4a;
  span {
    flex: 1;
  }
`;

export function DateCell(props: CellProps<VideoType>) {
  const { value } = props.cell;
  return value && formatDate(value);
}

export function StatusCell(props: CellProps<VideoType>) {
  const status = videoStatusById[props.cell.value];
  const video = props.cell.row.original;
  const hasCustomStatus = !!video.customStatus;
  return (
    status && <StatusTag decent={hasCustomStatus}>{status.name}</StatusTag>
  );
}

export function LengthCell(props: CellProps<VideoType>) {
  return formatLength(props.cell.value) || EMPTY_STRING;
}

function formatLength(seconds: number) {
  if (!seconds) return seconds;
  if (seconds <= 60) return seconds;
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds - minutes * 60;
  return `${minutes}:${remainingSeconds}`;
}

export const StatusTag = styled.span<{ decent?: boolean }>`
  background-color: #ebebeb;
  opacity: ${(props) => props.decent && ".5"};
  border-radius: 4px;
  padding: 4px 8px;
  white-space: nowrap;
`;
