import React from "react";
import styled from "@emotion/styled";
import { useLoginState } from "../../../store/login/loginHooks";

interface IntroProps {}

function Intro(props: IntroProps) {
  const { user } = useLoginState();
  return (
    <Section>
      <h1 className="title">
        Welcome back <i>{user.name}</i>,
      </h1>
      <h2 className="subtitle">
        In the time you were busy we collected some usefull data for you.
      </h2>
    </Section>
  );
}

const Section = styled.section`
  margin-bottom: 12px;
`;

export default Intro;
