import React, { useState } from "react";
import styled from "@emotion/styled";
import TextareaInput from "~/components/inputs/text/TextareaInput";
import Button from "~/components/button/Button";
import { VideoImportTable } from "~/screens/admin/videoimport/VideoImportTable";
import { VideoImport } from "~/screens/admin/videoimport/VideoImport";
import { ProjectSelectionContext } from "~/elements/context/project/ProjectSelectionContext";
import MultiProjectSelector from "~/elements/context/project/MultiProjectSelector";
import { useSelectedProjectId } from "~/store/project/projectHooks";
import { SelectorLabelWrapper } from "~/elements/misc/SelectorLabelWrapper";
import Notification from "~/components/notification/Notification";

interface VideoImportScreenProps {}

export function VideoImportScreen(props: VideoImportScreenProps) {
  return (
    <ProjectSelectionContext resolveFromUrl>
      <VideoImportScreen2 />
    </ProjectSelectionContext>
  );
}

function VideoImportScreen2(props: VideoImportScreenProps) {
  const [tableReset, setTableReset] = useState(1);
  const [imports, setImports] = useState<VideoImport[]>([]);
  const [input, setInput] = useState(
    (process.env.GATSBY_VIDEO_IMPORT_DEFAULT as string) || ""
  );
  const projectId = useSelectedProjectId();
  return (
    <Container>
      <TextareaInput
        placeholder={placeholders}
        value={input}
        onChange={(e) => setInput(e.target.value)}
      />
      <Group>
        <Button
          disabled={!projectId}
          variant={"is-primary"}
          onClick={() => {
            const urls = input.split("\n");
            setImports(urls.map((url) => ({ url, projectId })));
            setTableReset((prev) => prev + 1);
          }}
        >
          Importieren
        </Button>
        <SelectorLabelWrapper label={"Project"}>
          <MultiProjectSelector multi={false} />
        </SelectorLabelWrapper>
      </Group>
      <VideoImportTable key={tableReset} imports={imports} />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  align-items: flex-start;
`;

const Group = styled.div`
  display: flex;
  gap: 1em;
`;

const placeholders = [
  "https://twitter.com/Ski_Austria_/status/1664209169975586816",
  "https://www.facebook.com/watch/?v=331982159123870",
  "https://www.facebook.com/ECMunich2022/videos/606800297644035", // does not work because of redirect
  "https://www.instagram.com/p/CINgF05Du87/",
  "https://www.instagram.com/stories/europeada/3220666771131733101/",
].join("\n");
