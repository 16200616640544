import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import styled from "@emotion/styled";
import { useFilterContext } from "./context/VideoFilterContext";

interface ClearFilterProps {}

function ClearFilter(props: ClearFilterProps) {
  const { setFilter } = useFilterContext();
  return (
    <Container
      className="button is-rounded"
      title={"Clear"}
      onClick={() => {
        setFilter({});
      }}
    >
      <span className={"icon is-small"}>
        <FontAwesomeIcon icon={faTrash} />
      </span>
    </Container>
  );
}

const Container = styled.a`
  margin-right: 12px;
`;

export default ClearFilter;
