import React, { useMemo } from "react";
import { GetDetectionJobStatus } from "@core/types/status/statusRouteTypes";
import styled from "@emotion/styled";
import { DebugDetectionPage } from "~/screens/app/detection/debug/DebugDetectionPage";
import { useFetchApi } from "~/utils/fetch/useFetchApi";
import { ErrorNotification } from "~/components/notification/ErrorNotification";
import AppPageSpinner from "~/layouts/app/AppPageSpinner";
import { VIDEO_STATUS_ROUTE } from "~/utils/const";
import { ControlButtons } from "~/screens/app/detection/debug/videos/ControlButtons";
import { JobsInfo } from "~/screens/app/detection/debug/videos/JobsInfo";
import { LastResults } from "~/screens/app/detection/debug/videos/LastResults";

export function DebugVideoDetectionScreen() {
  const { data: status, refetch, error } = useDetectionJobStatus();
  if (error)
    return (
      <ErrorNotification>
        Status konnte nicht abgerufen werden
      </ErrorNotification>
    );
  if (!status) return <AppPageSpinner />;
  return <DebugVideoDetection data={status} refetch={refetch} />;
}

function useDetectionJobStatus() {
  return useFetchApi<GetDetectionJobStatus<string>, GetDetectionJobStatus>(
    VIDEO_STATUS_ROUTE,
    {
      refetchOnWindowFocus: true,
      refetchInterval: 15000,
      staleTime: 5000,
      mapResult: (val) => ({
        ...val,
        time: new Date(val.time),
        lastResults: val.lastResults.map((r) => ({
          ...r,
          time: new Date(r.time),
        })),
      }),
    }
  );
}

function DebugVideoDetection({
  data,
  refetch,
}: Pick<ReturnType<typeof useDetectionJobStatus>, "data" | "refetch">) {
  const debug = useMemo(() => {
    return JSON.stringify(data, null, 2);
  }, [data]);
  return (
    <DebugDetectionPage>
      <DebugPageWrapper>
        <ControlButtons isRunning={data?.running} refetch={refetch} />
        {data?.jobs && <JobsInfo jobs={data.jobs} />}
        {data?.lastResults && <LastResults results={data.lastResults} />}
        {/*{process.env.NODE_ENV === "development" && <pre>{debug}</pre>}*/}
      </DebugPageWrapper>
    </DebugDetectionPage>
  );
}

const DebugPageWrapper = styled.div`
  h2 {
    font-size: 1.4em;
    font-weight: bold;
    margin-top: 1em;
  }
`;
