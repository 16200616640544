import React, { ReactNode } from "react";
import styled from "@emotion/styled";
import { isMobile } from "~/utils/style/styleUtils";

interface SelectorLabelWrapperProps {
  label: string;
  children?: ReactNode;
}

export function SelectorLabelWrapper({
  label,
  children,
}: SelectorLabelWrapperProps) {
  return (
    <SelectorLabel>
      <label className={"label"}>{label}</label>
      {children}
    </SelectorLabel>
  );
}

const SelectorLabel = styled.div`
  display: inline-flex;
  align-items: center;

  ${isMobile} {
    flex-wrap: wrap;
    label {
      display: none;
    }
  }

  label {
    margin-right: 12px;
  }
  label:not(:last-child) {
    margin-bottom: 0;
  }
`;
