import { PaginationState } from "@core/types/misc/PaginationState";
import { Dispatch, SetStateAction, useMemo } from "react";

export function usePaginationController(
  pagination: PaginationState,
  setPagination: Dispatch<SetStateAction<PaginationState>>,
  maxItemCount: number
) {
  return useMemo(() => {
    const pageIndex = pagination.page;
    const numberOfPages = Math.floor(maxItemCount / pagination.pageSize);
    const canNextPage = pageIndex < numberOfPages - 1;
    const canPrevPage = pageIndex > 0;
    return {
      canNextPage,
      canPrevPage,
      numberOfPages,
      setPage(page: number) {
        if (page < 0) return false;
        if (page > numberOfPages - 1) return false;
        setPagination((prev) => ({ ...prev, page }));
        return true;
      },
      setNextPage() {
        if (!canNextPage) return;
        setPagination((prev) => ({ ...prev, page: prev.page + 1 }));
      },
      setPrevPage() {
        setPagination((prev) => ({ ...prev, page: prev.page - 1 }));
      },
      setPageSize(pageSize: number) {
        setPagination((prev) => ({ ...prev, pageSize }));
      },
    };
  }, [pagination, setPagination, maxItemCount]);
}
