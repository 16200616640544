import React from "react";
import { VideoType } from "../../../../@core/types/domain/video/VideoType";
import { updateVideo } from "../../../../services/video/videoService";
import { alertError } from "../../../../utils/alert/alertUtils";
import { globalUpdateVideo } from "../../context/VideoProviderContext";
import { VideoCategory } from "../../../../@core/types/domain/video/VideoCategory";
import CategorySelection from "../../../../components/domain/CategorySelection";
import { CellProps } from "./videoTableCells";

type CategoryCellProps = CellProps<VideoType>;

function CategoryCell(props: CategoryCellProps) {
  const categoryId = props.cell.value;
  return (
    <CategorySelection
      value={categoryId}
      onChange={(option) => {
        const video = props.cell.row.original;
        fetchUpdate(video.id, option.value)
          .then((video) => {
            globalUpdateVideo(video);
          })
          .catch(alertError);
      }}
    />
  );
}

function fetchUpdate(videoId: number, category: VideoCategory) {
  if (category === 0) category = null;
  return updateVideo(videoId, { category });
}

export default CategoryCell;
