import React from "react";
import { LivestreamsView } from "./LivestreamsView";
import AppPageLayout from "~/layouts/app/AppPageLayout";
import { ProjectSelectionContext } from "~/elements/context/project/ProjectSelectionContext";
import { ProjectSelectorRow } from "~/elements/context/project/ProjectSelectorRow";

interface LivestreamOverviewScreenProps {}

export function LivestreamOverviewScreen(props: LivestreamOverviewScreenProps) {
  return (
    <AppPageLayout>
      <ProjectSelectionContext resolveFromUrl>
        <ProjectSelectorRow autoSelect />
        <LivestreamsView />
      </ProjectSelectionContext>
    </AppPageLayout>
  );
}
