import React, { useCallback } from "react";
import { LivestreamSectionType } from "@core/types/domain/stream/LivestreamSectionType";
import { SectionSaveButton } from "~/screens/app/detection/stream/videorange/SectionSaveButton";
import { SavedStreamSelector } from "~/screens/app/detection/stream/videorange/SavedStreamSelector";
import { UseVideoRanges } from "~/screens/app/detection/stream/videorange/range/useVideoRanges";
import { deserializeRanges } from "~/screens/app/detection/stream/videorange/range/queryPersistRange";
import { useStreamSectionState } from "~/screens/app/detection/stream/videorange/select/useStreamSectionState";

interface StreamSaveSectionProps
  extends Pick<UseVideoRanges, "ranges" | "setRanges"> {
  stream: Pick<LivestreamSectionType, "id">;
}

export function StreamSaveSection({
  stream,
  ranges,
  setRanges,
}: StreamSaveSectionProps) {
  const { section, setSection: _setSection } = useStreamSectionState();
  const setSection = useCallback(
    (next: LivestreamSectionType | undefined) => {
      _setSection(next);
      if (next) setRanges(deserializeRanges(next.ranges));
    },
    [_setSection, setRanges]
  );
  return (
    <>
      <SectionSaveButton
        streamId={stream.id}
        ranges={ranges}
        section={section}
        setSection={setSection}
      />
      <SavedStreamSelector
        streamId={stream.id}
        ranges={ranges}
        section={section}
        setSection={setSection}
      />
    </>
  );
}
