import React, { useMemo, useState } from "react";
import styled from "@emotion/styled";
import { BroadcastSignalType } from "@core/types/detection/BroadcastSignalType";
import {
  getSignalRecordingDownloadUrl,
  getSignalRecordingUrl,
} from "@core/utils/domain/signalUtils";
import {
  RecordingSelectState,
  RecordingUrlSelector,
} from "~/screens/app/admin/signals/single/selector/RecordingUrlSelector";
import { VideoJsPlayer } from "~/components/video/videojs/VideoJsPlayer";
import { addHours, formatDateTime } from "~/utils/dateUtils";
import AppPageSpinner from "~/layouts/app/AppPageSpinner";

interface SignalRecordingViewProps {
  signal: BroadcastSignalType;
}
function getDefaultSelect(): RecordingSelectState {
  return {
    from: addHours(new Date(), -4),
    to: addHours(new Date(), -2),
  };
}
export function SignalRecordingView({ signal }: SignalRecordingViewProps) {
  const [select, setSelect] = useState<RecordingSelectState>(getDefaultSelect);
  const { playUrl, downloadUrl } = useMemo(() => {
    return {
      playUrl: signal && getSignalRecordingUrl(signal, select.from, select.to),
      downloadUrl:
        signal && getSignalRecordingDownloadUrl(signal, select.from, select.to),
    };
  }, [signal, select]);
  if (!signal) return <AppPageSpinner />;
  return (
    <>
      <RecordingUrlSelector initialValue={select} onSelect={setSelect} />
      <Row>
        <VideoContainer>
          {playUrl && <VideoJsPlayer src={playUrl} />}
        </VideoContainer>
        <DebugColumn>
          <h2>Debug</h2>
          <pre>
            Play URL:{" "}
            <a href={playUrl} target={"_blank"} rel="noreferrer">
              {playUrl}
            </a>
            <br />
            Download URL:{" "}
            <a href={downloadUrl} target={"_blank"} rel="noreferrer">
              {downloadUrl}
            </a>
            <br />
            From: {select.from.getTime()} ({formatDateTime(select.from)})
            <br />
            To: {select.to.getTime()} ({formatDateTime(select.to)})
          </pre>
        </DebugColumn>
      </Row>
    </>
  );
}
const VideoContainer = styled.div`
  background-color: black;
  min-width: 960px;
  min-height: 540px;
  max-width: 100%;
`;

const DebugColumn = styled.div`
  padding-left: 24px;
  padding-right: 24px;

  & > pre {
    padding-left: 0;
    white-space: pre-wrap;
  }
`;

const Row = styled.div`
  display: flex;
`;
