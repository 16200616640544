import matchSorter from "match-sorter";
import React from "react";
// @ts-ignore
import { formatDate, parseDate } from "react-day-picker/moment";
import groupBy from "lodash/groupBy";
import { FilterFieldConfig, videoFields } from "../utils/videoFields";
import { TextFilterType } from "../types/TextFilterType";
import { FilterType } from "../types/VideoFilterType";
import { platforms } from "../../../../@core/types/domain/Platform";
import { filterableStatus } from "../../../../@core/types/domain/video/VideoStatus";
import { limit } from "../../../../utils/jsUtils";
import { DateFilterType } from "../types/DateFilterType";
import { dateFormat } from "../../../../config";
import { allVideoCustomStatus } from "../../../../@core/types/domain/video/VideoCustomStatus";
import { allVideoCategories } from "../../../../@core/types/domain/video/VideoCategory";
import filterFields from "./filterFields";

export interface SearchResult {
  key: string;
  field: FilterFieldConfig;
  filter: (() => FilterType) | FilterType;
  title?: React.ElementType | React.ReactElement;
  groupId?: number;
}

const emptySearchResults = Object.values(
  groupBy(filterFields, (obj) => obj.groupId || 0)
);

export function getSearchResults(search: string): SearchResult[][] {
  /**
   *  IMPORTANT the returned array MUST have the same size
   *  on every call due to keying the output. Feel free to change that.
   */

  if (search === "") {
    return emptySearchResults;
  }

  const groups: SearchResult[][] = [];

  // Date searches
  const date = parseDate(search, dateFormat);
  if (date) {
    groups.push([addDateResult("publishAt", videoFields.publishAt, date)]);
  }

  // Text searches
  groups.push([
    addTextResult("title", videoFields.title, search),
    addTextResult("channelName", videoFields.channelName, search),
    // addTextResult("description", videoFields.description, search),
  ]);

  // Platforms
  const matchingPlatforms = matchSorter(platforms, search, { keys: ["name"] });
  groups.push([
    ...matchingPlatforms
      .map((platform) => {
        return {
          key: videoFields.platform.name + platform.id,
          field: videoFields.platform,
          title: (
            <span>
              Platform is <b>{platform.name}</b>
            </span>
          ),
          filter: () => ({
            in: [platform.id],
          }),
        };
      })
      .filter(limit(3)),
  ]);

  // Status
  const matchingStatus =
    search === "status"
      ? filterableStatus // filter all
      : matchSorter(filterableStatus, search, { keys: ["name"] });
  groups.push([
    ...matchingStatus
      .map((value) => {
        return {
          key: videoFields.status.name + value.id,
          field: videoFields.status,
          title: (
            <span>
              Status is <b>{value.name}</b>
            </span>
          ),
          filter: () => ({
            in: [value.id],
          }),
        };
      })
      .filter(limit(3)),
  ]);

  // User Status
  const matchingUserStatus = matchSorter(allVideoCustomStatus, search, {
    keys: ["name"],
  });
  groups.push([
    ...matchingUserStatus
      .map((value) => {
        return {
          key: videoFields.customStatus.name + value.id,
          field: videoFields.customStatus,
          title: (
            <span>
              User Status is <b>{value.name}</b>
            </span>
          ),
          filter: () => ({
            in: [value.id],
          }),
        };
      })
      .filter(limit(3)),
  ]);

  // User Status
  const matchinCategories = matchSorter(allVideoCategories, search, {
    keys: ["name"],
  });
  groups.push([
    ...matchinCategories
      .map((value) => {
        return {
          key: videoFields.category.name + value.id,
          field: videoFields.category,
          title: (
            <span>
              Category is <b>{value.name}</b>
            </span>
          ),
          filter: () => ({
            in: [value.id],
          }),
        };
      })
      .filter(limit(3)),
  ]);

  return groups;
}

function addTextResult(key: string, field: FilterFieldConfig, search: string) {
  return {
    key,
    field,
    title: (
      <span>
        {field.label} contains <b>{search}</b>{" "}
      </span>
    ),
    filter: () =>
      ({
        type: "contains",
        search,
      } as TextFilterType),
  };
}
function addDateResult(key: string, field: FilterFieldConfig, date: Date) {
  return {
    key,
    field,
    title: (
      <span>
        {field.label} is <b>{formatDate(date, dateFormat)}</b>{" "}
      </span>
    ),
    filter: () =>
      ({
        type: "exact",
        date,
      } as DateFilterType),
  };
}
