import React, { useCallback } from "react";
import styled from "@emotion/styled";
import classNames from "classnames";
import { useRecoilState } from "recoil";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import HeaderLogo from "./HeaderLogo";
import { Stylable } from "~/types/reactTypes";
import { isCollapsed } from "~/utils/style/styleUtils";
import { navState } from "~/views/nav/navState";

interface BaseHeaderProps extends Stylable {
  contentRight?: any;
  hideMenu?: boolean;
}

function Header({ className, contentRight, hideMenu }: BaseHeaderProps) {
  const [{ toggled }, setNavState] = useRecoilState(navState);
  const toggleMenu = useCallback(() => {
    setNavState((prev) => ({
      ...prev,
      toggled: !prev.toggled,
    }));
  }, [setNavState]);
  return (
    <HeaderContainer className={classNames("navbar", className)}>
      <TopMenuWrapper className="navbar-brand">
        <LinkCol className="navbar-item" href="/">
          <HeaderLogo />
        </LinkCol>
        {!hideMenu && (
          <MobileToggle onClick={toggleMenu}>
            <FontAwesomeIcon icon={toggled ? faTimes : faBars} fixedWidth />
          </MobileToggle>
        )}
      </TopMenuWrapper>
      {contentRight && <div className="navbar-end">{contentRight}</div>}
    </HeaderContainer>
  );
}

const TopMenuWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LinkCol = styled.a`
  flex-shrink: 1;
`;

const MobileToggle = styled.div`
  display: none;
  padding: 6px 12px;
  ${isCollapsed} {
    display: block;
  }
`;

const HeaderContainer = styled.div`
  box-shadow: 0 1px 0 rgba(24, 28, 33, 0.04);
  padding-top: 12px;
  padding-bottom: 12px;

  .navbar-brand img {
    max-height: inherit;
  }

  .navbar-item img {
    max-height: 52px;
  }
`;

export default Header;
