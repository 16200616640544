export enum VideoCustomStatus {
  Unset = 0,
  Recheck = 1,
  FollowUp = 2,
  NoFollowUp = 3,
  NonRelevant = 4,
  Sponsor = 5,
}

interface VideoCustomStatusType {
  id: number;
  name: string;
}

const configs: { [k in VideoCustomStatus]: VideoCustomStatusType } = {
  [VideoCustomStatus.Unset]: { id: VideoCustomStatus.Unset, name: "Unset" },
  [VideoCustomStatus.Recheck]: {
    id: VideoCustomStatus.Recheck,
    name: "Recheck",
  },
  [VideoCustomStatus.FollowUp]: {
    id: VideoCustomStatus.FollowUp,
    name: "Follow Up",
  },
  [VideoCustomStatus.NoFollowUp]: {
    id: VideoCustomStatus.NoFollowUp,
    name: "No Follow Up",
  },
  [VideoCustomStatus.NonRelevant]: {
    id: VideoCustomStatus.NonRelevant,
    name: "Non Relevant",
  },
  [VideoCustomStatus.Sponsor]: {
    id: VideoCustomStatus.Sponsor,
    name: "Sponsor",
  },
};

export const allVideoCustomStatus: VideoCustomStatusType[] =
  Object.values(configs);

export const videoCustomStatusById: {
  [s: number]: VideoCustomStatusType;
} = configs;
