import React from "react";
import { LivestreamType } from "@core/types/domain/Livestream";
import { liveStreamTaskId } from "@core/utils/domain/detectionUtils";
import { TaskInfo } from "@core/types/detection/DetectionTypes";
import {
  StreamSectionStats,
  StreamSectionStatsProps,
} from "~/screens/app/detection/stream/StreamSectionStats";
import AppPageSpinner from "~/layouts/app/AppPageSpinner";
import { SectionSelectorView } from "~/screens/app/detection/stream/videorange/SectionSelectorView";
import { useStoredTaskInfo } from "~/services/detection/storage/fetchStoredTaskInfo";
import { ErrorNotification } from "~/components/notification/ErrorNotification";
import { useVideoRanges } from "~/screens/app/detection/stream/videorange/range/useVideoRanges";
import { useSectionStatsFilter } from "~/screens/app/detection/stream/filter/SectionStatsFilterState";
import { PlacementSectionStats } from "~/screens/app/detection/stream/placement/PlacementSectionStats";
import { SectionStatsFilter } from "~/screens/app/detection/stream/filter/SectionStatsFilter";

interface StreamStatsViewProps {
  stream: LivestreamType;
}

export function StreamStatsView({ stream }: StreamStatsViewProps) {
  const {
    data: task,
    isFetching: l1,
    isError,
  } = useStoredTaskInfo(
    stream.projectId,
    liveStreamTaskId.fromStreamId(stream.id)
  );

  const { ranges, setRanges } = useVideoRanges();
  if (!stream) return null;

  if (isError)
    return (
      <ErrorNotification>
        There seem to be no data for the selected stream
      </ErrorNotification>
    );

  return (
    <div>
      {task?.previewVideoUrl && (
        <SectionSelectorView
          initialRanges={ranges}
          onSelect={setRanges}
          task={task}
          stream={stream}
        />
      )}
      {l1 ? (
        <AppPageSpinner />
      ) : (
        <SectionStats
          stream={stream}
          ranges={ranges}
          task={task as any as TaskInfo}
        />
      )}
    </div>
  );
}

function SectionStats({
  stream,
  ranges,
  task,
}: StreamSectionStatsProps & { task: TaskInfo }) {
  const [filter, setFilter] = useSectionStatsFilter();
  const stats =
    filter.type === "place" ? (
      <PlacementSectionStats stream={stream} ranges={ranges} task={task} />
    ) : (
      <StreamSectionStats stream={stream} ranges={ranges} />
    );
  return (
    <div>
      <SectionStatsFilter filter={filter} updateFilter={setFilter} />
      {stats}
    </div>
  );
}
