import React from "react";
import styled from "@emotion/styled";
import { Controller, useForm } from "react-hook-form";
import { RecordingStartArgs } from "@core/types/recordings/RecordingApi";
import { DetectionServer } from "@core/types/detection/DetectionServer";
import { fetchStartRecording } from "~/screens/app/detection/debug/recording/service/fetchStartRecording";
import { alertError, alertInfo } from "~/utils/alert/alertUtils";
import { refetchActiveRecordings } from "~/screens/app/detection/debug/recording/service/fetchActiveRecordings";
import { FromStreamSelector } from "~/screens/app/detection/debug/recording/create/FromStreamSelector";
import {
  ServerSelection,
  useServerSelection,
} from "~/components/inputs/domain/ServerSelection";

interface CreateRecordingProps {}

type FormState = RecordingStartArgs;

export function CreateRecording(props: CreateRecordingProps) {
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { isSubmitting },
  } = useForm<FormState>({
    defaultValues: getDefault(),
  });
  const onSubmit = (data: FormState) => {
    return fetchStartRecording(data)
      .then((result) => {
        alertInfo(`Started recording (Task #${result.started_task})`);
        refetchActiveRecordings();
      })
      .catch((reason) => {
        alertError(`Failed starting recording: ${reason.message}`);
      });
  };
  const state = useServerSelection();
  return (
    <Container>
      <h2>Start recording</h2>
      <FromStreamSelector onSet={reset} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <div className="field">
            <label className="label">Stream URL</label>
            <div className="control">
              <input
                className="input"
                type="text"
                placeholder="rtmp://..."
                {...register("url")}
              />
            </div>
          </div>
          <div className="field">
            <label className="label">Project</label>
            <div className="control">
              <input
                className="input"
                type="text"
                placeholder="113"
                {...register("groupId")}
              />
            </div>
          </div>
          <div className="field">
            <label className="label">Name</label>
            <div className="control">
              <input
                className="input"
                type="text"
                placeholder="L_123"
                {...register("id")}
              />
            </div>
          </div>
          <div className="field">
            <label className="label">Version</label>
            <div className="control">
              <input
                className="input"
                type="text"
                placeholder="1"
                defaultValue={"1"}
                {...register("version")}
              />
            </div>
          </div>
          <div className="field">
            <label className="label">Server</label>
            <div className="control">
              <Controller
                name={"server"}
                control={control}
                render={({ field: { onChange, onBlur, value, name, ref } }) => {
                  return <ServerSelection value={value} onChange={onChange} />;
                }}
              />
            </div>
          </div>
          <div className="field">
            <label className="label">&nbsp;</label>
            <p className="control">
              <button
                className="button is-success"
                type={"submit"}
                disabled={isSubmitting}
              >
                Start
              </button>
            </p>
          </div>
        </Row>
      </form>
    </Container>
  );
}

const Row = styled.div`
  display: flex;
  & > .field {
    margin-right: 1rem;
    width: 120px;
  }
  input {
    min-width: 120px;
  }
`;

const Container = styled.div`
  margin-top: 2rem;
`;

function getDefault(): Partial<FormState> {
  if (process.env.NODE_ENV !== "development")
    return {
      server: DetectionServer.S6.name,
    };
  return {
    url: "rtsp://wowzaec2demo.streamlock.net/vod/mp4:BigBuckBunny_115k.mov",
    groupId: "113",
    id: "L_TEST",
    version: "1",
    server: DetectionServer.S6.name,
  };
}
