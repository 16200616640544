import React, { useMemo } from "react";
import { useSelectedProject } from "~/store/project/projectHooks";
import { useStreamDetectionResults } from "~/screens/app/detection/data/fetchDetectionResults";
import AppPageSpinner from "~/layouts/app/AppPageSpinner";
import { useLivestreams } from "~/screens/app/detection/livestreams/data/useLivestreams";
import Notification from "~/components/notification/Notification";
import { StreamStatsComponent } from "~/screens/app/detection/stats/stream/StreamStatsComponent";

interface StreamStatsViewProps {}

export function StreamStatsView(props: StreamStatsViewProps) {
  const project = useSelectedProject();
  const projectIds = useMemo(() => project && [project?.id], [project]);

  const { data: streams, isFetching: l1 } = useLivestreams(projectIds);
  const allStreamIds = useMemo(() => streams?.map((s) => s.id), [streams]);

  const { data: results, isFetching: l2 } =
    useStreamDetectionResults(allStreamIds);

  if (!project || !streams) return null;
  if (l1 || l2) return <AppPageSpinner />;

  if (!results?.length) {
    return (
      <Notification type={"info"}>
        No data has been collected for this project yet.
      </Notification>
    );
  }

  return (
    <>
      <h1>{project.name}</h1>
      <StreamStatsComponent
        streams={streams}
        results={results}
        projectId={project.id}
        project={project}
      />
    </>
  );
}
