import {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
} from "react";
import { FilterType, VideoFilterType } from "../types/VideoFilterType";

interface VidoeFilterContextType {
  filter: VideoFilterType;
  setFilter: Dispatch<SetStateAction<VideoFilterType>>;
}

const context = createContext<VidoeFilterContextType>(null);

export const VideoFilterContext = context.Provider;

export function useFilterContext() {
  return useContext(context);
}

export function useUpdateFilter<T extends FilterType>(name: string) {
  const { setFilter } = useFilterContext();
  return useCallback(
    (updater: (prev: T) => T) => {
      setFilter((current) => ({
        ...current,
        [name]: updater(current[name]),
      }));
    },
    [setFilter]
  );
}

export function useRemoveFilter() {
  const { setFilter } = useFilterContext();
  return (name: string) => {
    setFilter((prevState) => {
      const next = { ...prevState };
      delete next[name];
      return next;
    });
  };
}

export type AddFilterFn = (fieldName: string, filter: FilterType) => void;
export function useAddFilter(): AddFilterFn {
  const { setFilter } = useFilterContext();
  return useCallback(
    (fieldName: string, filter: FilterType) => {
      setFilter((prevState) => ({
        ...prevState,
        [fieldName]: filter,
      }));
    },
    [setFilter]
  );
}
