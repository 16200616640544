import React, { useMemo } from "react";
import styled from "@emotion/styled";
import { ProjectType } from "@core/types/domain/ProjectType";
import AppPageSpinner from "~/layouts/app/AppPageSpinner";
import Explain from "~/components/bulma/Explain";
import Notification from "~/components/notification/Notification";
import { useVideoDetectionResults } from "~/screens/app/detection/data/fetchDetectionResults";
import { VideoDetectionView } from "~/screens/app/detection/stats/components/VideoDetectionView";
import { ApiErrorResponse } from "~/services/fetchApi";
import { useVideoStatsWithEuroValue } from "~/screens/app/detection/stats/stream/useStatsWithEuroValue";

interface ProjectDetectionViewProps {
  project: ProjectType;
}

export function ProjectDetectionView({ project }: ProjectDetectionViewProps) {
  const projectIds = useMemo(() => (project ? [project.id] : null), [project]);
  const { data: results, error } = useVideoDetectionResults(projectIds);

  const parsedResults = useVideoStatsWithEuroValue(
    results,
    project.costsPerView
  );

  const isUnknownJobError =
    (error as ApiErrorResponse)?.message === "unknown job";
  if (results?.length === 0 || isUnknownJobError) {
    return (
      <>
        <Notification type={"danger"}>
          No data has been collected for this project yet.
        </Notification>
        {process.env.NODE_ENV === "development" && (
          <Notification type={"internal"}>
            Example projects: 89, 58
          </Notification>
        )}
      </>
    );
  }

  if (!results) return <AppPageSpinner />;

  return (
    <>
      <TitleRow>
        <h2>
          Social logo statistics
          <Explain>
            Includes all social media videos related to this project
          </Explain>
        </h2>
      </TitleRow>
      <VideoDetectionView
        results={parsedResults}
        projectId={project.id}
        type={"social"}
      />
    </>
  );
}

const TitleRow = styled.div`
  display: flex;
  button {
    margin-left: 14px;
  }
`;
