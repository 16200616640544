import React, { Dispatch, SetStateAction } from "react";
import { Column, usePagination, useSortBy, useTable } from "react-table";
import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLongArrowAltDown,
  faLongArrowAltUp,
} from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { PaginationState } from "@core/types/misc/PaginationState";
import { usePaginationController } from "~/components/table/usePaginationController";
import TableCard from "~/components/card/TableCard";
import TableCardHeader from "~/views/videos/table/TableCardHeader";
import PageSelector from "~/views/videos/table/PageSelector";
import PageSizeSelector from "~/views/videos/table/PageSizeSelector";

interface DataState<D> {
  items: D[] | undefined;
  /** number of available items */
  maxItemCount: number;
  isLoading: boolean;
}

interface CardProps {
  title: string;
  icon: IconProp;
}

export interface AsyncTableProps<D extends Record<string, any>>
  extends DataState<D>,
    CardProps {
  columns: Array<Column<D>>;
  pagination: PaginationState;
  setPagination: Dispatch<SetStateAction<PaginationState>>;
  className?: string;
}

const sortUp = <FontAwesomeIcon icon={faLongArrowAltUp} />;
const sortDown = <FontAwesomeIcon icon={faLongArrowAltDown} />;

export function AsyncTable<D extends Record<string, any>>({
  columns,
  items,
  isLoading,
  pagination,
  setPagination,
  title,
  maxItemCount,
  icon,
  className,
}: AsyncTableProps<D>) {
  const pageSize = pagination.pageSize;
  const pageIndex = pagination.page;

  const instance = useTable<D>(
    { columns, data: items },
    useSortBy,
    usePagination
  );

  const { getTableProps, headerGroups, rows, prepareRow } = instance;

  const {
    canPrevPage,
    canNextPage,
    setNextPage,
    setPrevPage,
    setPage,
    setPageSize,
    numberOfPages,
  } = usePaginationController(pagination, setPagination, maxItemCount);

  // noinspection DuplicatedCode
  return (
    <TableCard
      title={title}
      icon={icon}
      header={
        <TableCardHeader>
          <PageSelector
            pageIndex={pageIndex}
            nextPage={setNextPage}
            previousPage={setPrevPage}
            canNextPage={canNextPage}
            canPreviousPage={canPrevPage}
            gotoPage={setPage}
            numberOfPages={numberOfPages}
          />
          <PageSizeSelector pageSize={pageSize} setPageSize={setPageSize} />
        </TableCardHeader>
      }
      loading={isLoading}
    >
      <TableContainer>
        <StyledTable
          {...getTableProps()}
          className={"table is-striped is-hoverable " + (className || "")}
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {
                  return (
                    <th
                      key={column.id}
                      /*{...column.getHeaderProps(column.getSortByToggleProps())}*/
                      {...column.getHeaderProps()}
                      className={`cell-${column.id}`}
                    >
                      {column.render("Header")}
                      <ArrowContainer>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? sortUp
                            : sortDown
                          : ""}
                      </ArrowContainer>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr key={row.id} {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        key={cell.column.id}
                        {...cell.getCellProps()}
                        className={`cell-${cell.column.id}`}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </StyledTable>
      </TableContainer>
    </TableCard>
  );
}

const TableContainer = styled.div`
  max-width: 100%;
  width: 100%;
  overflow: hidden;
`;

const StyledTable = styled.table`
  width: 100%;

  td,
  th {
    width: auto;
  }

  td.long-text {
    word-break: break-word;
  }

  tr:nth-of-type(odd) {
    td {
      background-color: white;
    }
  }

  tr td,
  tr th {
    padding: 14px;

    &:first-of-type {
      padding-left: 8px;
    }
  }
`;

const ArrowContainer = styled.span`
  svg {
    margin-left: 6px;
  }
`;
