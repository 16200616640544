import React from "react";
import { formatDateTime } from "~/utils/dateUtils";
import { formatDurationFull } from "~/utils/formatUtils";
import { StreamWithDetections } from "~/screens/app/admin/streams/data/useStreamsWithResults";

interface DetectionsColProps {
  stream: StreamWithDetections;
}

export function DetectionsCol({ stream }: DetectionsColProps) {
  if (!stream.detections) return null;
  const info = stream.detections
    .map(
      (d) =>
        `#${d.id} | ${formatDateTime(d.createdAt)} | ${formatDurationFull(
          d.length
        )}`
    )
    .join("\n");
  return (
    <span data-tooltip={info} data-balloon-pos={"down"}>
      {stream.detections ? stream.detections.length : ""}
    </span>
  );
}
