import { fetchApi, requireOk, toJson } from "../fetchApi";
import { VideoCommentType } from "../../@core/types/domain/video/VideoCommentType";
import {
  CreateCommentBody,
  CreateCommentResult,
} from "../../@core/types/api/videoRouteTypes";

export function fetchVideoComments(
  videoId: number
): Promise<VideoCommentType[]> {
  return fetchApi(`/video/${videoId}/comments`).then(requireOk).then(toJson);
}

export function fetchAddVideoComment(
  videoId: number,
  comment: CreateCommentBody
): Promise<CreateCommentResult> {
  return fetchApi(`/video/${videoId}/comments`, {
    method: "POST",
    body: JSON.stringify(comment),
  })
    .then(requireOk)
    .then(toJson);
}

export function fetchDeleteVideoComment(
  videoId: number,
  commentId: number
): Promise<CreateCommentResult> {
  return fetchApi(`/video/${videoId}/comments/${commentId}`, {
    method: "DELETE",
  })
    .then(requireOk)
    .then(toJson);
}
