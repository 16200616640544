import React from "react";
import { ProjectDetectionPage } from "./ProjectDetectionPage";
import AppPageLayout from "~/layouts/app/AppPageLayout";
import { ProjectSelectionContext } from "~/elements/context/project/ProjectSelectionContext";
import FloatingProjectSelector from "~/elements/context/project/FloatingProjectSelector";

interface ProjectDetectionScreenProps {}

function ProjectDetectionScreen(props: ProjectDetectionScreenProps) {
  return (
    <AppPageLayout>
      <ProjectSelectionContext resolveFromUrl>
        <FloatingProjectSelector autoSelect />
        <ProjectDetectionPage />
      </ProjectSelectionContext>
    </AppPageLayout>
  );
}

export default ProjectDetectionScreen;
