import { useMemo, useState } from "react";
import { SelectDropdownProps } from "~/elements/selectdropdown/SelectDropdown";

export function useSelectDropdownState<T>() {
  const [values, setValues] = useState<T[]>([]);
  const bindings: Pick<SelectDropdownProps<T>, "values" | "onChange"> = {
    values,
    onChange: (values: T[]) => {
      setValues(values);
    },
  };
  return useMemo(() => {
    return {
      isEmpty: !values.length,
      values,
      setValues,
      bindings,
    };
  }, [values]);
}
