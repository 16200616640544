import React, { useMemo } from "react";
import { upperFirst } from "lodash";
import GroupedPieChartWidget, {
  GroupedPieChartWidgetProps,
} from "../../dashboard/sections/GroupedPieChartWidget";
import { ProjectSocialStats } from "../../../../@core/types/api/projectRouteTypes";

export interface StatsByWidgetProps<T extends string = string>
  extends Omit<GroupedPieChartWidgetProps, "data"> {
  stats: Record<T, ProjectSocialStats>;
  field: keyof ProjectSocialStats;
  getLabel: (key: string) => string;
}

function StatsByWidget({
  field,
  stats,
  getLabel,
  ...props
}: StatsByWidgetProps) {
  const data = useMemo(() => {
    return (
      stats &&
      Object.entries(stats).map(([key, set]) => {
        const label = (getLabel && getLabel(key)) || upperFirst(key);
        return {
          value: set[field] as number,
          label,
        };
      })
    );
  }, [stats]);
  return <GroupedPieChartWidget {...props} data={data} />;
}

export default StatsByWidget;
